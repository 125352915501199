import Phaser from "phaser";

export class RectangularProgress {
  constructor(
    scene,
    x,
    y,
    {
      container,
      width = 100,
      height = 20,
      border = 2,
      iconMargin = 0,
      icon,
      iconScale,
      barColor = 0xbaa73d,
      trackColor = 0xffebd1,
      fadeOut = false,
    }
  ) {
    this.fadeOut = fadeOut;
    this.scene = scene;
    this.container = container;
    this.width = width;
    this.fillWidth = width - border * 2;
    // this.track = scene.add.arc(x, y, radius, 0, 360);
    // this.track.setStrokeStyle(10, 0x996147, 0.5);
    // this.container.add(this.track);
    // const itemIcon = this.scene.add.image(x, y, icon);
    // itemIcon.setScale(0.8, 0.8);
    // this.container.add(itemIcon);
    // this.progressCircle = scene.add.arc(x, y, radius, -90, -90);
    // this.progressCircle.setStrokeStyle(10, 0xbaa73d, 1);
    // this.container.add(this.progressCircle);
    this.track = scene.add.rectangle(x, y, width, height, trackColor);
    this.track.alpha = 0.6;
    this.fill = scene.add.rectangle(
      x + border,
      y,
      0,
      height - border * 2,
      barColor
    );
    this.fill.alpha = 0.8;

    this.track.setOrigin(0, 0.5);
    this.fill.setOrigin(0, 0.5);
    this.container.add(this.track);
    this.container.add(this.fill);

    const itemIcon = this.scene.add.image(x + width + iconMargin, y, icon);
    itemIcon.setOrigin(0, 0.5);
    if (iconScale) {
      itemIcon.setScale(iconScale);
    }
    this.progressIcon = itemIcon;
    if (!fadeOut) {
      this.progressIcon.alpha = 0.2;
    }

    this.container.add(itemIcon);
  }

  start(time, reset = false) {
    if (this.progressTween) {
      this.progressTween.stop();
      this.progressTween.remove();
    }

    if (this.fadeTween) {
      this.fadeTween.stop();
      this.fadeTween.remove();
    }

    if (reset) {
      this.fill.width = 0;
      this.progressIcon.alpha = this.fadeOut ? 1 : 0.2;
    }

    this.progressTween = this.scene.tweens.add({
      targets: this.fill,
      duration: time,
      width: this.fillWidth,
    });

    if (this.fadeOut) {
      this.fadeTween = this.scene.tweens.add({
        targets: this.progressIcon,
        duration: time,
        alpha: 0,
      });
    } else {
      this.fadeTween = this.scene.tweens.add({
        targets: this.progressIcon,
        duration: time,
        alpha: 1,
      });
    }
  }
}
