const loadingQueue = [];

function pixiLoadAxie(instance, id, resolve, reject) {
  const atlasURL = `${instance.baseUrl}${id}/axie/axie.atlas`;
  const imageURL = `${instance.baseUrl}${id}/axie/axie.png`;
  const modelURL = `${instance.baseUrl}${id}/axie/axie.json`;

  if (
    window.PIXI.loader.loading &&
    loadingQueue.findIndex((queue) => queue.id === id) < 0
  ) {
    loadingQueue.push({ instance, id, resolve, reject });
    return;
  }

  async function processSpine(resources) {
    let response;

    try {
      response = await fetch(modelURL);
    } catch (e) {
      reject("error getting axie data");
      return;
    }

    let skeletonData;
    try {
      skeletonData = await response.json();
    } catch (e) {
      reject("error parsing axie skeleton");
    }

    const atlasData = resources[`atlas-${id}`].data;
    const spineAtlas = new window.PIXI.spine.core.TextureAtlas(
      atlasData,
      (line, callback) => {
        callback(window.PIXI.BaseTexture.from(`png-${id}`));
      }
    );

    const spineAtlasLoader = new window.PIXI.spine.core.AtlasAttachmentLoader(
      spineAtlas
    );
    const spineJsonParser = new window.PIXI.spine.core.SkeletonJson(
      spineAtlasLoader
    );

    spineJsonParser.scale = 1;

    const spineData = spineJsonParser.readSkeletonData(skeletonData);

    instance.spine = new window.PIXI.spine.Spine(spineData);
    instance.spine.position.set(
      instance.app.view.width / 2,
      instance.app.view.height * 0.9
    );

    instance.spine.stateData.defaultMix = 0.2;
    instance.spine.stateData.setMix("action/appear", "action/idle", 2.2);
    instance.spine.stateData.setMix(
      "defense/hit-by-normal-attack-crit",
      "action/idle",
      2.2
    );

    instance.spine.scale.set(-0.2, 0.2);
    instance.spine.state.setAnimation(0, "action/idle", true);

    instance.app.stage.removeChildren();
    instance.app.stage.addChild(instance.spine);
    window.PIXI.spine.Spine.globalAutoUpdate = true;

    instance.app.start();

    instance.loaded = true;

    if (instance.animQueue.length) {
      instance.animQueue.forEach((animation) => {
        instance.reaction(animation.action, animation.backToIdle);
      });

      instance.animQueue = [];
    }
  }

  if (window.PIXI.loader.resources[`atlas-${id}`]) {
    processSpine(window.PIXI.loader.resources);
    resolve();

    if (loadingQueue.length) {
      const queueItem = loadingQueue.shift();

      // console.log("> load from queue", queueItem.id);
      pixiLoadAxie(
        queueItem.instance,
        queueItem.id,
        queueItem.resolve,
        queueItem.reject
      );
    }
  } else {
    window.PIXI.loader
      .add(`atlas-${id}`, atlasURL)
      .add(`png-${id}`, imageURL)
      .load(async (loader, resources) => {
        processSpine(resources);

        resolve();

        if (loadingQueue.length) {
          const queueItem = loadingQueue.shift();

          pixiLoadAxie(
            queueItem.instance,
            queueItem.id,
            queueItem.resolve,
            queueItem.reject
          );
        }
      });
  }
}

export class AxieSpine {
  constructor(baseUrl = "https://assets.axieinfinity.com/axies/") {
    this.baseUrl = baseUrl;

    this.app = new window.PIXI.Application(300, 300, {
      forceCanvas: true,
      transparent: true,
    });

    this.loaded = false;
    this.animQueue = [];
  }

  // loadAxie(id, resolve, reject) {
  //   const atlasURL = `${this.baseUrl}${id}/axie/axie.atlas`;
  //   const imageURL = `${this.baseUrl}${id}/axie/axie.png`;
  //   const modelURL = `${this.baseUrl}${id}/axie/axie.json`;

  //   if (
  //     window.PIXI.loader.loading &&
  //     loadingQueue.findIndex((queue) => queue.id === id) < 0
  //   ) {
  //     loadingQueue.push({ id, resolve, reject });
  //     console.log("add to que", id, loadingQueue);
  //     return;
  //   }

  //   window.PIXI.loader
  //     .add(`atlas-${id}`, atlasURL)
  //     .add(`png-${id}`, imageURL)
  //     .load(async (loader, resources) => {
  //       let response;

  //       try {
  //         response = await fetch(modelURL);
  //       } catch (e) {
  //         reject("error getting axie data");
  //         return;
  //       }

  //       let skeletonData;
  //       try {
  //         skeletonData = await response.json();
  //       } catch (e) {
  //         reject("error parsing axie skeleton");
  //       }

  //       const atlasData = resources[`atlas-${id}`].data;
  //       const spineAtlas = new window.PIXI.spine.core.TextureAtlas(
  //         atlasData,
  //         (line, callback) => {
  //           callback(window.PIXI.BaseTexture.from(`png-${id}`));
  //         }
  //       );

  //       const spineAtlasLoader =
  //         new window.PIXI.spine.core.AtlasAttachmentLoader(spineAtlas);
  //       const spineJsonParser = new window.PIXI.spine.core.SkeletonJson(
  //         spineAtlasLoader
  //       );

  //       spineJsonParser.scale = 1;

  //       const spineData = spineJsonParser.readSkeletonData(skeletonData);

  //       this.spine = new window.PIXI.spine.Spine(spineData);
  //       this.spine.position.set(
  //         this.app.view.width / 2,
  //         this.app.view.height * 0.9
  //       );
  //       window.aa = this.spine;

  //       this.spine.stateData.defaultMix = 0.2;
  //       this.spine.stateData.setMix("action/appear", "action/idle", 2.2);
  //       this.spine.stateData.setMix(
  //         "defense/hit-by-normal-attack-crit",
  //         "action/idle",
  //         2.2
  //       );

  //       this.spine.scale.set(-0.2, 0.2);
  //       this.spine.state.setAnimation(0, "action/idle", true);

  //       this.app.stage.removeChildren();
  //       this.app.stage.addChild(this.spine);
  //       window.PIXI.spine.Spine.globalAutoUpdate = true;

  //       this.app.start();

  //       this.loaded = true;

  //       if (this.animQueue.length) {
  //         this.animQueue.forEach((animation) => {
  //           this.reaction(animation.action, animation.backToIdle);
  //         });

  //         this.animQueue = [];
  //       }
  //       resolve();

  //       if (loadingQueue.length) {
  //         const queueItem = loadingQueue.shift();

  //         console.log("> load from queue", queueItem.id);
  //         this.loadAxie(queueItem.id, queueItem.resolve, queueItem.reject);
  //       }
  //     });
  // }

  load(axieId) {
    return new Promise((resolve, reject) => {
      // window.PIXI.loader.reset();

      pixiLoadAxie(this, axieId, resolve, reject);
      // this.loadAxie(axieId, resolve, reject);
    });
  }

  reaction(action, backToIdle) {
    if (!this.loaded) {
      this.animQueue.push({
        action,
        backToIdle,
      });

      return;
    }

    this.spine.state.setAnimation(0, action);
    if (backToIdle) {
      // setTimeout(() => {
      this.spine.state.addAnimation(0, "action/idle", true, 1);
      // }, 0);
    }
  }
}
