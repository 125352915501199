import Phaser from "phaser";
import { MatchAxie } from "./MatchAxie";

const PADDING = 20;
const COL_WIDTH = 410;
const ROW_HEIGHT = 84;

export class MatchDetails {
  constructor(scene, x, y) {
    this.scene = scene;

    this.container = this.scene.add.container(x, y);
  }

  renderDetails(match) {
    const midX = this.scene.cameras.main.width / 2;
    const myPosition = match.players.findIndex((player) => player.me);
    this.container.removeAll();

    // render rows
    for (
      let i = 0;
      i < (match.players.length > 7 ? 7 : match.players.length);
      i++
    ) {
      let color = 0xf9ca8a;
      if (i % 2 === 1) {
        color = 0xddb379;
      }

      const rect = this.scene.add.rectangle(
        0,
        48 + ROW_HEIGHT / 2 + i * ROW_HEIGHT,
        this.scene.cameras.main.width,
        ROW_HEIGHT,
        color
      );
      rect.alpha = 0.25;
      this.container.add(rect);
    }

    if (myPosition >= 0) {
      this.container.add(
        this.scene.make.text({
          x: 0,
          y: 22,
          text: `Position ${myPosition + 1} / ${match.players.length}`,
          style: {
            fontFamily: "Luckiest Guy",
            fontSize: "26px",
            stroke: "#7d5d2a",
            strokeThickness: 4,
            fill: "#ffffff",
          },
          add: true,
          origin: {
            x: 0.5,
            y: 0.5,
          },
        })
      );
    }

    this.container.add([
      this.scene.add.rectangle(
        -midX + 413 + PADDING,
        48 + (7 * ROW_HEIGHT) / 2,
        2,
        7 * ROW_HEIGHT,
        0x71572f
      ),
      this.scene.add.rectangle(
        -midX + 826 + PADDING,
        48 + (7 * ROW_HEIGHT) / 2,
        2,
        7 * ROW_HEIGHT,
        0x71572f
      ),
    ]);

    match.players.forEach((player, i) => {
      const col = Math.floor(i / 7);

      this.container.add(
        this.scene.make.text({
          x: -midX + PADDING + 6 + col * 423,
          y: 48 + ROW_HEIGHT / 2 + (i % 7) * ROW_HEIGHT,
          text: `${i + 1}.`,
          style: {
            fontFamily: "Luckiest Guy",
            fontSize: "16px",
            stroke: "#7d5d2a",
            strokeThickness: 4,
            fill: "#ffffff",
          },
          add: true,
          origin: {
            x: 0.5,
            y: 0.5,
          },
        })
      );

      if (player.stats && player.stats.axie) {
        new MatchAxie(
          this.scene,
          this.container,
          -midX + PADDING + ROW_HEIGHT / 2 + 12 + col * 423,
          48 + ROW_HEIGHT / 2 + (i % 7) * ROW_HEIGHT,
          player,
          i
        );
      }
    });
  }
}

// 88 h
// 410 w
