import { CSSTransition } from "react-transition-group";
import Box from "../../components/Box";
// import AxieSelector from "./AxieSeletor";
import { TRANSITIONS } from "../../../../../constants";
import styles from "../../ui.module.scss";
import gameStyles from "./GameUI.module.scss";
import { useState, useReducer, useEffect } from "react";
import { SOUND_FX } from "../../../gameSrc/sound/Sound";
// import SelectionBox from "./SelectionBox";
import { getRandomInt } from "../../../gameSrc/utils";

const initState = {
  address: "",
  name: "",
};

const reducer = (state, action) => {
  let newState = { ...state };

  switch (action.type) {
    case "setProfile":
      newState = { ...action.data };
      break;

    case "password":
      newState.password = action.value;
      break;

    default:
      break;
  }

  return newState;
};

const GameUI = ({
  show,
  playSound,
  changeView,
  game,
  gameState,
  onJoinMatch,
}) => {
  const [selection, selectAxie] = useState();
  const [starterAxies, setStarterAxies] = useState();
  const [playerAxies, setAxies] = useState();
  const [showSelectionBox, toggleSelectionBox] = useState(false);
  const [display, setDisplay] = useState(false);
  const [joining, setJoin] = useState(false);
  const [randomSelection, selectRandom] = useState(null);

  useEffect(() => {
    if (!show) {
      setDisplay(false);
      return;
    }

    setDisplay(true);
  }, [show]);

  // useEffect(() => {
  //   if (!playerAxies) {
  //     return;
  //   }

  //   const favorite = parseInt(window.localStorage.getItem("favorite"));

  //   if (favorite) {
  //     const favoriteAxie = playerAxies.find(
  //       (data) => data.axie.axie_id === favorite
  //     );

  //     if (favoriteAxie) {
  //       selectAxie(favoriteAxie.axie);
  //     }
  //   }
  // }, [playerAxies]);

  // const handleJoin = (type) => {
  //   setJoin(true);
  //   if (!selection) {
  //     console.log("need to pick random");

  //     let randomId;
  //     if (playerAxies && playerAxies.length) {
  //       // pick one from the player's axies
  //       const randomIndex = getRandomInt(0, playerAxies.length - 1);
  //       selectRandom(playerAxies[randomIndex].axie);

  //       randomId = playerAxies[randomIndex].axie.axie_id;
  //     } else {
  //       // pick one of the starters
  //       const randomIndex = getRandomInt(0, starterAxies.length - 1);
  //       selectRandom(starterAxies[randomIndex].axie);
  //       randomId = starterAxies[randomIndex].axie.axie_id;
  //     }

  //     playSound(SOUND_FX.MATCH_FULL, { volume: 0.2 });

  //     setTimeout(() => {
  //       // game.current.joinGame(randomId, true);
  //       changeView("gameLobby");
  //     }, 1500);
  //   } else {
  //     // game.current.joinGame(selection.axie_id);
  //     changeView("gameLobby");
  //   }
  // };

  return (
    <CSSTransition
      in={show}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      onEnter={() => {
        onJoinMatch();
      }}
      onExit={() => {
        // setDisplay(false);
        // selectAxie(null);
      }}
      unmountOnExit
    >
      <div className={styles.screen}>
        {/* <div
          className={gameStyles.dummy}
          style={{ top: 5, left: 10, height: 170 }}
        >
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div>
        <div className={gameStyles.dummy} style={{ top: 185, left: 10 }}>
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div>
        <div className={gameStyles.dummy} style={{ top: 365, left: 10 }}>
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div>
        <div className={gameStyles.dummy} style={{ top: 545, left: 10 }}>
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div>

        <div
          className={gameStyles.dummy}
          style={{ top: 5, left: 190, height: 170 }}
        >
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div>
        <div className={gameStyles.dummy} style={{ top: 185, left: 190 }}>
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div>
        <div className={gameStyles.dummy} style={{ top: 365, left: 190 }}>
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div>
        <div className={gameStyles.dummy} style={{ top: 545, left: 190 }}>
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div>

        <div className={gameStyles.mine}>
          <img src="images/board-sq.png" />
        </div>
        <div className={gameStyles.controls}>
          <div className={styles.skills}></div>
        </div>

        <div
          className={gameStyles.dummy}
          style={{ top: 5, right: 10, height: 170 }}
        >
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div>
        <div className={gameStyles.dummy} style={{ top: 185, right: 10 }}>
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div>
        <div className={gameStyles.dummy} style={{ top: 365, right: 10 }}>
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div>
        <div className={gameStyles.dummy} style={{ top: 545, right: 10 }}>
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div>

        <div
          className={gameStyles.dummy}
          style={{ top: 5, right: 190, height: 170 }}
        >
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div>
        <div className={gameStyles.dummy} style={{ top: 185, right: 190 }}>
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div>
        <div className={gameStyles.dummy} style={{ top: 365, right: 190 }}>
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div>
        <div className={gameStyles.dummy} style={{ top: 545, right: 190 }}>
          <img src="images/board-sq.png" />
          <div className={gameStyles.dummyStat}></div>
        </div> */}
      </div>
    </CSSTransition>
  );
};

export default GameUI;
