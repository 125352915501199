import Phaser from "phaser";
import TripleBars from "../components/spinners/basic/TripleBars";

export class OptionView extends Phaser.GameObjects.Container {
  constructor(scene, x, y, children) {
    super(scene, 790, 0, children);

    this.width = 900;
    this.height = 720;
    scene.add.existing(this);
    this.spinner = TripleBars.create(scene, 0, this.height / 2)
      .useBarWidth(20)
      .useBarHeight(64)
      .useBarGap(14)
      .addToContainer(this);

    this.spinner.hide();
  }

  showLoader() {
    this.spinner.show();
    this.spinner.resume();
  }

  hideLoader() {
    this.spinner.stop();
    this.spinner.hide();
  }
}
