import { CSSTransition } from "react-transition-group";
import Box from "../../components/Box";
import MenuButton from "../../components/Form/MenuButton";
import RoninButton from "../../components/Form/RoninButton";
import Input from "../../components/Form/Input";
import ForgotPassword from "./ForgotPassword";
import Register from "./Register";
import { validateEmail } from "../../utils";
import { TRANSITIONS } from "../../../../../constants";
import { signIn } from "../../../gameSrc/helpers/api";
import ModalSpinner from "../../components/ModalLoader";

import styles from "../../ui.module.scss";
import loginStyles from "./login.module.scss";
import PasswordField from "../../components/Form/PasswordField";
import { useState, useReducer, useEffect } from "react";
import { SOUND_FX } from "../../../gameSrc/sound/Sound";
import { DAOlogin } from "../../../gameSrc/helpers/login";

const initState = {
  email: "",
  password: "",
};

const reducer = (state, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "email":
      newState.email = action.value;
      return newState;
      break;

    case "password":
      newState.password = action.value;
      return newState;
      break;

    default:
      return newState;
  }
};

const Login = ({ show, onLogin, playSound }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [modal, showModal] = useState(false);
  const [register, showRegister] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [sending, submitForm] = useState(false);
  const [errorMsg, setError] = useState("");
  const [connectingRonin, loginWithRonin] = useState(false);

  useEffect(() => {
    if (validateEmail(state.email)) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [state]);

  const handleSignIn = async () => {
    setError("");
    submitForm(true);
    try {
      const response = await signIn(state.email, state.password);

      window.localStorage.setItem("token", response.token);
      await onLogin();
    } catch (e) {
      setError(e.message);
    }
    submitForm(false);
  };

  const handleRonin = async () => {
    loginWithRonin(true);
    playSound(SOUND_FX.CLICK, { volume: 0.1 });

    try {
      await DAOlogin();

      await onLogin();
    } catch (e) {
      alert("Error connecting your Ronin Wallet. Please try again later.");
    }

    loginWithRonin(false);
  };
  return (
    <CSSTransition
      in={show}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      unmountOnExit
    >
      <div className={styles.screen}>
        <Box
          className={styles.centerHorizontal}
          style={{ width: 460, top: 20 }}
        >
          <RoninButton
            text={"Login with Ronin Wallet"}
            onClick={handleRonin}
            disabled={connectingRonin}
          />
          <div
            className={styles.separator}
            style={{ marginTop: 24, marginBottom: 20 }}
          >
            <span>or</span>
          </div>
          <div
            className={[styles.errorMsg, errorMsg ? styles.showError : ""]
              .join(" ")
              .trim()}
          >
            {errorMsg}
          </div>
          <Input
            name="email"
            label="Email"
            required
            style={{ marginBottom: 10 }}
            validationFn={validateEmail}
            errorMsg="Enter valid email address"
            changeFn={(val) => {
              dispatch({ type: "email", value: val });
            }}
          />
          <PasswordField
            name="password"
            label="Password"
            required
            style={{ marginBottom: 5 }}
            validationFn={(val) => {
              return val.trim() !== "";
            }}
            errorMsg="Enter a password"
            changeFn={(val) => {
              dispatch({ type: "password", value: val });
            }}
          />
          <div
            className={loginStyles.forgot}
            style={{ marginBottom: 20 }}
            onClick={() => {
              showModal(true);
            }}
          >
            Forgot password?
          </div>
          <MenuButton
            text={"Sign in"}
            disabled={!validForm}
            onClick={handleSignIn}
          />
          <div
            className={[styles.separator, styles.light].join(" ")}
            style={{ marginTop: 14, marginBottom: 10 }}
          ></div>
          <div style={{ fontWeight: 600, fontSize: 18, paddingLeft: 5 }}>
            New to <span className={styles.blitz}>Axie Blitz</span> ?
            <MenuButton
              text={"Register Now"}
              size={"small"}
              display="inline"
              style={{ marginLeft: 15 }}
              onClick={() => {
                showRegister(true);
              }}
            />
          </div>

          <ForgotPassword
            show={modal}
            onClose={() => {
              showModal(false);
            }}
          />

          <Register
            show={register}
            onClose={() => {
              showRegister(false);
            }}
          />
          <ModalSpinner show={sending} style={{ borderRadius: 24 }} />
        </Box>
      </div>
    </CSSTransition>
  );
};

export default Login;
