import Phaser from "phaser";
import { OptionView } from "../../OptionView";
import { getUserAxies, syncUserAxies } from "../../../helpers/api";
import { SOUND_FX } from "../../../sound/Sound";

export class MyAxies extends OptionView {
  constructor(scene, x, y, children) {
    super(scene, x, y, children);
    this.axies = [];
    this.menuAxies = {};
    this.texturesQueued = [];
    this.createList();

    this.syncBtn = this.scene.make.image({
      x: -390,
      y: 40,
      key: "sync",
      add: true,
    });
    this.syncBtn.setInteractive();
    this.add(this.syncBtn);

    this.syncText = this.scene.make.text({
      x: -368,
      y: 38,
      text: "Sync Axies",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "26px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#ffffff",
      },
      add: true,
      origin: {
        x: 0,
        y: 0.5,
      },
    });
    this.syncText.setInteractive();
    this.add(this.syncText);

    this.scene.input.on("gameobjectover", (pointer, gameObject, event) => {
      if (gameObject === this.syncBtn || gameObject === this.syncText) {
        this.scene.game.events.emit("play-fx", SOUND_FX.HOVER);
        this.hover();
      }
    });

    this.scene.input.on("gameobjectout", (pointer, gameObject, event) => {
      if (gameObject === this.syncBtn || gameObject === this.syncText) {
        this.out();
      }
    });

    this.scene.input.on("gameobjectdown", (pointer, gameObject, event) => {
      if (gameObject === this.syncBtn || gameObject === this.syncText) {
        this.scene.game.events.emit("play-fx", SOUND_FX.CLICK);
        this.syncAxies();
      }
    });
  }

  hover() {
    const glowPlugin = this.scene.plugins.get("rexGlowFilterPipeline");
    const glowBtnPipeline = glowPlugin.add(this.syncBtn);
    const glowTextPipeline = glowPlugin.add(this.syncText);

    this.syncBtn.glowTask = this.scene.tweens.add({
      targets: glowBtnPipeline,
      intensity: 0.003,
      ease: "Sine.easeIn",
      duration: 500,
      repeat: -1,
      yoyo: true,
    });

    this.syncText.glowTask = this.scene.tweens.add({
      targets: glowTextPipeline,
      intensity: 0.003,
      ease: "Sine.easeIn",
      duration: 500,
      repeat: -1,
      yoyo: true,
    });
  }

  out() {
    if (!this.syncBtn.glowTask) {
      return;
    }

    const glowPlugin = this.scene.plugins.get("rexGlowFilterPipeline");
    glowPlugin.remove(this.syncBtn);
    glowPlugin.remove(this.syncText);
    this.syncBtn.glowTask.stop();
    this.syncBtn.glowTask = null;
    this.syncText.glowTask.stop();
    this.syncText.glowTask = null;
  }

  async syncAxies() {
    if (this.syncing) {
      return;
    }

    this.syncing = true;
    this.axies = [];
    this.updateOptions();
    this.showLoader();

    try {
      this.axies = await syncUserAxies();
      this.menuAxies = {};
      this.hideLoader();
      this.updateOptions();
    } catch (e) {
      console.log("error syncing axies", e);
    }
  }

  async getAxies() {
    try {
      const axies = await getUserAxies();
      console.log(axies);
      this.menuAxies = {};
      this.axies = axies;
    } catch (e) {
      console.log("error getting axies", e);
      this.axies = [];
    }
  }

  async show() {
    if (this.visibilityTween) {
      this.visibilityTween.stop();
      this.visibilityTween.remove();
      this.visibilityTween = null;
    }

    this.visibilityTween = this.scene.tweens.add({
      targets: [this, this.scrollableMenu],
      alpha: 1,
      duration: 333,
    });

    this.showLoader();
    await this.getAxies();
    this.hideLoader();
    this.updateOptions();
  }

  hide() {
    if (this.visibilityTween) {
      this.visibilityTween.stop();
      this.visibilityTween.remove();
      this.visibilityTween = null;
    }

    this.visibilityTween = this.scene.tweens.add({
      targets: [this, this.scrollableMenu],
      alpha: 0,
      duration: 333,
    });
  }

  createList() {
    const track = this.scene.rexUI.add.roundRectangle(0, 0, 20, 6, 6, 0xd4b98b);
    track.alpha = 0.75;
    track.depth = 5;

    this.scrollableMenu = this.scene.rexUI.add.scrollablePanel({
      height: this.height - 90,
      width: this.width,
      x: this.x,
      y: 70,
      scrollMode: 0,

      mouseWheelScroller: {
        focus: false,
        speed: 0.1,
      },
      slider: {
        track: track,
        thumb: this.scene.make.image({
          x: 0,
          y: 0,
          key: "menu-thumb",
          add: true,
          depth: 5,
        }),
      },
      panel: {
        child: this.createAxieOptions(),
      },
      space: {
        left: 20,
        right: 30,
        top: 40,
        bottom: 30,
      },
    });
    this.scrollableMenu.setOrigin(0.5, 0);

    this.scrollableMenu.layout();

    this.add(this.scrollableMenu);
  }

  updateOptions() {
    const sizer = this.scrollableMenu.getElement("panel");
    sizer.clear(true);

    this.axies.forEach((data) => {
      const axie = data.axie;
      if (this.menuAxies[axie.axie_id]) {
        sizer.add(this.menuAxies[axie.axie_id]);
      } else {
        const option = this.scene.add.container(0, 0);
        const img = this.scene.add.image(0, 10, "body-placeholder");
        // img.setSize(1280, 960);
        // img.setScale(0.125);
        option.width = 160;
        option.height = 160;
        option.name = "axie-" + axie.axie_id;

        option.add(
          this.scene.make.text({
            x: 0,
            y: -74,
            text: axie.name,
            style: {
              fontFamily: "Luckiest Guy",
              fontSize: "14px",
              stroke: "#7d5d2a",
              strokeThickness: 4,
              fill: "#ffffff",
              wordWrap: {
                width: 160,
              },
              fixedWidth: 160,
              align: "center",
              maxLines: 3,
            },
            add: true,
            origin: {
              x: 0.5,
              y: 0,
            },
          })
        );

        this.menuAxies[axie.axie_id] = img;
        console.log(axie);
        if (this.scene.textures.exists(`png-${axie.axie_id}`)) {
          img.setTexture(`png-${axie.axie_id}`);
          img.setScale(0.125);
        } else {
          if (this.texturesQueued.indexOf(axie.axie_id) < 0) {
            this.texturesQueued.push(`png-${axie.axie_id}`);
            console.log(`png-${axie.axie_id}`);
            this.scene.load.image(`png-${axie.axie_id}`, axie.image);
          }
        }

        option.add(img);
        option.depth = 5;
        option.setData("axieId", axie.axie_id);
        sizer.add(option);
        sizer.layout();
      }
    });

    this.scene.load.start();

    if (!this.loaderAttached) {
      this.loaderAttached = true;
      this.scene.load.on("filecomplete", (key) => {
        if (!this.texturesQueued) {
          return;
        }

        const fileIndex = this.texturesQueued.indexOf(key);

        if (fileIndex >= 0) {
          this.menuAxies[key.replace("png-", "")].setTexture(key);
          this.menuAxies[key.replace("png-", "")].setScale(0.125);

          this.texturesQueued.splice(fileIndex, 1);
          sizer.layout();
          this.scrollableMenu.layout();
        }
      });
    }

    this.scrollableMenu.layout();
  }

  createAxieOptions() {
    // 1280*960
    const sizer = this.scene.rexUI.add.fixWidthSizer({
      space: {
        left: 3,
        right: 5,
        top: 3,
        bottom: 3,
        item: 5,
        line: 8,
      },
    });

    // sizer.layout();

    return sizer;
  }
}
