import Phaser from "phaser";
import { AxieDetailsModal } from "./AxieSelectionDetails";
import { SOUND_FX } from "./sound/Sound";

export class AxieSelection {
  constructor(scene, playerState) {
    this.scene = scene;
    this.playerState = playerState;

    this.texturesQueued = [];
    this.menuAxies = {};
    this.menuReady = false;
    this.startLockCount = false;
    this.selectedOption = null;
    this.lockedOption = null;

    this.createButtons();
    this.createMenu();
    this.setListeners();

    // this.playerState.onChange = (changes) => {
    //   changes.forEach((change) => {
    //     if (change.field === "ownedAxies") {
    //       console.log("ownedaxies", change.value, change.previousValue);
    //       if (change.value.length) {
    //         this.createMenu();
    // this.enableButtons();
    //       }
    //     }
    //   });
    // };
    this.playerState.triggerAll();
    this.detailsPanel = new AxieDetailsModal(
      this.scene,
      this.scene.cameras.main.width / 2 + 420,
      350
    );
    // this.add(this.detailsPanel);
  }

  createButtons() {
    const y = 50;
    this.button = this.scene.add.container(
      this.scene.cameras.main.width / 2,
      0
    );
    this.button.depth = 5;
    this.button.alpha = 0;

    this.button.add(
      this.scene.make.image({
        x: 0,
        y: y,
        key: "menu-button",
        scale: {
          x: 0.165,
          y: 0.12,
        },
        add: true,
      })
    );

    this.buttonText = this.scene.make.text({
      x: 0,
      y: y,
      text: "choose your axie",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "20px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#ffffff",
        wordWrap: {
          width: 180,
        },
        fixedWidth: 180,
        align: "center",
      },
      add: true,
      origin: {
        x: 0.5,
        y: 0.5,
      },
    });

    this.button.add(this.buttonText);

    this.button.setInteractive(
      new Phaser.Geom.Rectangle(-100, y - 30, 200, 60),
      Phaser.Geom.Rectangle.Contains
    );

    this.buttonActive = this.scene.add.container(
      this.scene.cameras.main.width / 2,
      0
    );
    this.buttonActive.depth = 5;

    this.buttonActive.add(
      this.scene.make.image({
        x: 0,
        y: y,
        key: "menu-button-active",
        scale: {
          x: 0.165,
          y: 0.12,
        },
        add: true,
      })
    );

    this.buttonActiveText = this.scene.make.text({
      x: 0,
      y: y,
      text: "choose your axie",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "20px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#ffffff",
        wordWrap: {
          width: 180,
        },
        fixedWidth: 180,
        align: "center",
      },
      add: true,
      origin: {
        x: 0.5,
        y: 0.5,
      },
    });
    this.buttonActive.add(this.buttonActiveText);

    this.buttonActive.visible = false;

    this.buttonActive.setInteractive(
      new Phaser.Geom.Rectangle(-100, y - 30, 200, 60),
      Phaser.Geom.Rectangle.Contains
    );

    this.countdownText = this.scene.make.text({
      x: this.scene.cameras.main.width / 2,
      y: y,
      text: "",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "24px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#ffffff",
        wordWrap: {
          width: 180,
        },
        fixedWidth: 180,
        align: "center",
      },
      add: true,
      origin: {
        x: 0.5,
        y: 0.5,
      },
    });

    this.countdownText.alpha = 0;
    this.countdownText.depth = 5;
  }

  enableButtons(delay) {
    // this.toggleMenu(true)0, 200, 60false,;
    this.scene.tweens.add({
      targets: this.button,
      alpha: 1,
      duration: 500,
      delay: delay ? delay : 0,
    });
  }

  createMenu() {
    this.menuContainer = this.scene.add.container(
      this.scene.cameras.main.width / 2,
      0
    );

    const track = this.scene.rexUI.add.roundRectangle(0, 0, 20, 6, 6, 0xd4b98b);
    track.alpha = 0.75;
    track.depth = 5;

    this.scrollableMenu = this.scene.rexUI.add.scrollablePanel({
      height: 450,
      width: 428,
      x: this.scene.cameras.main.width / 2,
      y: 270,
      scrollMode: 0,
      background: this.scene.make.image({
        x: 0,
        y: 0,
        key: "menu-selection",
        scale: {
          x: 0.2,
          y: 0.15,
        },
        add: true,
        depth: 4,
      }),
      mouseWheelScroller: {
        focus: false,
        speed: 0.1,
      },
      slider: {
        track: track,
        thumb: this.scene.make.image({
          x: 0,
          y: 0,
          key: "menu-thumb",
          add: true,
          depth: 5,
        }),
      },
      panel: {
        child: this.createAxieOptions(this.playerState.ownedAxies),
      },
      space: {
        left: 20,
        right: 30,
        top: 40,
        bottom: 30,
      },
    });

    this.scrollableMenu.layout();
    this.scrollableMenu.visible = false;
    this.scrollableMenu.alpha = 0;
    this.menuReady = true;

    this.scrollableMenu
      .setChildrenInteractive()
      .on("child.click", (child, pointer, event) => {
        this.scrollableMenu.getElement("panel").children.forEach((option) => {
          option.getByName("background").visible = false;
        });

        this.selectedOption = child.getData("axieId");
        child.getByName("background").visible = true;

        this.scene.game.events.emit("play-fx", SOUND_FX.SHOW_DETAILS);

        this.detailsPanel.show(
          this.playerState.ownedAxies.find(
            (axie) => axie.axieId === this.selectedOption
          )
        );

        this.buttonSelectImage.setTint(0xffffff);
        this.buttonSelectText.setTint(0xffffff);

        // console.log(
        //   // this.scrollableMenu,
        //   this.scrollableMenu.getElement("panel")
        // );
      });
    // .on("child.pressstart", function (child, pointer, event) {
    //   console.log(child);
    // });

    this.buttonSelect = this.scene.add.container(
      this.scene.cameras.main.width / 2,
      0
    );
    this.buttonSelect.depth = 4;

    this.buttonSelectImage = this.scene.make.image({
      x: 0,
      y: 496,
      key: "menu-green-button",
      add: true,
    });

    // window.rr = this.buttonSelectImage;
    this.buttonSelect.add(this.buttonSelectImage);

    this.buttonSelectText = this.scene.make.text({
      x: 0,
      y: 496,
      text: "select",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "24px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#ffffff",
        wordWrap: {
          width: 180,
        },
        fixedWidth: 180,
        align: "center",
      },
      add: true,
      origin: {
        x: 0.5,
        y: 0.5,
      },
    });
    this.buttonSelect.add(this.buttonSelectText);
    this.buttonSelect.alpha = 0;
    this.buttonSelect.visible = false;

    this.buttonSelectImage.setTint(0x446644);
    this.buttonSelectText.setTint(0x666666);
    // this.buttonSelect.visible = false;

    this.buttonSelect.setInteractive(
      new Phaser.Geom.Rectangle(-88, 466, 176, 60),
      Phaser.Geom.Rectangle.Contains
    );
  }

  toggleMenu(show) {
    this.showMenu = show;
    if (this.menuToggling) {
      return;
    }

    this.menuToggling = true;

    if (show) {
      this.scrollableMenu.visible = show;
      this.buttonSelect.visible = show;

      if (this.selectedOption) {
        this.detailsPanel.show();
      }
    } else {
      this.detailsPanel.hide();
    }

    // if (show) {
    this.button.visible = !show;
    this.buttonActive.visible = show;
    // }

    this.scene.tweens.add({
      targets: [this.scrollableMenu, this.buttonSelect],
      alpha: show ? 1 : 0,
      duration: 250,
      onComplete: () => {
        this.menuToggling = false;
        if (!show) {
          this.scrollableMenu.visible = show;
          this.buttonSelect.visible = show;
        }
      },
    });

    this.scene.events.emit("menu-toggle", show);
  }

  createAxieOptions(ownedAxies) {
    // 1280*960
    const sizer = this.scene.rexUI.add.fixWidthSizer({
      space: {
        left: 3,
        right: 5,
        top: 10,
        bottom: 3,
        item: 5,
        line: 8,
      },
    });

    ownedAxies.forEach((axie) => {
      if (this.menuAxies[axie.axieId]) {
        sizer.add(this.menuAxies[axie.axieId]);
      } else {
        const option = this.scene.add.container(0, 0);
        const img = this.scene.add.image(0, 10, "body-placeholder");
        img.setSize(1280, 960);
        img.setScale(0.132);
        option.width = 170;
        option.height = 160;
        option.name = "axie-" + axie.axieId;

        const background = this.scene.add.image(0, 0, "skill-mask");
        background.setSize(170, 160);
        background.name = "background";
        background.visible = false;
        option.add(background);

        option.add(
          this.scene.make.text({
            x: 0,
            y: -74,
            text: axie.name,
            style: {
              fontFamily: "Luckiest Guy",
              fontSize: "14px",
              stroke: "#7d5d2a",
              strokeThickness: 4,
              fill: "#ffffff",
              wordWrap: {
                width: 164,
              },
              fixedWidth: 164,
              align: "center",
              maxLines: 3,
            },
            add: true,
            origin: {
              x: 0.5,
              y: 0,
            },
          })
        );

        this.menuAxies[axie.axieId] = img;

        if (this.scene.textures.exists(`png-${axie.axieId}`)) {
          img.setTexture(`png-${axie.axieId}`);
        } else {
          if (this.texturesQueued.indexOf(axie.axieId) < 0) {
            this.texturesQueued.push(`png-${axie.axieId}`);

            this.scene.load.image(`png-${axie.axieId}`, axie.imageUrl);
          }
        }

        option.add(img);
        option.depth = 5;
        option.setData("axieId", axie.axieId);
        sizer.add(option);
        sizer.layout();
      }
    });

    this.scene.load.start();

    if (!this.loaderAttached) {
      this.loaderAttached = true;
      this.scene.load.on("filecomplete", (key) => {
        if (!this.texturesQueued) {
          return;
        }

        const fileIndex = this.texturesQueued.indexOf(key);

        if (fileIndex >= 0) {
          this.menuAxies[key.replace("png-", "")].setTexture(key);
          // this.menuAxies[key.replace("png-", "")].setScale(0.125);

          this.texturesQueued.splice(fileIndex, 1);
          sizer.layout();
          this.scrollableMenu.layout();
        }
      });
    }

    // sizer.layout();

    return sizer;
  }

  setListeners() {
    const glowPlugin = this.scene.plugins.get("rexGlowFilterPipeline");

    this.scene.input.on("gameobjectover", (pointer, gameObject, event) => {
      if (gameObject === this.button) {
        this.button.alpha = 0.85;
        if (this.startLockCount) {
          this.buttonText.alpha = 1;
          this.buttonActiveText.alpha = 1;
          this.countdownText.alpha = 0;
        }
      } else if (gameObject === this.buttonSelect && this.selectedOption) {
        this.buttonSelect.alpha = 0.95;
      }
    });

    this.scene.input.on("gameobjectout", (pointer, gameObject, event) => {
      if (gameObject === this.button) {
        this.button.alpha = 1;
        if (this.startLockCount) {
          this.buttonText.alpha = 0;
          this.buttonActiveText.alpha = 0;
          this.countdownText.alpha = 1;
        }
      } else if (gameObject === this.buttonSelect) {
        this.buttonSelect.alpha = 1;
      }
    });

    this.scene.input.on("gameobjectdown", (pointer, gameObject, event) => {
      if (gameObject === this.button) {
        this.scene.game.events.emit("play-fx", SOUND_FX.CLICK);
        this.toggleMenu(true);
        // this.button.visible = false;
        // this.buttonActive.visible = true;
      } else if (gameObject === this.buttonActive) {
        // this.buttonActive.visible = false;
        // this.button.visible = true;
        this.scene.game.events.emit("play-fx", SOUND_FX.CLICK);
        this.toggleMenu(false);
      } else if (
        gameObject === this.buttonSelect &&
        !this.lockedOption &&
        this.selectedOption
      ) {
        this.lockedOption = this.selectedOption;

        this.fadeButtons();
        // console.log("TOGGLE OFF from lock button");
        this.scene.game.events.emit("play-fx", SOUND_FX.LOCK);
        this.toggleMenu(false);

        if (this.buttonSelect.glowTask) {
          glowPlugin.remove(this.buttonSelect);
          this.buttonSelect.glowTask.stop();
          this.buttonSelect.glowTask = null;
          this.buttonSelect.beat.stop();
          this.buttonSelect.beat = null;
        }

        // send axie selection if lock count started
        if (this.startLockCount) {
          this.sendAxieSelection();
        } else {
          this.scene.events.emit(
            "client-axie-selection",
            this.playerState.ownedAxies.find(
              (axie) => axie.axieId === this.lockedOption
            )
          );
        }
      }
    });

    this.scene.events.on("update", (time, delta) => {
      if (!this.startLockCount || this.lockedOption) {
        return;
      }

      this.currentLockTime += delta;

      this.updateCount();
    });
  }

  fadeButtons() {
    this.scene.tweens.add({
      targets: [this.button, this.buttonActive, this.countdownText],
      alpha: 0,
      duration: 250,
      onComplete: () => {
        this.button.visible = false;
        this.buttonActive.visible = false;
        this.countdownText.visible = false;
      },
    });
  }

  sendAxieSelection() {
    this.scene.events.emit("axie-selection", this.lockedOption);
  }

  timeoutLockAxie() {
    if (this.selectedOption) {
      this.lockedOption = this.selectedOption;
    } else {
      this.lockedOption = this.playerState.ownedAxies[0].axieId;
    }
    this.fadeButtons();
    this.toggleMenu(false);
    this.sendAxieSelection();
  }

  startSelection(lockTime) {
    this.startLockCount = true;
    this.targetLockTime = lockTime;
    this.targetCountSeconds = 0;
    this.currentLockTime = new Date().getTime();

    if (this.lockedOption) {
      this.sendAxieSelection();
      return;
    }

    this.scene.tweens.add({
      targets: [this.buttonText, this.buttonActiveText],
      alpha: 0,
      duration: 250,
    });

    this.scene.tweens.add({
      targets: this.countdownText,
      alpha: 1,
      duration: 250,
    });

    this.updateCount();

    if (!this.showMenu) {
      this.toggleMenu(true);
    }

    // make select button glow
    const glowPlugin = this.scene.plugins.get("rexGlowFilterPipeline");
    const glowPipeline = glowPlugin.add(this.buttonSelect);

    this.buttonSelect.glowTask = this.scene.tweens.add({
      targets: glowPipeline,
      intensity: 0.005,
      ease: "Sine.easeIn",
      duration: 400,
      repeat: -1,
      yoyo: true,
    });

    this.buttonSelect.beat = this.scene.tweens.add({
      targets: [this.buttonSelectImage, this.buttonSelectText],
      scale: 1.025,
      ease: "Sine.easeIn",
      duration: 400,
      repeat: -1,
      yoyo: true,
    });
  }

  updateCount() {
    // check if time ran out
    if (this.currentLockTime >= this.targetLockTime + 200) {
      this.timeoutLockAxie();
      return;
    }

    const seconds = Math.ceil(
      (this.targetLockTime - this.currentLockTime) / 1000
    );

    if (this.targetCountSeconds === seconds || seconds < 0) {
      return;
    }

    this.targetCountSeconds = seconds;
    this.scene.game.events.emit(
      "play-fx",
      seconds % 2 === 0 ? SOUND_FX.TICK : SOUND_FX.TOCK
    );
    this.countdownText.setText(seconds.toString());
  }
}
