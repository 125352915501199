import { useState } from "react";
import Input from "./Input";

import styles from "./input.module.scss";

const PasswordField = ({ ...rest }) => {
  const [show, showPassword] = useState(false);

  return (
    <Input
      type={show ? "text" : "password"}
      {...rest}
      className={styles.passwordField}
    >
      <span
        className={styles.showPass}
        onClick={() => {
          showPassword(!show);
        }}
      >
        {show ? "hide" : "show"}
      </span>
    </Input>
  );
};

export default PasswordField;
