import { useCallback, useEffect, useState } from "react";
import { TRANSITIONS } from "../../../../constants";
import ModalSpinner from "./ModalLoader";
import { CSSTransition } from "react-transition-group";
import parser from "bbcode-to-react";
import styles from "./AxieStatsModal.module.scss";
import uiStyles from "../../UI/ui.module.scss";
import { getAxieStats } from "../../gameSrc/helpers/api";

const MAX_STATS = {
  speed: 1035,
  evasion: 250,
  luck: 1490,
  wall: 6050,
};

const MIN_STATS = {
  speed: 770,
  evasion: 38,
  luck: 180,
  wall: 5400,
};

const STATS_SPREAD = {
  speed: MAX_STATS.speed - MIN_STATS.speed,
  evasion: MAX_STATS.evasion - MIN_STATS.evasion,
  luck: MAX_STATS.luck - MIN_STATS.luck,
  wall: MAX_STATS.wall - MIN_STATS.wall,
};

const AxieStatsModal = ({
  axieId,
  className = "",
  showFavorite,
  favoriteId,
  disableFavorite,
  onFavoriteClick = () => {},
  onClose = () => {},
  showArrows = false,
  nextFn = () => {},
  previousFn = () => {},
}) => {
  const [data, setData] = useState(null);
  const [fetchedId, setFetchedId] = useState(null);
  const [showDetails, toggleDetails] = useState(null);
  const [modifiers, setModifiers] = useState();

  useEffect(() => {
    if (!axieId) {
      setData(null);
      return;
    }
    const abortController = new AbortController();

    const fetchData = async () => {
      // setTimeout(async () => {
      try {
        const response = await getAxieStats(axieId, abortController);
        setFetchedId(axieId);

        setModifiers(
          response.statModifiers.reduce(
            (acc, value) => {
              Object.keys(value).forEach((stat) => {
                if (["speed", "evasion", "luck", "wall"].includes(stat)) {
                  if (value[stat] !== 0) {
                    if (value[stat] > 0) {
                      acc[stat].value += value[stat];
                    } else {
                      acc[stat].neg += value[stat];
                    }

                    acc[stat].mods.push({
                      plus: value[stat] > 0,
                      label: value.typeValue,
                    });

                    if (value[stat] < 0) {
                      response.stats[stat] += Math.abs(value[stat]);
                    }
                  }
                }
              });

              return acc;
            },
            {
              speed: { value: 0, neg: 0, mods: [] },
              evasion: { value: 0, neg: 0, mods: [] },
              luck: { value: 0, neg: 0, mods: [] },
              wall: { value: 0, neg: 0, mods: [] },
            }
          )
        );
        setData(response);
      } catch (error) {
        if (error.name === "AbortError") {
          // Aborting a fetch throws an error
          // So we can't update state afterwards
        }
        // Handle other request errors here
      }
      // }, Math.round(Math.random() * 12000));
    };

    fetchData();
    return () => {
      setData(null);
      setFetchedId(null);
      toggleDetails(false);
      abortController.abort();
    };
  }, [axieId]);

  return (
    <CSSTransition
      in={!!axieId}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      unmountOnExit
    >
      <div className={[styles.container, className].join(" ").trim()}>
        <CSSTransition
          in={!!fetchedId}
          timeout={TRANSITIONS.fade}
          classNames="tr-fade"
          onEntered={() => {
            toggleDetails(true);
          }}
          unmountOnExit
        >
          <div
            className={[styles.wrapper, showDetails ? styles.showDetails : ""]
              .join(" ")
              .trim()}
          >
            {data ? (
              <>
                <div
                  className={[
                    styles.statHeader,
                    showArrows ? styles.withArrows : "",
                  ]
                    .join(" ")
                    .trim()}
                >
                  {showArrows ? (
                    <>
                      <button
                        className={styles.previous}
                        onClick={previousFn}
                        onMouseEnter={() => {
                          // playSound(SOUND_FX.HOVER, { volume: 0.25 });
                        }}
                        disabled={!fetchedId}
                      >
                        <img src="images/ui/arrow.png" alt="logout" />
                      </button>
                      <button
                        className={styles.next}
                        onClick={nextFn}
                        onMouseEnter={() => {
                          // playSound(SOUND_FX.HOVER, { volume: 0.25 });
                        }}
                        disabled={!fetchedId}
                      >
                        <img src="images/ui/arrow.png" alt="logout" />
                      </button>
                    </>
                  ) : null}

                  <img
                    src={`https://axiecdn.axieinfinity.com/axies/${data.axie.axieId}/axie/axie-full-transparent.png`}
                    alt={`${data.axie.name} image`}
                  />
                  <div className={styles.statsWrapper}>
                    <div className={styles.nameContainer}>
                      {showFavorite && (
                        <div
                          className={[
                            styles.favorite,
                            favoriteId === data.axie.axieId
                              ? styles.selected
                              : "",
                            disableFavorite ? styles.disabled : "",
                          ]
                            .join(" ")
                            .trim()}
                          onClick={() => {
                            onFavoriteClick(data.axie.axieId);
                          }}
                        ></div>
                      )}
                      <span className={uiStyles.blitz}>
                        <img src={`images/${data.axie.axieClass}.png`} />
                        {data.axie.name}
                      </span>
                    </div>
                    <div className={[styles.stats, uiStyles.blitz].join(" ")}>
                      <div className={styles.statLine}>
                        <div className={styles.statLabel}>speed</div>
                        <div className={styles.statBar}>
                          <div
                            className={styles.fill}
                            style={
                              showDetails
                                ? {
                                    width: `${
                                      ((data.stats.speed - MIN_STATS.speed) /
                                        STATS_SPREAD.speed) *
                                      100
                                    }%`,
                                    opacity: 1,
                                  }
                                : null
                            }
                          >
                            <div
                              className={[
                                styles.modifier,
                                Math.abs(modifiers.speed.neg) >
                                modifiers.speed.value
                                  ? styles.red
                                  : styles.green,
                              ].join(" ")}
                              style={{
                                width: `${
                                  Math.abs(modifiers.speed.neg) >
                                  modifiers.speed.value
                                    ? (-modifiers.speed.neg /
                                        data.stats.speed) *
                                      100
                                    : (modifiers.speed.value /
                                        data.stats.speed) *
                                      100
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.statLine}>
                        <div className={styles.statLabel}>evasion</div>
                        <div className={styles.statBar}>
                          <div
                            className={styles.fill}
                            style={
                              showDetails
                                ? {
                                    width: `${
                                      ((data.stats.evasion -
                                        MIN_STATS.evasion) /
                                        STATS_SPREAD.evasion) *
                                      100
                                    }%`,
                                    opacity: 1,
                                  }
                                : null
                            }
                          >
                            <div
                              className={[
                                styles.modifier,
                                modifiers.evasion.value >= 0
                                  ? styles.green
                                  : styles.red,
                              ].join(" ")}
                              style={{
                                width: `${
                                  (modifiers.evasion.value /
                                    data.stats.evasion) *
                                  100
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.statLine}>
                        <div className={styles.statLabel}>luck</div>
                        <div className={styles.statBar}>
                          <div
                            className={styles.fill}
                            style={
                              showDetails
                                ? {
                                    width: `${
                                      ((data.stats.luck - MIN_STATS.luck) /
                                        STATS_SPREAD.luck) *
                                      100
                                    }%`,
                                    opacity: 1,
                                  }
                                : null
                            }
                          >
                            <div
                              className={[
                                styles.modifier,
                                modifiers.luck.value >= 0
                                  ? styles.green
                                  : styles.red,
                              ].join(" ")}
                              style={{
                                width: `${
                                  (modifiers.luck.value / data.stats.luck) * 100
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.statLine}>
                        <div className={styles.statLabel}>wall</div>
                        <div className={styles.statBar}>
                          <div
                            className={styles.fill}
                            style={
                              showDetails
                                ? {
                                    width: `${
                                      ((data.stats.wall - MIN_STATS.wall) /
                                        STATS_SPREAD.wall) *
                                      100
                                    }%`,
                                    opacity: 1,
                                  }
                                : null
                            }
                          >
                            <div
                              className={[
                                styles.modifier,
                                modifiers.wall.value >= 0
                                  ? styles.green
                                  : styles.red,
                              ].join(" ")}
                              style={{
                                width: `${
                                  (modifiers.wall.value / data.stats.wall) * 100
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.abilitiesContainer}>
                  {Object.keys(data.parts).map((part, i) => (
                    <div
                      className={styles.ability}
                      key={i}
                      style={{ transitionDelay: `${0.3 + 0.05 * i}s` }}
                    >
                      <img
                        src={`images/skills/${data.parts[part].cardId}.png`}
                        alt={`${data.parts[part].cardName} image`}
                      />
                      <div className={styles.description}>
                        <div className={styles.partName}>
                          {data.parts[part].name}
                        </div>
                        <div className={styles.cardName}>
                          {data.parts[part].cardName}
                        </div>
                        <p>{parser.toReact(data.parts[part].description)}</p>
                        <div className={styles.cast}>
                          <div className={styles.mana}>
                            mana cost:{" "}
                            <img
                              src={`images/${data.parts[part].partClass}.png`}
                            />{" "}
                            {data.parts[part].manaCost}
                          </div>
                          <div className={styles.cd}>
                            cooldown: {data.parts[part].cooldown / 1000} sec
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </CSSTransition>
        <ModalSpinner show={!fetchedId} style={{ borderRadius: 24 }} />
        <button className={styles.close} onClick={onClose}>
          <svg
            style={{ fill: "currentcolor" }}
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.293 6.293a1 1 0 0 1 1.414 0L12 10.586l4.293-4.293a1 1 0 1 1 1.414 1.414L13.414 12l4.293 4.293a1 1 0 0 1-1.414 1.414L12 13.414l-4.293 4.293a1 1 0 0 1-1.414-1.414L10.586 12 6.293 7.707a1 1 0 0 1 0-1.414Z"
              fill="currentColor"
            ></path>
          </svg>
        </button>
      </div>
    </CSSTransition>
  );
};

export default AxieStatsModal;
