import { SOUND_FX } from "../../sound/Sound";

export class ProfileSideNav {
  constructor(scene) {
    const midY = scene.cameras.main.height / 2;
    this.scene = scene;
    this.scene.add.rectangle(
      150,
      midY,
      300,
      this.scene.cameras.main.height,
      0xab8d66
    );

    this.myAxies = this.scene.make.text({
      x: 150,
      y: midY - 200,
      text: "My Axies",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "28px",
        stroke: "#ffffff",
        strokeThickness: 4,
        fill: "#7d5d2a",
        wordWrap: {
          width: 250,
        },
        fixedWidth: 250,
        align: "center",
      },
      add: true,
      origin: {
        x: 0.5,
        y: 0.5,
      },
    });
    this.myAxies.setInteractive();
    this.myAxies.setData("option", "axies");

    this.matchHistory = this.scene.make.text({
      x: 150,
      y: midY - 120,
      text: "Match history",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "28px",
        stroke: "#ffffff",
        strokeThickness: 4,
        fill: "#7d5d2a",
        wordWrap: {
          width: 250,
        },
        fixedWidth: 250,
        align: "center",
      },
      add: true,
      origin: {
        x: 0.5,
        y: 0.5,
      },
    });
    this.matchHistory.setInteractive();
    this.matchHistory.setData("option", "history");

    this.rankings = this.scene.make.text({
      x: 150,
      y: midY - 40,
      text: "Rankings",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "28px",
        stroke: "#ffffff",
        strokeThickness: 4,
        fill: "#7d5d2a",
        wordWrap: {
          width: 250,
        },
        fixedWidth: 250,
        align: "center",
      },
      add: true,
      origin: {
        x: 0.5,
        y: 0.5,
      },
    });
    this.rankings.setInteractive();
    this.rankings.setData("option", "rankings");

    this.backBtn = this.scene.make.text({
      x: 150,
      y: this.scene.cameras.main.height - 60,
      text: "Back",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "32px",
        stroke: "#ffffff",
        strokeThickness: 4,
        fill: "#7d5d2a",
        wordWrap: {
          width: 250,
        },
        fixedWidth: 250,
        align: "center",
      },
      add: true,
      origin: {
        x: 0.5,
        y: 0.5,
      },
    });
    this.backBtn.setInteractive();
    this.backBtn.setData("option", "back");

    this.scene.input.on("gameobjectover", (pointer, gameObject, event) => {
      if (gameObject.getData("option") && this.active !== gameObject) {
        this.scene.game.events.emit("play-fx", SOUND_FX.HOVER);
        this.hover(gameObject);
      }
    });

    this.scene.input.on("gameobjectout", (pointer, gameObject, event) => {
      if (gameObject.getData("option") && this.active !== gameObject) {
        this.out(gameObject);
      }
    });

    this.scene.input.on("gameobjectdown", (pointer, gameObject, event) => {
      if (gameObject.getData("option") && this.active !== gameObject) {
        this.scene.game.events.emit("play-fx", SOUND_FX.CLICK);
        this.select(gameObject);
      }
    });
  }

  select(option) {
    console.log(option);
    if (!option) {
      this.select(this.myAxies);
      return;
    }

    const outlinePlugin = this.scene.plugins.get("rexOutlinePipeline");

    if (this.active) {
      outlinePlugin.remove(this.active);
      this.active.setScale(1);
    }

    this.active = option;

    outlinePlugin.add(option, {
      thickness: 7,
      outlineColor: 0x795940,
      quality: 0.25,
    });

    option.setScale(1.15);

    this.scene.events.emit("show-option", option.getData("option"));

    this.out(option);
  }

  hover(option) {
    const glowPlugin = this.scene.plugins.get("rexGlowFilterPipeline");
    const glowPipeline = glowPlugin.add(option);

    option.glowTask = option.scene.tweens.add({
      targets: glowPipeline,
      intensity: 0.0025,
      ease: "Sine.easeIn",
      duration: 500,
      repeat: -1,
      yoyo: true,
    });
  }

  out(option) {
    if (!option.glowTask) {
      return;
    }

    const glowPlugin = this.scene.plugins.get("rexGlowFilterPipeline");
    glowPlugin.remove(option);
    option.glowTask.stop();
    option.glowTask = null;
  }
}
