import { createSkillCard } from "../../utils";

const tags = {
  aquatic: "blue-skill-mana",
  dawn: "dawn-tag",
  dusk: "dusk-tag",
  mech: "mech-tag",
  bird: "pink-skill-mana",
  reptile: "purple-skill-mana",
  bug: "red-skill-mana",
  beast: "yellow-skill-mana",
  plant: "green-skill-mana",
};

export class MatchAxie {
  constructor(scene, container, x, y, player, pos) {
    this.scene = scene;
    const axie = player.stats.axie;

    const img = this.scene.add.image(x, y, "body-placeholder");
    // img.setSize(1280, 960);
    // img.setScale(0.132);
    container.add(img);

    if (!axie) {
      console.log("no axie info", player.id);
      return;
    }
    const textureName = `png-${axie.id}`;

    this.checkLoad = (key) => {
      if (key !== textureName) {
        return;
      }
      this.scene.load.off("filecomplete", this.checkLoad);

      img.setTexture(textureName);
      img.setScale(0.1);
    };

    if (this.scene.textures.exists(textureName)) {
      img.setTexture(textureName);
      img.setScale(0.1);
    } else {
      this.scene.load.image(
        textureName,
        `https://assets.axieinfinity.com/axies/${axie.id}/axie/axie-full-transparent.png`
      );

      this.scene.load.on("filecomplete", this.checkLoad);
      this.scene.load.start();
    }

    container.add(
      this.scene.make.rexBBCodeText({
        x: x + 40,
        y: y - 31,
        text: `[img=${tags[axie.class]}] [stroke]${
          player.stats.name ? player.stats.name : axie.name
        }[/stroke]`,
        style: {
          fontFamily: "Secular One",
          fontSize: "12px",
          stroke: "#7d5d2a",
          strokeThickness: 3,
          fill: "#ffffff",
        },
        add: true,
        origin: {
          x: 0,
          y: 0.5,
        },
      })
    );

    ["eyes", "ears", "back", "mouth", "horn", "tail"].forEach((part, i) => {
      const axiePart = axie[part];
      createSkillCard(container, {
        x: x + i * 48 + 44,
        y: y - 2,
        width: 42,
        height: 42,
        key: axiePart.cardId,
        textureKey: "match-" + axiePart.cardId,
        add: true,
        partType: `${axie.id}-${pos}-${part}`,
      });
    });

    ["blue", "red", "yellow", "purple", "green", "pink"].forEach((color, i) => {
      const orb = this.scene.add.sprite(
        x + 52 + i * 40,
        y - 14,
        "orbs",
        `${color}Orb`
      );
      orb.setScale(0.42125);
      container.add(orb);

      container.add(
        this.scene.make.text({
          x: x + 62 + i * 40,
          y: y - 14,
          text: player.stats.totalMana[color],
          style: {
            fontFamily: "Secular One",
            fontSize: "12px",
            stroke: "#7d5d2a",
            strokeThickness: 3,
            fill: "#ffffff",
          },
          add: true,
          origin: {
            x: 0,
            y: 0.5,
          },
        })
      );
    });

    const wildcard = this.scene.add.sprite(x + 52 + 6 * 40, y - 14, "orbs");
    wildcard.setScale(0.42125);
    wildcard.play("wildcard");
    container.add(wildcard);

    container.add(
      this.scene.make.text({
        x: x + 62 + 6 * 40,
        y: y - 14,
        text: player.stats.totalMana.wildcard,
        style: {
          fontFamily: "Secular One",
          fontSize: "12px",
          stroke: "#7d5d2a",
          strokeThickness: 3,
          fill: "#ffffff",
        },
        add: true,
        origin: {
          x: 0,
          y: 0.5,
        },
      })
    );
  }
}
