import { getRonNonce, validateRonSignature, connectRonin } from "./api";
import Web3 from "web3";

export function DAOConnect() {
  return new Promise(async (resolve, reject) => {
    const nonce = await getRonNonce();

    const RoninSignatureWindow = window.open(
      `https://ronin.axiedao.org/sso/?ref=${window.parent.location.href}&message=Connect your wallet to Axie Blitz: ${nonce}&autoclose=true`
    );
    window.addEventListener("message", async function receiveSig(event) {
      if (
        typeof event.data === "object" &&
        "key" in event.data &&
        event.data.key === "signature" &&
        event.origin === "https://ronin.axiedao.org"
      ) {
        window.removeEventListener("message", receiveSig, false); // Remove listener
        const signature = event.data.message; // Actual signature payload

        RoninSignatureWindow.close();

        try {
          const response = await connectRonin(signature, nonce);

          console.log("connect token", response.token);
          window.localStorage.setItem("token", response.token);

          resolve();
        } catch (e) {
          reject(e);
        }
      }
    });
  });
}
export function DAOlogin() {
  return new Promise(async (resolve, reject) => {
    const nonce = await getRonNonce();

    const RoninSignatureWindow = window.open(
      `https://ronin.axiedao.org/sso/?ref=${window.parent.location.href}&message=Axie Blitz login: ${nonce}&autoclose=true`
    );
    window.addEventListener("message", async function receiveSig(event) {
      if (
        typeof event.data === "object" &&
        "key" in event.data &&
        event.data.key === "signature" &&
        event.origin === "https://ronin.axiedao.org"
      ) {
        window.removeEventListener("message", receiveSig, false); // Remove listener
        const signature = event.data.message; // Actual signature payload

        RoninSignatureWindow.close();

        const response = await validateRonSignature(signature, nonce);

        if (!response.error) {
          window.localStorage.setItem("token", response.token);

          resolve();
        } else {
          reject(response.error);
        }
      }
    });
  });
}

const getNetworkId = async (web3) => {
  const currentChainId = await web3.eth.net.getId();
  return currentChainId;
};

const addRoninChain = async (web3) => {
  try {
    await web3.currentProvider.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x7E4",
          chainName: "Ronin Network",
          nativeCurrency: {
            name: "RON",
            symbol: "RON",
            decimals: 18,
          },
          blockExplorerUrls: ["https://explorer.roninchain.com/"],
          rpcUrls: ["https://api.roninchain.com/rpc"],
        },
      ],
    });
  } catch (e) {
    console.log("error adding ronin chain", e);
  }
};

export function MetamaskLogin() {
  return new Promise(async (resolve, reject) => {
    const { ethereum } = window;

    const web3 = new Web3(window.ethereum);

    const acc = await ethereum.request({
      method: "eth_requestAccounts",
    });

    const currentChainId = await getNetworkId(web3);
    if (currentChainId !== 2020) {
      try {
        await web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: Web3.utils.toHex("2020"),
            },
          ],
        });

        console.log("ok");
        console.log("add slp");
        // const wasAdded = await web3.currentProvider.request({
        //   method: "wallet_watchAsset",
        //   params: {
        //     type: "ERC20", // Initially only supports ERC20, but eventually more!
        //     options: {
        //       address: "0xa8754b9fa15fc18bb59458815510e40a12cd2014", // The address that the token is at.
        //       symbol: "SLP", // A ticker symbol or shorthand, up to 5 chars.
        //       decimals: 0, // The number of decimals in the token
        //       image:
        //         "https://assets.axieinfinity.com/explorer/images/contract-icon/slp.png", // A string url of the token logo
        //     },
        //   },
        // });

        // console.log("added", wasAdded);
      } catch (switchError) {
        if (switchError.code === 4902) {
          await addRoninChain(web3);
        }
        console.log(switchError);
      }
    } else {
      const contract = new web3.eth.Contract(
        [
          {
            constant: !1,
            inputs: [
              {
                name: "_to",
                type: "address",
              },
              {
                name: "_value",
                type: "uint256",
              },
            ],
            name: "transfer",
            outputs: [
              {
                name: "",
                type: "bool",
              },
            ],
            payable: !1,
            stateMutability: "nonpayable",
            type: "function",
          },
        ],
        "0xa8754b9fa15fc18bb59458815510e40a12cd2014"
      );

      console.log(contract);

      const block = await web3.eth.getBlock("latest");
      console.log(block);
      const nonce = await web3.eth.getTransactionCount(
        "0x0859ad47A265BD85f910d8e26d6DF5339C6aE1Fb",
        "latest"
      );
      console.log(nonce);

      try {
        const transfer = await contract.methods.transfer(
          "0x37968e2a4c4b977090ad98d6fd46ff2b08a62285",
          500000
        );
        console.log(transfer.encodeABI());
        const transferGas = await transfer.estimateGas({
          from: "0x0859ad47A265BD85f910d8e26d6DF5339C6aE1Fb",
          gas: block.gasLimit,
        });

        const resp = await web3.currentProvider.request({
          method: "eth_sendTransaction",
          params: [
            {
              to: "0xa8754b9fa15fc18bb59458815510e40a12cd2014",
              gas: web3.utils.toHex(transferGas),
              gasPrice: web3.utils.toWei("1", "gwei"),
              data: transfer.encodeABI(),
              from: "0x0859ad47A265BD85f910d8e26d6DF5339C6aE1Fb",
            },
          ],
        });
        console.log(resp);

        // gasPrice: this.web3.utils.toWei("1", "gwei"),
      } catch (e) {
        console.log("error  here");
        reject(e);
        return;
      }
    }
  });
}
