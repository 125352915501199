import styles from "./AxieList.module.scss";

const AxieList = ({
  axies,
  selection,
  className,
  itemsPerRow = 4,
  onClick = () => {},
  showFavorites,
  favoriteId,
  disableFavorite,
  noStroke,
  onFavoriteClick = () => {},
}) => {
  return (
    <div className={[styles.list, className].join(" ").trim()}>
      {axies && axies.length && (
        <>
          {axies.map((axieData, i) => {
            const { axie } = axieData;

            return (
              <div
                className={styles.axie}
                key={i}
                onClick={() => {
                  onClick(axie.axie_id);
                }}
                style={{ width: `${100 / itemsPerRow}%` }}
              >
                <div
                  className={[
                    styles.content,
                    selection === axie.axie_id ? styles.selected : "",
                  ]
                    .join(" ")
                    .trim()}
                >
                  <div
                    className={`${styles.name} ${
                      noStroke ? styles.noStroke : ""
                    }`}
                    title={axie.name}
                  >
                    <img src={`images/${axie.class}.png`} />
                    {axie.name}
                  </div>
                  <img
                    src={`https://axiecdn.axieinfinity.com/axies/${axie.axie_id}/axie/axie-full-transparent.png`}
                    alt={`${axie.name} image`}
                  />
                  {showFavorites && (
                    <div
                      className={[
                        styles.favorite,
                        favoriteId === axie.axie_id ? styles.selected : "",
                        disableFavorite ? styles.disabled : "",
                      ]
                        .join(" ")
                        .trim()}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onFavoriteClick(axie.axie_id);
                      }}
                    ></div>
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default AxieList;
