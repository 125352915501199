import Button from "./Button";

import styles from "./button.module.scss";

const RoninButton = ({ text, onClick, size, ...rest }) => {
  return (
    <Button
      text={
        <>
          <img src="./images/ronin-white.png" />
          {text}
        </>
      }
      className={[styles.roninButton, styles[`size-${size}`]].join(" ").trim()}
      onClick={onClick}
      {...rest}
    ></Button>
  );
};

export default RoninButton;
