import { CSSTransition } from "react-transition-group";
import { TRANSITIONS } from "../../../../../../constants";
import Checkbox from "react-custom-checkbox";

import settingStyles from "../settings.module.scss";

const GameOptions = ({ show, onChange, values }) => {
  return (
    <CSSTransition
      in={show}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      unmountOnExit
    >
      <div className={settingStyles.optionsContainer}>
        <Checkbox
          name="game-fill"
          icon={<img src="images/ui/check.png" style={{ width: 44 }} alt="" />}
          size={28}
          borderWidth={3}
          checked={values.fill}
          label="Wait for game to fill up"
          borderColor="#503c27"
          containerClassName={settingStyles.optionLabelContainer}
          labelClassName={settingStyles.optionLabel}
          onChange={(value) => {
            onChange("game", { fill: value });
          }}
        />
        <Checkbox
          name="show-ping"
          icon={<img src="images/ui/check.png" style={{ width: 44 }} alt="" />}
          size={28}
          borderWidth={3}
          checked={values.ping}
          label="Show ping in game"
          borderColor="#503c27"
          containerClassName={settingStyles.optionLabelContainer}
          labelClassName={settingStyles.optionLabel}
          onChange={(value) => {
            onChange("game", { ping: value });
          }}
        />
      </div>
    </CSSTransition>
  );
};

export default GameOptions;
