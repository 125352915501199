import styles from "./ui.module.scss";
import useWrapperSize from "../../../helpers/useWrapperSize";
import { VIEWPORT } from "../gameSrc/constants";
import { useEffect, useState } from "react";

import Login from "./screens/login";
import MainMenu from "./screens/mainMenu";
import Profile from "./screens/settings/Profile";
import Settings from "./screens/settings/Settings";
import GameSelection from "./screens/gameselection/GameSelection";
import Lobby from "./screens/gamelobby/Lobby";
import GameUI from "./screens/gameUI/GameUI";
import { getStarterAxies, syncUserAxies } from "../gameSrc/helpers/api";

const UI = ({
  view,
  changeView,
  changeLogin,
  playSound,
  game,
  gameState,
  energy,
  onJoinMatch,
  info,
}) => {
  const [width] = useWrapperSize();
  const [scale, setScale] = useState(1);
  const [needResize, checkSize] = useState(false);
  const [firstResize, setFirst] = useState(false);

  useEffect(() => {
    const canvas = document.querySelector(".game-wrapper > canvas");

    if (canvas || needResize) {
      setTimeout(() => {
        setScale(canvas.offsetWidth / VIEWPORT.width);
        checkSize(false);

        if (!firstResize) {
          setFirst(true);
        }
      }, 500);
    }
  }, [width, needResize]);

  return (
    <div
      className={[styles.ui]}
      style={{
        width: VIEWPORT.width,
        height: VIEWPORT.height,
        transform: `translate(-50%, -50%) scale(${scale})`,
        pointerEvents: view === "match" ? "none" : null,
      }}
    >
      {firstResize ? (
        <>
          <Login
            show={view === "login"}
            onLogin={async () => {
              // automatically sync axies
              const { axies, favorite } = await syncUserAxies();
              const starters = await getStarterAxies();

              window.localStorage.setItem("axies", JSON.stringify(axies));
              window.localStorage.setItem("favorite", favorite);
              window.localStorage.setItem("starters", JSON.stringify(starters));
              changeLogin(true);
            }}
            playSound={playSound}
          />
          <MainMenu
            show={view === "mainMenu"}
            playSound={playSound}
            changeView={changeView}
            onLogout={() => {
              changeLogin(false);
            }}
            energy={energy}
            info={info}
          />
          <Profile
            show={view === "profile"}
            changeView={changeView}
            playSound={playSound}
          />
          <Settings
            show={view === "settings"}
            changeView={changeView}
            game={game}
            playSound={playSound}
            onSave={() => {
              checkSize(true);
            }}
          />
          <GameSelection
            show={view === "game_selection"}
            changeView={changeView}
            game={game}
            playSound={playSound}
          />
          <Lobby
            show={view === "gameLobby"}
            changeView={changeView}
            game={game}
            gameState={gameState}
            playSound={playSound}
          />
          <GameUI
            show={view === "match"}
            changeView={changeView}
            game={game}
            gameState={gameState}
            playSound={playSound}
            onJoinMatch={onJoinMatch}
          />
        </>
      ) : null}
    </div>
  );
};

export default UI;
