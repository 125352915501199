import { CSSTransition } from "react-transition-group";
import Input from "../../components/Form/Input";
import MenuButton from "../../components/Form/MenuButton";
import { TRANSITIONS } from "../../../../../constants";
import styles from "./login.module.scss";

const ForgotPassword = ({ show, onClose }) => {
  return (
    <CSSTransition
      in={show}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      unmountOnExit
    >
      <div className={styles.modal}>
        <button className={styles.close} onClick={onClose}>
          <svg
            style={{ fill: "currentcolor" }}
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.293 6.293a1 1 0 0 1 1.414 0L12 10.586l4.293-4.293a1 1 0 1 1 1.414 1.414L13.414 12l4.293 4.293a1 1 0 0 1-1.414 1.414L12 13.414l-4.293 4.293a1 1 0 0 1-1.414-1.414L10.586 12 6.293 7.707a1 1 0 0 1 0-1.414Z"
              fill="currentColor"
            ></path>
          </svg>
        </button>
        <div className={styles.modalContent}>
          <p style={{ fontSize: 24, fontWeight: 600, paddingTop: 20 }}>
            Forgot your password?
          </p>
          <p style={{ marginBottom: 40 }}>
            Enter your email address. We will send you a link to reset your
            password.
          </p>
          <Input
            name="forgot-email"
            label="Email"
            required
            style={{ marginBottom: 20 }}
          />
          <MenuButton
            text={"Email me"}
            onClick={() => {
              console.log("c");
            }}
          />
        </div>
      </div>
    </CSSTransition>
  );
};

export default ForgotPassword;
