import { CSSTransition } from "react-transition-group";
import { TRANSITIONS } from "../../../../constants";

import styles from "./ModalSpinner.module.scss";

const ModalSpinner = ({ show, ...rest }) => {
  return (
    <CSSTransition
      in={show}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      unmountOnExit
    >
      <div className={styles.modal} {...rest}>
        <img src="images/spinner.png" alt="spinner" />
      </div>
    </CSSTransition>
  );
};

export default ModalSpinner;
