import { CSSTransition } from "react-transition-group";
import Box from "../../components/Box";
import MenuButton from "../../components/Form/MenuButton";
import { SOUND_FX } from "../../../gameSrc/sound/Sound";
import Input from "../../components/Form/Input";
import { validateEmail } from "../../utils";
import { TRANSITIONS } from "../../../../../constants";
import { signIn } from "../../../gameSrc/helpers/api";
import ModalSpinner from "../../components/ModalLoader";

import styles from "../../ui.module.scss";
import settingsStyles from "./settings.module.scss";
import { useState, useReducer, useEffect } from "react";
import TextButton from "../../components/Form/TextButton";
import MyAxies from "./profile/MyAxies";

const initState = {
  optionSelected: "",
};

const reducer = (state, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "change_option":
      newState.optionSelected = action.value;
      break;

    default:
      break;
  }

  return newState;
};

const Profile = ({ show, changeView, playSound }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  // const [modal, showModal] = useState(false);
  // const [register, showRegister] = useState(false);
  // const [validForm, setValidForm] = useState(false);
  // const [sending, submitForm] = useState(false);
  // const [errorMsg, setError] = useState("");
  const [displayed, showSettings] = useState(false);

  useEffect(() => {
    if (show) {
      showSettings(true);
    } else {
      showSettings(false);
    }
  }, [show]);

  useEffect(() => {
    if (displayed) {
      // showSettings(true);
      dispatch({ type: "change_option", value: "myaxies" });
    } else {
      // showSettings(false);
      dispatch({ type: "change_option", value: "" });
    }
  }, [displayed]);
  // useEffect(() => {
  //   if (validateEmail(state.email)) {
  //     setValidForm(true);
  //   } else {
  //     setValidForm(false);
  //   }
  // }, [state]);

  // const handleSignIn = async () => {
  //   setError("");
  //   submitForm(true);
  //   try {
  //     const response = await signIn(state.email, state.password);

  //     window.localStorage.setItem("token", response.token);
  //     onLogin();
  //   } catch (e) {
  //     setError(e.message);
  //   }
  //   submitForm(false);
  // };

  return (
    <CSSTransition
      in={show}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      unmountOnExit
    >
      <div className={styles.screen} style={{ background: "rgba(0,0,0,0.75)" }}>
        <CSSTransition
          in={displayed}
          timeout={TRANSITIONS.fade}
          classNames="tr-fade-left"
          unmountOnExit
        >
          <Box className={[settingsStyles.leftBar, styles.dark].join(" ")}>
            <TextButton
              text={"My Axies"}
              size="large"
              copyClassName={[
                settingsStyles.option,
                state.optionSelected === "myaxies"
                  ? settingsStyles.selected
                  : "",
              ]
                .join(" ")
                .trim(0)}
              onClick={() => {
                playSound(SOUND_FX.CLICK, { volume: 0.25 });
                dispatch({ type: "change_option", value: "myaxies" });
              }}
              onMouseEnter={() => {
                playSound(SOUND_FX.HOVER, { volume: 0.25 });
              }}
            ></TextButton>
            <MenuButton
              text={"back"}
              // disabled={!validForm}
              throttleMs={1000}
              onClick={() => {
                playSound(SOUND_FX.CLICK, { volume: 0.25 });
                changeView("mainMenu");
              }}
              onMouseEnter={() => {
                playSound(SOUND_FX.HOVER, { volume: 0.25 });
              }}
            />
          </Box>
        </CSSTransition>
        <CSSTransition
          in={displayed}
          timeout={TRANSITIONS.fade}
          classNames="tr-fade-right"
          unmountOnExit
        >
          <Box
            className={settingsStyles.mainBox}
            style={{ padding: "80px 40px" }}
          >
            <MyAxies
              show={state.optionSelected === "myaxies"}
              playSound={playSound}
            />
          </Box>
        </CSSTransition>
        {/* <ModalSpinner show={sending} style={{ borderRadius: 24 }} /> */}
      </div>
    </CSSTransition>
  );
};

export default Profile;
