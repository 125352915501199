import { CSSTransition } from "react-transition-group";
import Box from "../../components/Box";
// import AxieSelector from "./AxieSeletor";
import { TRANSITIONS } from "../../../../../constants";
import styles from "../../ui.module.scss";
import lobbyStyles from "./Lobby.module.scss";
import { useState, useReducer, useEffect } from "react";
import { SOUND_FX } from "../../../gameSrc/sound/Sound";
// import SelectionBox from "./SelectionBox";
import { getRandomInt } from "../../../gameSrc/utils";

const initState = {
  address: "",
  name: "",
};

const reducer = (state, action) => {
  let newState = { ...state };

  switch (action.type) {
    case "setProfile":
      newState = { ...action.data };
      break;

    case "password":
      newState.password = action.value;
      break;

    default:
      break;
  }

  return newState;
};

const Lobby = ({ show, playSound, changeView, game, gameState }) => {
  const [selection, selectAxie] = useState();
  const [starterAxies, setStarterAxies] = useState();
  const [playerAxies, setAxies] = useState();
  const [showSelectionBox, toggleSelectionBox] = useState(false);
  const [display, setDisplay] = useState(false);
  const [joining, setJoin] = useState(false);
  const [randomSelection, selectRandom] = useState(null);

  useEffect(() => {
    if (!show) {
      setDisplay(false);
      return;
    }

    setDisplay(true);
  }, [show]);

  return (
    <CSSTransition
      in={show}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      onEnter={() => {
        // setDisplay(true);
      }}
      onExit={() => {
        // setDisplay(false);
        // selectAxie(null);
      }}
      unmountOnExit
    >
      <div className={styles.screen} style={{ background: "rgba(0,0,0,0.8)" }}>
        <button
          className={lobbyStyles.back}
          onClick={() => {
            game.current.leaveLobby();
            playSound(SOUND_FX.CLICK, { volume: 0.25 });
            changeView("mainMenu");
          }}
          onMouseEnter={() => {
            playSound(SOUND_FX.HOVER, { volume: 0.25 });
          }}
          // disabled={joining}
        >
          <img src="images/ui/arrow.png" alt="logout" />
        </button>
        <div className={`${display ? lobbyStyles.showContent : ""}`}>
          <span className={lobbyStyles.loader}></span>
          <div className={lobbyStyles.players}>
            <div className={styles.blitz} style={{ fontSize: 48 }}>
              looking for players
            </div>
            <div className={`${styles.blitz} ${lobbyStyles.count}`}>
              {gameState.playerCount} / 17
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Lobby;
