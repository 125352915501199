import { AxieSpine } from "./AxieSpine";
import { timer } from "./utils";

export class Axie {
  constructor(config) {
    this.scene = config.scene;

    // // Draw the circle using Phaser 3
    this.spine = new AxieSpine();
  }

  tick() {
    this.canvas.texture.refresh();
  }

  setPositionX(x) {
    this.canvas.x = x;
  }

  setPositionY(y) {
    this.canvas.y = y;
  }

  setPosition(x, y) {
    this.setPositionX(x);
    this.setPositionY(y);
  }

  setOrigin(x, y) {
    this.canvas.setOrigin(x, y);
  }

  async reactTo(type, delay) {
    if (delay) {
      await timer(delay);
    }
    // console.log("reactTo", type);
    switch (type) {
      case "wall":
        this.spine.reaction("battle/get-debuff", true);
        break;

      case "start-game":
        this.spine.reaction("action/appear", true);
        break;

      case "axie-selection":
        break;

      case "attack-addWall":
        this.spine.reaction("attack/melee/back-slam", true);
        break;

      case "hit-addWall":
      case "hit-manaAquatic":
      case "hit-manaBug":
        this.spine.reaction("defense/hit-by-normal-attack", true);
        break;

      case "evade":
        this.spine.reaction("defense/evade", true);
        break;

      case "attack-manaAquatic":
      case "attack-manaReptile":
      case "attack-manaBug":
        this.spine.reaction("attack/melee/mouth-bite", true);
        break;

      case "hit-manaBird":
        this.spine.reaction("battle/get-buff", true);
        break;

      default:
        break;
    }
  }

  async load(id) {
    return new Promise(async (resolve) => {
      let textureKey = `axie-${id}`;

      if (this.scene.textures.exists(textureKey)) {
        textureKey += new Date().getTime();
      }

      this.scene.textures.addCanvas(textureKey, this.spine.app.view);
      this.canvas = this.scene.add.image(0, 0, textureKey);
      this.canvas.setSize(150, 150);
      this.canvas.setDisplaySize(150, 150);
      this.canvas.setOrigin(0, 0);

      await this.spine.load(id);

      resolve();
    });
  }

  isLoaded() {
    return this.spine.loaded;
  }

  render(id) {
    this.obj = this.scene.add.spine(0, 0, `axie-${id}`);
    this.obj.setScale(0.2);

    this.obj.depth = 2;
    this.obj.width = 100;
    this.obj.height = 100;
  }
}
