import Phaser from "phaser";
import { Axie } from "./Axie";
import { tiles } from "./Board";
import { RectangularProgress } from "./components/RectangularProgress";
import { CircleProgress } from "./components/circleProgress/CircleProgress";
import { BOARD, classToManaProp, manaAbilities } from "./constants";
import { SOUND_FX } from "./sound/Sound";
import { createSkillCard } from "./utils";

export class StatsBoard extends Phaser.GameObjects.Container {
  constructor(scene, x, y, playerState, reduced) {
    super(scene, x, y);

    scene.add.existing(this);

    this.playerState = playerState;
    this.playerAxie = playerState.axie;
    this.reduced = reduced;

    if (!reduced) {
      this.createSkillsContainer();
      this.scene.anims.create({
        key: "stun",
        frames: this.scene.anims.generateFrameNames("stun"),
        repeat: -1,
        duration: 1500,
      });
      // map keys to part ability
      this.partKeys = {};
      const settingsParts = this.scene.game.settings.game.keys.skills;
      Object.keys(settingsParts).forEach((part) => {
        this.partKeys[settingsParts[part]] = part;
      });

      this.scene.input.keyboard.on("keydown", (event) => {
        const key = event.code;

        if (key && key === "Escape") {
          this.scene.events.emit("cancel-ability");
          return;
        }

        if (
          key &&
          this.partKeys[key] &&
          this.skills[this.partKeys[key]] &&
          this.skills[this.partKeys[key]].box.ready
        ) {
          this.scene.events.emit("press-ability", this.partKeys[key]);
        }
      });

      this.playerAxie.eyes.onChange = (changes) => {
        changes.forEach((change) => {
          if (change.field === "ready") {
            this.checkAbility("eyes");
          }
        });
      };
      this.playerAxie.ears.onChange = (changes) => {
        changes.forEach((change) => {
          if (change.field === "ready") {
            this.checkAbility("ears");
          }
        });
      };
      this.playerAxie.mouth.onChange = (changes) => {
        changes.forEach((change) => {
          if (change.field === "ready") {
            this.checkAbility("mouth");
          }
        });
      };
      this.playerAxie.back.onChange = (changes) => {
        changes.forEach((change) => {
          if (change.field === "ready") {
            this.checkAbility("back");
          }
        });
      };
      this.playerAxie.horn.onChange = (changes) => {
        changes.forEach((change) => {
          if (change.field === "ready") {
            this.checkAbility("horn");
          }
        });
      };
      this.playerAxie.tail.onChange = (changes) => {
        changes.forEach((change) => {
          if (change.field === "ready") {
            this.checkAbility("tail");
          }
        });
      };

      this.wallProgress = new RectangularProgress(this.scene, 120, 210, {
        width: 350,
        height: 28,
        container: this,
        icon: "wall",
        barColor: 0x583c27,
        iconMargin: 7,
        iconScale: 0.6,
      });

      this.lifeProgress = new RectangularProgress(this.scene, 120, 248, {
        width: 350,
        height: 28,
        container: this,
        icon: "heart",
        barColor: 0xb32b34,
        iconMargin: 7,
        fadeOut: true,
      });

      this.manaBoxes = {
        blue: this.createManaBox(0, 0, "blue"),
        red: this.createManaBox(88, 0, "red"),
        yellow: this.createManaBox(176, 0, "yellow"),
        purple: this.createManaBox(264, 0, "purple"),
        green: this.createManaBox(352, 0, "green"),
        pink: this.createManaBox(440, 0, "pink"),
      };

      this.manaBoxTooltips = {
        blue: this.createManaBoxTooltip(y, "aquatic", "blue"),
        red: this.createManaBoxTooltip(y, "bug", "red"),
        yellow: this.createManaBoxTooltip(y, "beast", "yellow"),
        purple: this.createManaBoxTooltip(y, "reptile", "purple"),
        green: this.createManaBoxTooltip(y, "plant", "green"),
        pink: this.createManaBoxTooltip(y, "bird", "pink"),
      };

      Object.keys(this.manaBoxes).forEach((k) => {
        this.add(this.manaBoxes[k].background);
        this.add(this.manaBoxes[k].text);
        this.add(this.manaBoxes[k].icon);
      });

      this.checkAllAbilities();
    }

    this.hearts = [];

    // this.wallProgress = new CircleProgress(this.scene, 180, 238, {
    //   radius: 36,
    //   container: this,
    //   icon: "wall",
    //   barColor: 0x583c27,
    //   iconScale: 0.8,
    // });

    // this.lifeProgress = new CircleProgress(this.scene, 120, , {
    //   radius: 36,
    //   container: this,
    //   icon: "heart",
    //   barColor: 0xb32b34,
    //   fadeOut: true,
    // });
  }

  checkAbility(part) {
    const box = this.skills[part].box;
    const axiePart = this.playerAxie[part];

    // console.log(
    //   "part: " + part,
    //   axiePart.ready ? "ready" : "not ready",
    //   classToManaProp[axiePart.partClass] +
    //     ": " +
    //     this.playerState[classToManaProp[axiePart.partClass]],
    //   "cost: " + axiePart.manaCost,
    //   box.ready ? "box ready" : "box not ready"
    // );
    if (
      axiePart.ready &&
      this.playerState[classToManaProp[axiePart.partClass]] >=
        axiePart.manaCost &&
      !box.ready
    ) {
      this.toggleSkillBox(part, true);
    } else if (!axiePart.ready && box.ready) {
      this.toggleSkillBox(part, false);
    } else if (
      axiePart.ready &&
      box.ready &&
      this.playerState[classToManaProp[axiePart.partClass]] < axiePart.manaCost
    ) {
      this.toggleSkillBox(part, false);
    }
  }

  checkAllAbilities() {
    ["eyes", "ears", "back", "mouth", "horn", "tail"].forEach((part) => {
      this.checkAbility(part);
    });
  }

  startWallCounter() {
    this.wallProgress.start(this.playerAxie.stats.modWall);
  }

  resetWallProgress() {
    this.wallProgress.start(this.playerAxie.stats.modWall, true);
  }

  startLifeCounter() {
    this.lifeProgress.start(BOARD.MOVE_TIMEOUT);
  }

  resetLifeProgress() {
    this.lifeProgress.start(BOARD.MOVE_TIMEOUT, true);
  }

  addHeart(i) {
    let heart;

    if (this.hearts[i]) {
      heart = this.hearts[i];
    } else {
      heart = this.scene.add.image(
        this.reduced ? 0 + 18 * i : 130 + 39 * i,
        this.reduced ? 0 : 174,
        "heart"
      );
      if (this.reduced) {
        heart.setScale(0.45);
      }
      this.add(heart);
      this.hearts[i] = heart;
    }

    heart.alpha = 0;
    this.scene.tweens.add({ targets: heart, alpha: 1, duration: 250 });
  }

  removeHeart(i) {
    const heart = this.hearts[i];

    this.scene.tweens.add({ targets: heart, alpha: 0, duration: 250 });
  }

  createManaBox(x, y, iconKey) {
    const box = {};

    box.background = this.scene.add.image(x, y, "mana-box");
    box.background.setDisplaySize(80, 34);
    box.background.setOrigin(0, 0);
    box.icon = this.scene.add.image(x + 4, y + 4, iconKey + "-crest");
    box.icon.setDisplaySize(26, 26);
    box.icon.setOrigin(0, 0);
    box.text = this.scene.add.text(x + 44, y + 6, "0");
    box.text.setFontFamily("Luckiest Guy");
    box.icon.setInteractive();
    box.icon.setData("mana", iconKey);

    return box;
  }

  createManaBoxTooltip(y, axieClass, color) {
    const midX = this.scene.cameras.main.width / 2;
    const midY = this.scene.cameras.main.height / 2;

    const container = this.scene.add.container(midX, y - 134);
    this.skillDetailsBG = this.scene.add.image(0, 0, "skill-detail");
    this.skillDetailsBG.depth = 30;
    container.add(this.skillDetailsBG);
    this.skillDetailsBG.alpha = 0.95;
    container.depth = 30;
    const containerOrigin = { x: -338, y: -131 };
    const skillImg = createSkillCard(container, {
      x: containerOrigin.x + 20,
      y: containerOrigin.y + 28,
      width: 216,
      height: 216,
      key: manaAbilities[axieClass].img,
      textureKey: `mana-skill-${manaAbilities[axieClass].img}`,
      fullImg: true,
      imgHeight: 256,
      imgWidth: 256,
      add: true,
    });

    container.add(
      this.scene.add.rexBBCodeText({
        x: 174,
        y: containerOrigin.y + 26,
        style: {
          fontFamily: "Secular One",
          fontSize: "18px",
          color: "#7d5d2a",
          stroke: 0xfafafa,
          strokeThickness: 2,
        },
        origin: {
          x: 0,
          y: 0,
        },
        text: `[stroke][b]Mana cost: 50 [y=2][img=${color}-skill-mana][/y][/b][/stroke]`,
      })
    );

    container.add(
      this.scene.add.rexBBCodeText({
        x: containerOrigin.x + 244,
        y: containerOrigin.y + 64,
        style: {
          fontFamily: "Secular One",
          fontSize: "18px",
          color: "#7d5d2a",
          stroke: 0xfafafa,
          strokeThickness: 2,
          fixedWidth: 404,
          wrap: {
            mode: "word",
          },
        },
        origin: {
          x: 0,
          y: 0,
        },
        text: `[stroke][size=22][b]${manaAbilities[axieClass].name}[/b][/size]

${manaAbilities[axieClass].text}`,
      })
    );

    container.alpha = 0;

    return container;
  }

  createSkillsContainer() {
    const container = this.scene.add.image(0, 40, "skills-container");
    container.setOrigin(0, 0);
    // container.setDisplaySize(510, 120);
    container.setDisplaySize(520, 112);
    this.add(container);

    const axieCircle = this.scene.add.image(0, 165, "skills-circle");
    axieCircle.alpha = 0.8;
    axieCircle.setOrigin(0, 0);
    axieCircle.setDisplaySize(110, 110);
    // axieCircle.visible = false;
    const skillWidth = 77.5;
    const skillHeight = 78;
    const skillSpacing = 5;

    this.skills = {};

    let x = 15;
    // let spacing = 4;

    ["eyes", "ears", "back", "mouth", "horn", "tail"].forEach((part, i) => {
      const key = this.scene.add.image(
        x + skillWidth + skillWidth * i + skillSpacing * i - 28,
        53 + skillHeight - 19,
        "skill-key"
      );

      const keyText = this.scene.make.text({
        x: x + skillWidth + skillWidth * i + skillSpacing * i - 13,
        y: 53 + skillHeight - 5,
        text: this.scene.game.settings.game.keys.skills[part].replace(
          "Key",
          ""
        ),
        style: {
          fontFamily: "Luckiest Guy",
          fontSize: "16px",
          stroke: "#000000",
          strokeThickness: 2,
          fill: "#ffffff",
        },
        align: "center",
      });

      // keyText.setFontFamily("Luckiest Guy");

      this.skills[part] = {
        box: createSkillCard(this, {
          x: x + skillWidth * i + skillSpacing * i,
          y: 53,
          width: skillWidth,
          height: skillHeight + 10,
          key: this.playerAxie[part].cardId,
          textureKey: "stat-" + this.playerAxie[part].cardId,
          add: true,
          partType: part,
        }),
        // //  this.createSkill(
        // //   x + skillWidth * i + skillSpacing * i,
        // //   550,
        // //   "axie-" + part,
        // //   skillWidth,
        // //   skillHeight
        // ),
        key,
        keyText,
        tooltip: this.createSkillTooltip(part),
      };

      this.skills[part].box.ready = true;
      this.skills[part].box.setInteractive();
      this.skills[part].box.glowPipeline = this.scene.plugins
        .get("rexGlowFilterPipeline")
        .add(this.skills[part].box);
      this.skills[part].box.hlsPipeline = this.scene.plugins
        .get("rexHSLAdjustPipeline")
        .add(this.skills[part].box);
      this.skills[part].key.hlsPipeline = this.scene.plugins
        .get("rexHSLAdjustPipeline")
        .add(this.skills[part].key);
      this.skills[part].keyText.hlsPipeline = this.scene.plugins
        .get("rexHSLAdjustPipeline")
        .add(this.skills[part].keyText);

      // this.toggleSkillBox(part, this.playerAxie[part].ready);

      key.setOrigin(0, 0);
      key.setDisplaySize(30, 33);
      keyText.setOrigin(0.5);
      this.add(key);
      this.add(keyText);
      // this.scene.add.existing(keyText);
    });

    this.add(axieCircle);
  }

  createSkillTooltip(part) {
    const axiePart = this.playerAxie[part];
    const midX = this.scene.cameras.main.width / 2;
    const midY = this.scene.cameras.main.height / 2;

    const container = this.scene.add.container(midX, midY - 20);
    this.skillDetailsBG = this.scene.add.image(0, 0, "skill-detail");
    container.add(this.skillDetailsBG);
    this.skillDetailsBG.alpha = 0.95;
    container.depth = 30;
    const containerOrigin = { x: -338, y: -131 };
    const skillImg = createSkillCard(container, {
      x: containerOrigin.x + 20,
      y: containerOrigin.y + 54,
      width: 192,
      height: 192,
      key: axiePart.cardId,
      textureKey: `full-skill-${axiePart.cardId}`,
      fullImg: true,
      imgHeight: 256,
      imgWidth: 256,
      add: true,
    });

    container.add(
      this.scene.add.rexBBCodeText({
        x: containerOrigin.x + 30,
        y: containerOrigin.y + 28,
        origin: { x: 0, y: 0 },
        style: {
          fontFamily: "Secular One",
          fontSize: "16px",
          color: "#7d5d2a",
          stroke: 0xfafafa,
          strokeThickness: 1.5,
        },
        text: `[stroke]${axiePart.name}[/stroke]`,
      })
    );

    container.add(
      this.scene.add.rexBBCodeText({
        x: 186,
        y: containerOrigin.y + 28,
        style: {
          fontFamily: "Secular One",
          fontSize: "16px",
          color: "#7d5d2a",
          stroke: 0xfafafa,
          strokeThickness: 2,
        },
        origin: {
          x: 0,
          y: 0,
        },
        text: `[stroke][b]Mana cost: ${axiePart.manaCost} [y=1][img=${axiePart.manaType}-skill-mana][/y][/b][/stroke]`,
      })
    );

    container.add(
      this.scene.add.rexBBCodeText({
        x: containerOrigin.x + 222,
        y: containerOrigin.y + 70,
        style: {
          fontFamily: "Secular One",
          fontSize: "18px",
          color: "#7d5d2a",
          stroke: 0xfafafa,
          strokeThickness: 2,
          fixedWidth: 430,
          wrap: {
            mode: "word",
          },
        },
        origin: {
          x: 0,
          y: 0,
        },
        text: `[stroke][size=22][b]${axiePart.cardName}[/b][/size] - ${axiePart.description}[/stroke]`,
      })
    );

    container.add(
      this.scene.add.rexBBCodeText({
        x: 120,
        y: containerOrigin.y + 220,
        style: {
          fontFamily: "Secular One",
          fontSize: "18px",
          color: "#7d5d2a",
          stroke: 0xfafafa,
          strokeThickness: 2,
        },
        origin: {
          x: 0,
          y: 0,
        },
        text: `[stroke]cooldown: ${parseFloat(
          (axiePart.cooldown / 1000).toFixed(2)
        )} seconds[/stroke]`,
      })
    );

    container.alpha = 0;
    return container;
  }

  toggleManaTooltip(color, show = true) {
    const tooltip = this.manaBoxTooltips[color];

    if (!tooltip) {
      return;
    }

    if (tooltip.tween) {
      tooltip.tween.stop();
      tooltip.tween.remove();
    }

    tooltip.tween = this.scene.tweens.add({
      targets: [tooltip],
      alpha: show ? 1 : 0,
      delay: show ? 250 : 0,
      duration: 250,
      onStart: () => {
        tooltip.visible = true;

        if (show) {
          tooltip.alpha = 0;
        } else {
          tooltip.alpha = 1;
        }
      },
      onComplete: () => {
        if (show) {
          tooltip.visible = true;
        } else {
          tooltip.visible = false;
        }
      },
    });
  }

  togglePartTooltip(part, show = true) {
    const tooltip = this.skills[part].tooltip;

    if (!tooltip) {
      return;
    }

    if (tooltip.tween) {
      tooltip.tween.stop();
      tooltip.tween.remove();
    }

    tooltip.tween = this.scene.tweens.add({
      targets: [tooltip],
      alpha: show ? 1 : 0,
      delay: show ? 200 : 0,
      duration: 200,
      onStart: () => {
        tooltip.visible = true;

        if (show) {
          tooltip.alpha = 0;
        } else {
          tooltip.alpha = 1;
        }
      },
      onComplete: () => {
        if (show) {
          tooltip.visible = true;
        } else {
          tooltip.visible = false;
        }
      },
    });
  }

  abilityPressed(part) {
    const box = this.skills[part].box;

    if (box.pressedTween) {
      box.pressedTween.stop();
      box.pressedTween.remove();
    }

    box.pressedTween = this.scene.tweens.add({
      targets: box.glowPipeline,
      intensity: 0.015,
      duration: 500,
      repeat: -1,
      yoyo: true,
    });
  }

  abilityExec(part) {
    const box = this.skills[part].box;

    if (box.pressedTween) {
      box.pressedTween.stop();
      box.pressedTween.remove();
    }

    box.glowPipeline.intensity = 0;
  }

  toggleSkillBox(part, enabled = true) {
    this.skills[part].box.hlsPipeline.setSatAdjust(enabled ? 1 : 0.02);
    this.skills[part].key.hlsPipeline.setSatAdjust(enabled ? 1 : 0.02);
    this.skills[part].keyText.hlsPipeline.setSatAdjust(enabled ? 1 : 0.02);

    if (enabled) {
      this.skills[part].box.ready = true;
      this.skills[part].box.tween = this.scene.tweens.add({
        targets: this.skills[part].box.glowPipeline,
        intensity: 0.02,
        duration: 333,
        yoyo: true,
      });
      this.scene.game.events.emit("play-fx", SOUND_FX.SPELL_READY);
    } else {
      this.skills[part].box.ready = false;
    }
  }

  activateSkillBox(part) {
    const box = this.skills[part].box;

    if (!box || !box.ready) {
      return;
    }

    if (box.tween) {
      box.tween.stop();
      box.tween.remove();
      box.tween = null;
    }

    box.tween = this.scene.tweens.add({
      targets: this.skills[part].box.glowPipeline,
      intensity: 0.02,
      duration: 333,
      repeat: -1,
      yoyo: true,
    });
  }

  deactivateSkillBox(part) {
    const box = this.skills[part].box;

    if (!box) {
      return;
    }

    if (box.tween) {
      box.tween.stop();
      box.tween.remove();
      box.tween = null;
      box.glowPipeline.intensity = 0;
    }
  }
  // createSkill(x, y, type, width, height) {
  //   const canvas = document.createElement("canvas");
  //   const ctx = canvas.getContext("2d");
  //   const skillBoxImg = this.scene.textures.get("skill-box").getSourceImage();
  //   const outlinePlugin = this.scene.plugins.get("rexOutlinePipeline");

  //   canvas.width = width;
  //   canvas.height = height;

  //   ctx.drawImage(skillBoxImg, 0, 0, width, height);
  //   ctx.globalCompositeOperation = "source-in";

  //   const skillImg = this.scene.textures.get(type).getSourceImage();
  //   ctx.drawImage(
  //     skillImg,
  //     (320 - width * 2.5) / 2,
  //     (320 - height * 2.5) / 2,
  //     width * 2.5,
  //     height * 2.5,
  //     0,
  //     0,
  //     width,
  //     height
  //   );

  //   // const skillBoxMaskImg = this.scene.textures
  //   //   .get("skill-mask")
  //   //   .getSourceImage();
  //   ctx.globalCompositeOperation = "source-over";
  //   // ctx.drawImage(skillBoxMaskImg, 0, 0, width, height);

  //   this.scene.textures.addCanvas(type + "-canvas", canvas);

  //   const skillBox = this.scene.add.image(x, y, type + "-canvas");
  //   skillBox.setSize(width, height);
  //   skillBox.setOrigin(0, 0);

  //   outlinePlugin.add(skillBox, {
  //     thickness: 1.5,
  //     outlineColor: 0xa98e63,
  //     quality: 0.05,
  //   });

  //   this.add(skillBox);

  //   return skillBox;
  // }

  async loadAxie(id) {
    this.axie = new Axie({ scene: this.scene });
    await this.axie.load(id);

    this.add(this.axie.canvas);
    this.axie.setPosition(-18, 110);
  }

  update() {
    if (this.axie) {
      this.axie.tick();
    }
  }

  updateMana(type, value) {
    setTimeout(() => {
      try {
        const glowPlugin = this.scene.plugins.get("rexGlowFilterPipeline");
        const outlinePlugin = this.scene.plugins.get("rexOutlinePipeline");
        const box = this.manaBoxes[type.substring(0, type.indexOf("Mana"))];
        box.text.setText(value);

        if (value >= BOARD.MAX_MANA && !box.icon.glowTask) {
          const glowPipeline = glowPlugin.add(box.icon);
          const outlinePipeline = outlinePlugin.add(box.background, {
            thickness: 4,
            outlineColor: tiles.find(
              (tile) => tile.label === box.icon.getData("mana")
            ).color,
            quality: 0.05,
          });

          box.icon.glowTask = this.scene.tweens.add({
            targets: glowPipeline,
            intensity: 0.0225,
            ease: "Sine.easeIn",
            duration: 300,
            repeat: -1,
            yoyo: true,
          });
          box.background.outlineTask = this.scene.tweens.add({
            targets: outlinePipeline,
            thickness: 0.25,
            ease: "Sine.easeIn",
            duration: 300,
            repeat: -1,
            yoyo: true,
          });
          box.icon.setData("active", true);

          this.scene.game.events.emit("play-fx", SOUND_FX.ORB_READY);
        } else if (value < BOARD.MAX_MANA) {
          if (box.icon.glowTask) {
            glowPlugin.remove(box.icon);
            box.icon.glowTask.stop();
            box.icon.glowTask = null;

            outlinePlugin.remove(box.background);
            box.background.outlineTask.stop();
            box.background.outlineTask = null;
          }
          box.icon.setData("active", false);
        }
      } catch (e) {}
    }, 750);
  }

  showShield() {
    if (!this.shieldObj) {
      this.shieldObj = this.scene.add.image(55, 220, "shield");
      this.shieldObj.setOrigin(0.5, 0.5);
      this.shieldObj.setScale(2.5);
      this.add(this.shieldObj);
      this.shieldObj.visible = false;
    }

    if (this.removeShieldTween) {
      this.removeShieldTween.stop();
      this.removeShieldTween.remove();
      this.removeShieldTween = null;
    }

    if (!this.shieldTween) {
      this.shieldTween = this.scene.tweens.add({
        targets: this.shieldObj,
        scale: 2.6,
        alpha: 0.85,
        duration: 500,
        repeat: -1,
        yoyo: true,
        onStart: () => {
          this.shieldObj.visible = true;
          this.shieldObj.setScale(2.5);
          this.shieldObj.alpha = 1;
        },
      });
    }

    // this.toggleEffect("stun", true);
  }

  removeShield() {
    if (this.shieldTween) {
      this.shieldTween.stop();
      this.shieldTween.remove();
      this.shieldTween = null;
      this.shieldObj.visible = false;
    }

    this.removeShieldTween = this.scene.tweens.add({
      targets: this.shieldObj,
      alpha: 0,
      // delay: 750,
      duration: 200,
      onComplete: () => {
        this.shieldObj.visible = false;
      },
    });
  }

  toggleEffect(name, show = false) {
    if (show) {
      if (name === "stun") {
        if (!this.stunSprite) {
          this.stunSprite = this.scene.add.sprite(55, 175, "stun");
          this.stunSprite.play("stun");
          this.stunSprite.setScale(0.2);
          this.add(this.stunSprite);
        }
        this.stunSprite.visible = true;
      }
    } else if (this.stunSprite) {
      this.stunSprite.visible = false;
    }
  }

  reset() {
    ["pink", "yellow", "purple", "green", "blue", "red"].forEach((color) => {
      this.toggleManaTooltip(color, false);
    });

    [("eyes", "ears", "back", "mouth", "horn", "tail")].forEach((part, i) => {
      this.scene.plugins
        .get("rexGlowFilterPipeline")
        .remove(this.skills[part].box);

      this.togglePartTooltip(part, false);
    });
  }
}
