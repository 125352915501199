import Phaser from "phaser";
import { AxieSpot } from "./AxieSpot";
import { AxieSelection } from "./AxieSelection";
import { AxieDetailsModal } from "./AxieSelectionDetails";
import { GAME_STATE } from "../Game";
import { Layouts, getLayout } from "./Layouts";
import { PLAYER_STATE } from "./constants";
import { SOUND_FX } from "./sound/Sound";

export class SelectionScene extends Phaser.Scene {
  create() {
    const midX = this.cameras.main.width / 2;
    const midY = this.cameras.main.height / 2;
    // this.bg = this.add.image(midX, midY, "world-bg");

    // this.bg.setDisplaySize(this.cameras.main.width, this.cameras.main.height);
    // this.plugins.get("rexHSLAdjustPipeline").add(this.bg, { lumAdjust: 2 });
    // this.plugins.get("rexGrayScalePipeline").add(this.bg, { intensity: 1 });
    // this.bg.alpha = 0.4;

    this.make.image({
      key: "select-bg",
      x: midX,
      y: midY,
      scale: {
        x: 2,
        y: 2,
      },
      add: true,
    });

    // this.bg.alpha = 0.2;
    this.spots = {};
    this.setupScene();

    this.detailsModal = new AxieDetailsModal(
      this,
      this.cameras.main.width / 2 + 420,
      350
    );

    this.fadeMask = this.add.rectangle(
      midX,
      midY,
      this.cameras.main.width,
      this.cameras.main.height,
      0x000000
    );
    this.fadeMask.setInteractive();
    this.fadeMask.depth = 10;
    this.fadeMask.visible = false;
    this.fadeMask.alpha = 1;

    this.events.on("transitionstart", (from, duration) => {
      this.tweens.add({
        targets: this.fadeMask,
        alpha: 0,
        // delay: duration * 0.25,
        duration: duration, // * 0.75,
        onStart: () => {
          this.fadeMask.visible = true;
          this.fadeMask.alpha = 1;
        },
        onComplete: () => {
          this.fadeMask.visible = false;
        },
      });
    });

    this.events.on("transitionout", (target, duration) => {
      this.tweens.add({
        targets: this.fadeMask,
        alpha: 1,
        duration: duration,
        onStart: () => {
          this.fadeMask.visible = true;
          this.fadeMask.alpha = 0;
          // this.events.removeAllListeners();
        },
      });
    });

    this.events.on("destroy", () => {
      this.reset();
    });
  }

  assignState(state, ownId) {
    this.state = state;
    this.ownId = ownId;

    // this.spots = {};
    // this.setupScene();
  }

  setupScene() {
    const axieSpot = new AxieSpot(
      this,
      this.cameras.main.width / 2,
      this.cameras.main.height / 2
    );

    axieSpot.id = this.ownId;
    this.spots[this.ownId] = axieSpot;

    // const path = new Phaser.Curves.Ellipse(400, 300, 150);

    // const particles = this.add.particles("flares");

    // particles.createEmitter({
    //   frame: { frames: ["yellow", "green"], cycle: true },
    //   scale: { start: 0.35, end: 0.175 },
    //   blendMode: "ADD",
    //   emitZone: { type: "edge", source: path, quantity: 90, yoyo: false },
    //   lifespan: 1000,
    //   timeScale: 2,
    // });

    // particles.setScale(2);
    // this.add(particles);
    // particles.x -= width * 2;
    // particles.y -= height * 2;
    // perspective.enter();
    // perspective.angleX = 70;
    // particles.setPosition(-width * 2, -width);
    // window.t = particles;
    // window.tt = outerCircle;
    // console.log(particles);
    this.state.players.onAdd = (player, key) => {
      // console.log("c", player, key, this.state.players.size);
      this.game.events.emit("play-fx", SOUND_FX.MATCH_PLAYER_FOUND, {
        volume: 0.5,
      });
      this.spots[this.ownId].updateBar(this.state.players.size / 19);
    };

    this.checkSelectionState();

    this.state.listen("gameState", (newGameState) => {
      this.checkSelectionState();
    });
    // console.log(this.state.players);

    // update axies
    this.events.on("preupdate", () => {
      if (this.state.gameState === GAME_STATE.SELECTING) {
        Object.keys(this.spots).forEach((sessionId) => {
          // console.log(this.state.players.get(sessionId).status);

          // if (this.state.players.get(sessionId).status === PLAYER_STATE.READY) {
          if (this.spots[sessionId].axie && this.spots[sessionId].axie.canvas) {
            // console.log("this.spots[sessionId]", this.spots[sessionId]);
            this.spots[sessionId].update();
          }
        });
      }
    });

    this.events.on("client-axie-selection", (data) => {
      this.spots[this.ownId].showAxie(data, 1);
      this.focusLayout();
      this.spots[this.ownId].lockIn();

      setTimeout(() => {
        // this.spots[this.ownId].toggleHover(true);
        Object.keys(this.spots).forEach((spotKey) => {
          if (this.spots[spotKey].axie && this.spots[spotKey].axie.canvas) {
            this.spots[spotKey].toggleHover(true);
          }
        });
      }, 500);
    });

    this.selectionPanel = new AxieSelection(
      this,
      this.state.players.get(this.ownId)
    );

    this.events.on("menu-toggle", (toggle) => {
      // console.log("menu-togle");
      Object.keys(this.spots).forEach((spotKey) => {
        this.spots[spotKey].toggleHover(
          !toggle && this.spots[spotKey].axie.isLoaded()
        );
      });
    });

    this.input.on("gameobjectover", (pointer, gameObject, event) => {
      const midX = this.cameras.main.width / 2;
      if (!(gameObject instanceof AxieSpot)) {
        return;
      }

      if (gameObject.id === this.ownId) {
        this.detailsModal.x = midX + 420;
        this.game.events.emit("play-fx", SOUND_FX.SHOW_DETAILS);
        this.detailsModal.show(
          this.state.players
            .get(this.ownId)
            .ownedAxies.find(
              (axie) => axie.axieId === this.selectionPanel.lockedOption
            )
        );
      } else if (gameObject.id !== this.ownId) {
        if (gameObject.x === midX) {
          this.detailsModal.x = midX + 420;
          this.game.events.emit("play-fx", SOUND_FX.SHOW_DETAILS);
          this.detailsModal.show(this.state.players.get(gameObject.id).axie);
        } else {
          if (gameObject.x < midX && gameObject.x > midX - 190) {
            this.detailsModal.x = midX + 420;
          } else if (gameObject.x > midX && gameObject.x < midX + 190) {
            this.detailsModal.x = midX - 420;
          } else {
            this.detailsModal.x = midX;
          }
          this.game.events.emit("play-fx", SOUND_FX.SHOW_DETAILS);
          this.detailsModal.show(this.state.players.get(gameObject.id).axie);
        }
      }
    });

    this.input.on("gameobjectout", (pointer, gameObject, event) => {
      if (!(gameObject instanceof AxieSpot)) {
        return;
      }

      this.detailsModal.hide();
    });
  }

  checkSelectionState() {
    if (!this.state) {
      return;
    }

    switch (this.state.gameState) {
      case GAME_STATE.WAITING:
        this.spots[this.ownId].searching();
        this.spots[this.ownId].updateBar(this.state.players.size / 19);
        break;

      case GAME_STATE.SELECTING:
        this.game.scene.getScene("UI").hideLeave();
        this.game.events.emit("play-fx", SOUND_FX.MATCH_FULL);
        this.selectionPanel.enableButtons(1000);
        this.selectionLayout();
        // this.mySelection.found();
        break;

      default:
        break;
    }
  }

  selectionLayout() {
    let currentRow = 0;
    const layout = Layouts[getLayout(this.state.players.size)];

    this.state.playerOrder.forEach((id, i) => {
      let currentCol = 0;

      if (currentRow > 0) {
        currentCol =
          i -
          layout.selectionRows
            .slice(0, currentRow)
            .reduce((acc, val) => acc + val, 0);

        if (currentCol === layout.selectionRows[currentRow]) {
          currentRow += 1;
          currentCol = 0;
        }
      } else {
        currentCol = i;
        if (currentCol === layout.selectionRows[currentRow]) {
          currentRow += 1;
          currentCol = 0;
        }
      }

      const colWidth = Math.floor(
        (this.cameras.main.width - layout.hPadding * 2) /
          layout.selectionRows[currentRow]
      );
      const rowHeight = Math.floor(
        (this.cameras.main.height - layout.vPadding * 2) /
          layout.selectionRows.length
      );

      const scale = colWidth / layout.selectionBoxSize;
      if (id !== this.ownId) {
        const spot = new AxieSpot(
          this,
          layout.hPadding + colWidth / 2 + colWidth * currentCol,
          layout.vPadding +
            rowHeight / 2 +
            rowHeight * currentRow +
            layout.selectionYShift * scale
        );
        this.spots[id] = spot;
        spot.id = id;
        spot.currentRow = currentRow;
        spot.rowScale = scale;
        spot.colWidth = colWidth;

        spot.showWaiting();

        spot.circleContainer.scaleY = spot.ellipseScale * scale;
        spot.circleContainer.scaleX = scale;
      } else {
        this.spots[this.ownId].found(
          layout.hPadding + colWidth / 2 + colWidth * currentCol,
          layout.vPadding +
            rowHeight / 2 +
            rowHeight * currentRow +
            layout.selectionYShift * scale,
          scale,
          this.spots[this.ownId].ellipseScale * scale
        );
        this.spots[this.ownId].colWidth = colWidth;
        this.spots[this.ownId].currentRow = currentRow;
        this.spots[this.ownId].rowScale = scale;

        this.selectionPanel.playerState.triggerAll();
      }

      this.state.players.get(id).listen("status", (current, previous) => {
        if (current === PLAYER_STATE.SELECTING) {
          if (this.ownId === id) {
            this.spots[this.ownId].focus();
            this.focusLayout();
            this.selectionPanel.startSelection(
              this.state.players.get(id).lockTargetTime
            );
          } else {
            this.spots[id].focus();
          }
        } else if (
          current === PLAYER_STATE.READY &&
          !this.spots[id].axie.canvas
        ) {
          this.spots[id].showAxie(
            this.state.players.get(id).axie,
            layout.selectionAxieScale[this.spots[id].currentRow]
          );

          if (id === this.ownId) {
            this.spots[id].lockIn();
          }

          if (
            !this.selectionPanel.showMenu ||
            this.selectionPanel.lockedOption
          ) {
            // console.log("should enable", id);
            this.spots[id].toggleHover(true);
          }
        }
      });
    });
  }

  focusLayout() {
    if (this.focused) {
      return;
    }

    this.focused = true;

    const layout = Layouts[getLayout(this.state.players.size)];

    // bring own spot forward
    const ownSpot = this.spots[this.ownId];
    const ownScale = layout.selectionBoxSize / ownSpot.colWidth;
    ownSpot.bringToFront(
      this.cameras.main.width / 2,
      this.cameras.main.height / 2 + layout.selectionYShift * 0.7,
      ownScale
    );

    let otherSpotsCount = 0;
    this.state.playerOrder.forEach((id, i) => {
      if (id === this.ownId) {
        return;
      }

      const spotLocation = layout.getSide(otherSpotsCount);
      const spotRow = layout.getRow(otherSpotsCount);
      const spot = this.spots[id];

      const colWidth = Math.floor(
        (this.cameras.main.width -
          layout.hPadding * 2 -
          layout.selectionBoxSize) /
          layout.fieldOpponentsColumns
      );
      const rowHeight = Math.floor(
        (this.cameras.main.height - layout.vPadding * 2) /
          layout.fieldOpponentsRows
      );

      spot.isRight = spotLocation.side === "right";

      spot.move(
        layout.hPadding +
          colWidth / 2 +
          spotLocation.col * colWidth +
          (spotLocation.side === "right" ? layout.selectionBoxSize : 0),
        layout.vPadding + rowHeight / 2 + spotRow * rowHeight,
        colWidth / spot.colWidth
      );

      otherSpotsCount++;
    });
  }

  reset() {
    // console.log("SHUTTINHG");
    this.spots = null;
    this.state.players.onAdd = null;
    this.selectionPanel = null;
    this.state = null;
    this.input.removeAllListeners();
    this.events.removeAllListeners();
  }
}
