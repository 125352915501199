import { CSSTransition } from "react-transition-group";
import Box from "../../components/Box";
import styles from "./Alpha.module.scss";
import Checkbox from "react-custom-checkbox";
import MenuButton from "../../components/Form/MenuButton";

const Alpha = ({ onClick, onClose }) => {
  return (
    <Box className={styles.container}>
      <img src="images/logo-s.png" alt="Axie Blitz logo" />
      <p style={{ fontSize: 32, textAlign: "center" }}>
        Welcome to our Alpha release!
      </p>
      <p>
        As an early release, we'll be actively monitoring the game and releasing
        with limited features. Currently the Blitz Store and Ranked games are
        disabled but will released soon!
      </p>
      <p>
        As a welcome gift everybody starts with{" "}
        <strong>
          10 <img src="images/ui/energy.png" style={{ height: 20 }} /> points
        </strong>
        . Each hour you will earn a free energy point (max 6), use your points
        to play games.
      </p>
      <div>
        Features planned for next release:
        <ul>
          <li>
            Blitz Points - earn Blitz Points by winning games. BP can we
            exchanged for energy in the Store.
          </li>
          <li>
            Ranked Games - if you own <strong>Axies</strong> you can play Ranked
            Games for a <strong>$SLP</strong> with a chance to earn SLP rewards
            for top placements.
          </li>
          <li>
            A new set of abilities - currently all abilities are temporary. All
            will be tweaked as we gather data, and Axie parts may have different
            abilities in the future.{" "}
            <strong>
              We'll note when a part has a final ability and won't change
            </strong>
          </li>
        </ul>
      </div>
      <p>
        Also expect the game to go on maintenance mode often as we continue to
        fix bugs and improve general performance.
      </p>
      <p>
        As we continue to build towards are final version, we value all your
        feedback. Head over to our{" "}
        <a href="https://discord.gg/axieblitz" target="_blank">
          discord
        </a>{" "}
        and say hi!
      </p>
      <br />
      <Checkbox
        name="game-fill"
        icon={<img src="images/ui/check.png" style={{ width: 44 }} alt="" />}
        size={28}
        borderWidth={3}
        checked={false}
        label="Don't show again"
        borderColor="#503c27"
        containerClassName={styles.hide}
        // labelClassName={settingStyles.optionLabel}
        onChange={(value) => {
          onClick(value);
        }}
      />
      <MenuButton
        className={styles.close}
        text={"close"}
        throttleMs={1000}
        onClick={() => {
          onClose();
        }}
      />
    </Box>
  );
};

export default Alpha;
