import Phaser from "phaser";
import { throttle } from "throttle-debounce";
import { OptionView } from "../../OptionView";
import { SOUND_FX } from "../../../sound/Sound";

export class SoundSettings extends OptionView {
  constructor(scene, x, y, children) {
    super(scene, x, y, children);

    const midX = this.width / 2;

    this.add(
      this.scene.make.text({
        x: -midX,
        y: 100,
        text: "General volume",
        style: {
          fontFamily: "Luckiest Guy",
          fontSize: "26px",
          stroke: "#7d5d2a",
          strokeThickness: 4,
          fill: "#ffffff",
        },
        add: true,
        origin: {
          x: 0,
          y: 0.5,
        },
      })
    );

    this.add(
      this.scene.make.text({
        x: -midX,
        y: 240,
        text: "Music volume",
        style: {
          fontFamily: "Luckiest Guy",
          fontSize: "26px",
          stroke: "#7d5d2a",
          strokeThickness: 4,
          fill: "#ffffff",
        },
        add: true,
        origin: {
          x: 0,
          y: 0.5,
        },
      })
    );

    this.add(
      this.scene.make.text({
        x: -midX,
        y: 380,
        text: "FX volume",
        style: {
          fontFamily: "Luckiest Guy",
          fontSize: "26px",
          stroke: "#7d5d2a",
          strokeThickness: 4,
          fill: "#ffffff",
        },
        add: true,
        origin: {
          x: 0,
          y: 0.5,
        },
      })
    );

    this.add(
      this.scene.rexUI.add.roundRectangle(
        -midX + 150,
        140,
        300,
        16,
        6,
        0x996147
      )
    );
    this.generalText = this.scene.make.text({
      x: -midX + 325,
      y: 138,
      text: "",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "26px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#ffffff",
      },
      add: true,
      origin: {
        x: 0,
        y: 0.5,
      },
    });
    this.add(this.generalText);
    this.generalImg = this.scene.add.image(-midX + 16, 140, "small-thumb");
    this.generalImg.slider = this.scene.plugins
      .get("rexSlider")
      .add(this.generalImg, {
        endPoints: [
          {
            x: -midX,
            y: 140,
          },
          {
            x: -midX + 300,
            y: 140,
          },
        ],
        value: 0,
        valuechangeCallback: throttle(500, (value) => {
          this.scene.events.emit("setting-change", {
            category: "sound",
            field: "volume.general",
            value: parseFloat(value.toFixed(2)),
          });
          this.generalText.setText(Math.floor(value * 100));
        }),
      });
    this.add(this.generalImg);

    this.add(
      this.scene.rexUI.add.roundRectangle(
        -midX + 150,
        280,
        300,
        16,
        6,
        0x996147
      )
    );
    this.musicText = this.scene.make.text({
      x: -midX + 325,
      y: 278,
      text: "",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "26px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#ffffff",
      },
      add: true,
      origin: {
        x: 0,
        y: 0.5,
      },
    });
    this.add(this.musicText);
    this.musicImg = this.scene.add.image(-midX + 16, 280, "small-thumb");
    this.musicImg.slider = this.scene.plugins
      .get("rexSlider")
      .add(this.musicImg, {
        endPoints: [
          {
            x: -midX,
            y: 280,
          },
          {
            x: -midX + 300,
            y: 280,
          },
        ],
        value: 0,
        valuechangeCallback: throttle(500, (value) => {
          this.scene.events.emit("setting-change", {
            category: "sound",
            field: "volume.music",
            value: parseFloat(value.toFixed(2)),
          });
          this.musicText.setText(Math.floor(value * 100));
        }),
      });
    this.add(this.musicImg);

    this.add(
      this.scene.rexUI.add.roundRectangle(
        -midX + 150,
        420,
        300,
        16,
        6,
        0x996147
      )
    );
    this.fxText = this.scene.make.text({
      x: -midX + 325,
      y: 418,
      text: "",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "26px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#ffffff",
      },
      add: true,
      origin: {
        x: 0,
        y: 0.5,
      },
    });
    this.add(this.fxText);
    this.fxImg = this.scene.add.image(-midX + 16, 420, "small-thumb");
    this.fxImg.slider = this.scene.plugins.get("rexSlider").add(this.fxImg, {
      endPoints: [
        {
          x: -midX,
          y: 420,
        },
        {
          x: -midX + 300,
          y: 420,
        },
      ],
      value: 0,
      valuechangeCallback: throttle(500, (value) => {
        this.scene.events.emit("setting-change", {
          category: "sound",
          field: "volume.fx",
          value: parseFloat(value.toFixed(2)),
        });
        this.fxText.setText(Math.floor(value * 100));
      }),
    });
    this.add(this.fxImg);

    this.alpha = 0;
    // this.add(this.soon);
  }

  async show(settings) {
    if (this.visibilityTween) {
      this.visibilityTween.stop();
      this.visibilityTween.remove();
      this.visibilityTween = null;
    }

    this.visibilityTween = this.scene.tweens.add({
      targets: this,
      alpha: 1,
      duration: 333,
    });

    this.generalImg.slider.setValue(settings.volume.general);
    this.musicImg.slider.setValue(settings.volume.music);
    this.fxImg.slider.setValue(settings.volume.fx);
  }

  hide() {
    if (this.visibilityTween) {
      this.visibilityTween.stop();
      this.visibilityTween.remove();
      this.visibilityTween = null;
    }

    this.visibilityTween = this.scene.tweens.add({
      targets: this,
      alpha: 0,
      duration: 333,
    });
  }
}
