import styles from "./button.module.scss";
import { throttle } from "throttle-debounce";
import { useCallback, useEffect, useMemo } from "react";

const Button = ({
  text,
  className,
  onClick = () => {},
  size,
  display,
  type,
  children,
  throttleMs = 50,
  ...rest
}) => {
  // const throttledClick = useMemo(
  //   () =>
  //     throttle(throttleMs, () => {
  //       console.log("in");
  //       onClick();
  //     }),
  //   []
  // );
  const throttledClick = useCallback(throttle(throttleMs, onClick));
  // console.log(throttledClick);
  useEffect(() => {
    return () => {
      throttledClick.cancel();
    };
  });

  return (
    <button
      className={[
        styles.button,
        className,
        styles[size],
        styles[display],
        styles[type],
      ]
        .join(" ")
        .trim()}
      onClick={throttledClick}
      {...rest}
    >
      <div className={styles.copy}>{text}</div>
      {children}
    </button>
  );
};

export default Button;
