import { useEffect, useState, createContext } from "react";
import * as Colyseus from "colyseus.js";

const client = new Colyseus.Client(
  process.env.REACT_APP_COLYSEUS || `ws://${window.location.hostname}:2567`
);

export const ClientContext = createContext(client);

export const ColyseusProvider = ({ children }) => (
  <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
);
