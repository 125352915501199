export function getLayout(playerCount) {
  let layout;
  return "p19";
  // switch (true) {
  //   case playerCount > 13:
  //     layout = "p19";
  //     break;
  //   case playerCount > 9:
  //     layout = "p13";
  //     break;
  //   case playerCount > 5:
  //     layout = "p9";
  //     break;
  //   case playerCount > 3:
  //     layout = "p5";
  //     break;
  //   default:
  //     layout = "p3";
  //     break;
  // }

  // return layout;
}

export const Layouts = {
  p19: {
    hPadding: 10,
    vPadding: 10,
    fields: {
      scale: 0.2475,
      hSpacing: 10,
      vSpacing: 10,
      hPadding: 34,
      width: 170,
      height: 170,
    },
    mine: {
      width: 540,
      height: 416,
      boardLeft: 432,
      containerleft: 370,
      spacing: 15,
      stats: {
        marginTop: 10,
        padding: 10,
      },
      bars: {
        w: 80,
        h: 34,
      },
    },
    // selectionBoxSize: 340,
    // selectionRows: [6, 7, 6],
    // selectionAxieScale: [1, 0.8, 1],
    // selectionScaleAfterFocus: 1.1,
    // selectionYShift: 100,
    // fieldOpponentsColumns: 6,
    // fieldOpponentsRows: 3,

    getSide: (current, newIndex) => {
      const cur = Math.floor(current / 2);
      return {
        side: cur % 2 === 0 ? "left" : "right",
        col: current % 4,
      };
    },
    getRow: (current) => {
      return Math.floor(current / 4);
    },
  },

  p13: {
    hPadding: 20,
    vPadding: 20,
    fields: {
      scale: 0.428,
      hSpacing: 79,
      vSpacing: 25,
      width: 108,
      height: 210,
    },
    mine: {
      l: 419,
      spacing: 15,
      bars: {
        w: 80,
        h: 34,
      },
    },
    selectionBoxSize: 410,
    selectionRows: [4, 5, 4],
    selectionAxieScale: [1, 0.8, 1],
    selectionScaleAfterFocus: 1.1,
    selectionYShift: 100,
    fieldOpponentsColumns: 4,
    fieldOpponentsRows: 3,

    getSide: (current, newIndex) => {
      const cur = Math.floor(current / 2);
      return {
        side: cur % 2 === 0 ? "left" : "right",
        col: current % 4,
      };
    },
    getRow: (current) => {
      return Math.floor(current / 4);
    },
  },

  p9: {
    hPadding: 20,
    vPadding: 20,
    fields: {
      scale: 0.595,
      hSpacing: 47,
      vSpacing: 25,
      width: 150,
      height: 292,
    },
    mine: {
      l: 419,
      spacing: 15,
      bars: {
        w: 80,
        h: 34,
      },
    },
    selectionBoxSize: 420,
    selectionRows: [4, 5],
    selectionAxieScale: [1, 1],
    selectionScaleAfterFocus: 1.1,
    selectionYShift: 100,
    fieldOpponentsColumns: 4,
    fieldOpponentsRows: 2,

    getSide: (current, newIndex) => {
      const cur = Math.floor(current / 2);
      return {
        side: cur % 2 === 0 ? "left" : "right",
        col: current % 4,
      };
    },
    getRow: (current) => {
      return Math.floor(current / 4);
    },
  },

  p5: {
    hPadding: 95,
    vPadding: 40,
    fields: {
      scale: 0.595,
      hSpacing: 160,
      vSpacing: 50,
      width: 150,
      height: 292,
    },
    mine: {
      l: 419,
      spacing: 15,
      bars: {
        w: 80,
        h: 34,
      },
    },
    selectionBoxSize: 480,
    selectionRows: [2, 3],
    selectionAxieScale: [1, 0.9],
    selectionScaleAfterFocus: 1.1,
    selectionYShift: 100,
    fieldOpponentsColumns: 2,
    fieldOpponentsRows: 2,

    getSide: (current, newIndex) => {
      const cur = current;
      return {
        side: cur % 2 === 0 ? "left" : "right",
        col: current % 2,
      };
    },
    getRow: (current) => {
      return Math.floor(current / 2);
    },
  },

  p3: {
    hPadding: 95,
    vPadding: 60,
    fields: {
      scale: 1,
      hSpacing: 70,
      vSpacing: 50,
      width: 252,
      height: 490,
    },
    mine: {
      l: 419,
      spacing: 15,
      bars: {
        w: 80,
        h: 34,
      },
    },
    selectionBoxSize: 480,
    selectionRows: [3],
    selectionAxieScale: [1],
    selectionScaleAfterFocus: 1.1,
    selectionYShift: 100,
    fieldOpponentsColumns: 2,
    fieldOpponentsRows: 1,

    getSide: (current, newIndex) => {
      const cur = current;
      return {
        side: cur % 2 === 0 ? "left" : "right",
        col: current % 2,
      };
    },
    getRow: (current) => {
      return Math.floor(current / 2);
    },
  },
};
