import Phaser from "phaser";
import { SOUND_FX } from "../sound/Sound";

export class Dropdown extends Phaser.GameObjects.Container {
  constructor(scene, x, y, { width = 200, height = 40, name, ...config } = {}) {
    super(scene, x, y);

    scene.add.existing(this);

    this.opened = false;

    this.selection = this.scene.rexUI.add.roundRectangle(
      0,
      0,
      width,
      height,
      3,
      0xbaa53c
    );
    this.add(this.selection);
    this.selection.setInteractive();

    this.arrow = this.scene.add.image(width / 2 - 22, 0, "down-arrow");
    this.add(this.arrow);

    this.displayText = this.scene.make.text({
      x: 0,
      y: 0,
      text: "",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "20px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#ffffff",
        align: "center",
      },
      add: true,
      origin: {
        x: 0.5,
        y: 0.5,
      },
    });
    this.add(this.displayText);

    if (config.options) {
      this.setOptions(config.options);
    }

    this.height = height;
    this.width = width;
    this.config = config;
    this.name = name;

    this.scene.input.on("gameobjectdown", (pointer, gameObject) => {
      if (gameObject === this.selection) {
        this.toggleDropdown();
      }

      if (
        typeof gameObject.getData(this.name + "-drop-option") !== "undefined"
      ) {
        this.selectOption(gameObject.getData(this.name + "-drop-option"));
        this.toggleDropdown();

        this.config.onChange(this.selectedValue);
      }
    });
  }

  selectOption(value) {
    const index = this.options.findIndex((option) => option.value === value);
    console.log(value, this.options, index);
    this.setDisplayText(this.options[index].text);

    this.selectedValue = this.options[index].value;
  }

  toggleDropdown() {
    if (this.opened) {
      this.arrow.rotation = 0;
      this.optionsContainer.alpha = 0;
    } else {
      this.arrow.rotation = Phaser.Math.DegToRad(180);
      this.optionsContainer.alpha = 1;
    }

    this.opened = !this.opened;
  }

  setDisplayText(text) {
    this.displayText.setText(text);
  }

  setOptions(options) {
    this.options = options;
    this.optionsContainer = this.scene.add.container(0, this.height);

    this.optionsContainer.add(
      this.scene.rexUI.add.roundRectangle(
        0,
        this.height - 3,
        this.width,
        this.height * options.length,
        3,
        0xbaa53c
      )
    );

    options.forEach((option, i) => {
      const optionObj = this.scene.make.text({
        x: 0,
        y: i * this.height,
        text: option.text,
        style: {
          fontFamily: "Luckiest Guy",
          fontSize: "20px",
          stroke: "#7d5d2a",
          strokeThickness: 4,
          fill: "#ffffff",
          align: "center",
        },
        add: true,
        origin: {
          x: 0.5,
          y: 0.5,
        },
      });
      optionObj.setData(this.name + "-drop-option", option.value);
      optionObj.setInteractive();
      this.optionsContainer.add(optionObj);
    });

    this.add(this.optionsContainer);
    this.optionsContainer.alpha = 0;
  }

  setListeners() {
    this.scene.input.on("gameobjectover", (pointer, gameObject, event) => {
      if (gameObject === this) {
        this.scene.game.events.emit("play-fx", SOUND_FX.HOVER);
      }
    });

    this.scene.input.on("gameobjectdown", (pointer, gameObject, event) => {
      if (gameObject === this && this.enabled) {
        this.onClick();
      }
    });
  }

  onClick(fn) {
    const clickThrottle = this.config.throttle;
    const now = new Date().getTime();

    if (fn) {
      this.onClickFn = fn;
      return;
    }

    if (now - this.lastPressed < clickThrottle) {
      return;
    }

    this.lastPressed = now;
    this.scene.game.events.emit("play-fx", SOUND_FX.CLICK);

    this.state = !this.state;
    this.showState();

    if (this.onClickFn) {
      this.onClickFn(this.state);
    }
  }

  setState(state) {
    this.state = state;
    this.showState();
  }

  showState() {
    if (this.state) {
      this.activeState.visible = true;
      this.activeStateText.visible = true;
      this.offState.visible = false;
      this.offStateText.visible = false;
    } else {
      this.activeState.visible = false;
      this.activeStateText.visible = false;
      this.offState.visible = true;
      this.offStateText.visible = true;
    }
  }

  toggle(enabled = true) {
    this.enabled = enabled;
    // this.hlsPipeline.setSatAdjust(enabled ? 1 : 0.5);
    // this.hlsPipeline.setLumAdjust(enabled ? 0.5 : 0.6);
  }
}
