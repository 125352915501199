import Phaser from "phaser";

export const timer = (ms) => new Promise((res) => setTimeout(res, ms));

export const createSkillCard = (
  container,
  {
    x,
    y,
    width,
    height,
    key,
    add,
    partType,
    textureKey,
    fullImg = false,
    imgWidth,
    imgHeight,
  }
) => {
  const canvasTextureKey = textureKey ? textureKey : key;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const scene = container.scene;
  const skillBoxImg = scene.textures.get("skill-box").getSourceImage();
  const outlinePlugin = scene.plugins.get("rexOutlinePipeline");
  let exists = false;
  let skillBox;

  canvas.width = width;
  canvas.height = height;

  let skillImg;

  function drawSkill() {
    ctx.drawImage(skillBoxImg, 0, 0, width, height);
    ctx.globalCompositeOperation = "source-in";

    skillImg = scene.textures.get(key).getSourceImage();
    ctx.drawImage(
      skillImg,
      fullImg ? 0 : (256 - width * 2.5) / 2,
      fullImg ? 0 : (256 - height * 2.5) / 2,
      fullImg ? imgWidth : width * 2.5,
      fullImg ? imgHeight : height * 2.5,
      0,
      0,
      width,
      height
    );

    ctx.globalCompositeOperation = "source-over";
    if (!scene.textures.exists(canvasTextureKey + "-canvas")) {
      scene.textures.addCanvas(canvasTextureKey + "-canvas", canvas);
    }
  }

  if (scene.textures.exists(key)) {
    exists = true;
    drawSkill();
  } else {
    // skillImg = scene.textures.get("skill-placeholder").getSourceImage();
    // ctx.drawImage(skillImg, 0, 0, 74, 74, 0, 0, 70, 70);

    scene.load.image(key, `images/skills/${key}.png`);
    scene.load.on("filecomplete", function checkImage(loadedKey) {
      if (key === loadedKey) {
        drawSkill();
        skillBox.setTexture(canvasTextureKey + "-canvas");
        scene.load.off("filecomplete", checkImage);
      }
    });

    scene.load.start();
  }

  // document.body.appendChild(skillImg);

  if (add) {
    if (container.getByName("skill-box-" + partType)) {
      skillBox = container.getByName("skill-box-" + partType);
      if (exists) {
        skillBox.setTexture(canvasTextureKey + "-canvas");
      }
    } else {
      skillBox = scene.add.image(
        x,
        y,
        exists ? canvasTextureKey + "-canvas" : "skill-placeholder"
      );
      skillBox.setSize(width, height);
      skillBox.setOrigin(0, 0);
      skillBox.name = "skill-box-" + partType;
      outlinePlugin.add(skillBox, {
        thickness: 1.5,
        outlineColor: 0xa98e63,
        quality: 0.05,
      });

      skillBox.depth = 3;
    }

    container.add(skillBox);
  }

  skillBox.setData("part-skill", partType);

  return skillBox;
};

export const canvasMakeTransparent = (context, width, height) => {
  const idata = context.getImageData(0, 0, width, height);
  const data32 = new Uint32Array(idata.data.buffer);
  let i = 0;
  const len = data32.length;
  while (i < len) data32[i] = data32[i++] << 8;
  context.putImageData(idata, 0, 0);
};

export const canvasInvert = (context, width, height) => {
  const imageData = context.getImageData(0, 0, width, height);
  const dataArr = imageData.data;

  for (let i = 0; i < dataArr.length; i += 4) {
    const r = dataArr[i]; // Red color lies between 0 and 255
    const g = dataArr[i + 1]; // Green color lies between 0 and 255
    const b = dataArr[i + 2]; // Blue color lies between 0 and 255

    const invertedRed = 255 - r;
    const invertedGreen = 255 - g;
    const invertedBlue = 255 - b;

    dataArr[i] = invertedRed;
    dataArr[i + 1] = invertedGreen;
    dataArr[i + 2] = invertedBlue;
  }

  context.putImageData(imageData, 0, 0);
};

export const makeBG = (scene, color, width, height, label) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const mask = scene.textures.get("bg-mask").getSourceImage();
  // console.log(mask);
  canvas.width = width;
  canvas.height = height;

  ctx.drawImage(mask, 0, 0, width, height);
  canvasInvert(ctx, width, height);

  canvasMakeTransparent(ctx, width, height);
  ctx.globalCompositeOperation = "source-in";

  ctx.fillStyle = color;
  ctx.fillRect(0, 0, width, height);

  scene.textures.addCanvas("bg-" + label, canvas);
};

export const createColorParticle = (
  scene,
  { width = 32, height = 32, color, label, textureKey = "glow-particle" }
) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const img = scene.textures.get(textureKey).getSourceImage();

  canvas.width = width;
  canvas.height = height;

  ctx.drawImage(img, 0, 0);

  // const idata = ctx.getImageData(0, 0, canvas.width, canvas.height);
  // const data32 = new Uint32Array(idata.data.buffer);
  // let i = 0;
  // const len = data32.length;
  // while (i < len) data32[i] = data32[i++] << 8;
  // ctx.putImageData(idata, 0, 0);

  canvasMakeTransparent(ctx, width, height);

  ctx.globalCompositeOperation = "source-in";

  ctx.fillStyle = color;
  ctx.fillRect(0, 0, width, height);

  scene.textures.addCanvas("particle-" + label, canvas);
};

export const getRandomInt = (vMin, vMax) => {
  const min = Math.ceil(vMin);
  const max = Math.floor(vMax);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getWorldPosition = (obj) => {
  const tempMatrix = new Phaser.GameObjects.Components.TransformMatrix();
  obj.getWorldTransformMatrix(tempMatrix);
  return tempMatrix.decomposeMatrix();
};

export const calcScaledDimensions = (obj) => {
  const parent = obj.parentContainer;

  return {
    width: obj.width * parent.scaleX,
    height: obj.height * parent.scaleY,
  };
};
