import Phaser from "phaser";
import DropShadowPipelinePlugin from "phaser3-rex-plugins/plugins/dropshadowpipeline-plugin.js";
import GlowFilterPipelinePlugin from "phaser3-rex-plugins/plugins/glowfilterpipeline-plugin.js";
import OutlinePipelinePlugin from "phaser3-rex-plugins/plugins/outlinepipeline-plugin.js";
import HSLAdjustPipelinePlugin from "phaser3-rex-plugins/plugins/hsladjustpipeline-plugin.js";
import GrayScalePipelinePlugin from "phaser3-rex-plugins/plugins/grayscalepipeline-plugin.js";
import KawaseBlurPipelinePlugin from "phaser3-rex-plugins/plugins/kawaseblurpipeline-plugin.js";
import GesturesPlugin from "phaser3-rex-plugins/plugins/gestures-plugin.js";
import CanvasPlugin from "phaser3-rex-plugins/plugins/canvas-plugin.js";
import RoundRectanglePlugin from "phaser3-rex-plugins/plugins/roundrectanglecanvas-plugin.js";
import WebFontLoaderPlugin from "phaser3-rex-plugins/plugins/webfontloader-plugin.js";
import BBCodeTextPlugin from "phaser3-rex-plugins/plugins/bbcodetext-plugin.js";
import UIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";
import SliderPlugin from "phaser3-rex-plugins/plugins/slider-plugin.js";
import CircularProgressPlugin from "phaser3-rex-plugins/plugins/circularprogress-plugin.js";

import { SOUND_FX } from "./sound/Sound";
import { GAME_STATE } from "../Game";
import { PreloadScene } from "./Preload";
import { MenuScene } from "./Menu";
import { WorldScene } from "./World";
import { UIScene } from "./UIOverlay";
import { SelectionScene } from "./Selection";
import { ParticlesScene } from "./ParticlesScene";
import { MatchResults } from "./views/matches/MatchResults";
import { SoundScene } from "./sound/Sound";
import { Profile } from "./views/profile/Profile";
import { Settings } from "./views/settings/Settings";
import { getInfo, getUserSettings, updateUserSettings } from "./helpers/api";
import { SERVER_STATUS } from "./constants";
import { VIEWPORT } from "./constants";

const config = {
  type: Phaser.WEBGL,
  width: VIEWPORT.width,
  height: VIEWPORT.height,
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_BOTH,
  },
  title: "Axie Blitz",
  audio: {
    disableWebAudio: true,
  },
  disableContextMenu: true,
  plugins: {
    global: [
      {
        key: "rexCircularProgressPlugin",
        plugin: CircularProgressPlugin,
        start: true,
      },
      {
        key: "rexSlider",
        plugin: SliderPlugin,
        start: true,
      },
      {
        key: "rexKawaseBlurPipeline",
        plugin: KawaseBlurPipelinePlugin,
        start: true,
      },
      {
        key: "rexRoundRectangleCanvasPlugin",
        plugin: RoundRectanglePlugin,
        start: true,
      },
      {
        key: "rexBBCodeTextPlugin",
        plugin: BBCodeTextPlugin,
        start: true,
      },
      {
        key: "rexGrayScalePipeline",
        plugin: GrayScalePipelinePlugin,
        start: true,
      },
      {
        key: "rexWebFontLoader",
        plugin: WebFontLoaderPlugin,
        start: true,
      },
      {
        key: "rexCanvasPlugin",
        plugin: CanvasPlugin,
        start: true,
      },
      {
        key: "rexDropShadowPipeline",
        plugin: DropShadowPipelinePlugin,
        start: true,
      },
      {
        key: "rexGlowFilterPipeline",
        plugin: GlowFilterPipelinePlugin,
        start: true,
      },
      {
        key: "rexOutlinePipeline",
        plugin: OutlinePipelinePlugin,
        start: true,
      },
      {
        key: "rexHSLAdjustPipeline",
        plugin: HSLAdjustPipelinePlugin,
        start: true,
      },
    ],
    scene: [
      {
        key: "rexGestures",
        plugin: GesturesPlugin,
        mapping: "rexGestures",
      },
      {
        key: "rexUI",
        plugin: UIPlugin,
        mapping: "rexUI",
      },
    ],
  },

  scene: {
    preload: PreloadScene,
  },
};

class BlitzGame extends Phaser.Game {
  constructor(config, dispatch) {
    super(config);

    this.dispatch = dispatch;
    this.state = GAME_STATE.LOBBY;
    this.initialize();
  }

  async initialize() {
    this.information = await getInfo();

    setInterval(async () => {
      try {
        this.information = await getInfo();

        // if (this.scene.isActive("menu")) {
        //   this.scene.getScene("menu").checkUser();
        // }
      } catch (e) {
        console.log("error getting game info", e);
      }
    }, 60 * 5 * 1000);

    this.addGlobalScenes();
  }

  async getSettings() {
    try {
      this.settings = await getUserSettings();
    } catch (e) {
      this.settings = {
        game: { fill: false },
        sound: { volume: { fx: 1, music: 1 } },
      };

      console.log("error getting settings", e);
    }

    this.scene.getScene("sound").adjustSettings(this.settings.sound.volume);
  }

  addGlobalScenes() {
    this.scene.add("profile", Profile);
    this.scene.add("settings", Settings);
    this.scene.add("menu", MenuScene);
    this.scene.add("UI", UIScene);
    this.scene.add("matchResults", MatchResults);
    this.scene.add("sound", SoundScene);

    // workaround for sceneManager being crap
    setTimeout(() => {
      this.soundScene = this.scene.getScene("sound");

      this.addGlobalListeners();
    }, 0);
  }

  addScenes() {
    // this.scene.add("selection", SelectionScene);
    this.scene.add("world", WorldScene);
    this.scene.add("particles", ParticlesScene);

    // this.scene.bringToTop("world");
    // this.scene.bringToTop("particles");
    // this.scene.bringToTop("UI");
    // this.scene.bringToTop("matchResults");

    this.addWorldListeners();
  }

  connectToRoom(room) {
    const world = this.scene.getScene("world");
    // const selection = this.scene.getScene("selection");

    this.state = GAME_STATE.WAITING;

    this.room = room;
    this.roomId = room.id;
    this.sessionId = room.sessionId;
    this.roomState = room.state;

    world.assignState(this.roomState, this.sessionId);
    // selection.assignState(this.roomState, this.sessionId);

    this.roomState.listen("gameState", (current, prev) => {
      // console.log(">>>>", current, prev);
      this.checkRoomState();
    });

    this.roomState.players.onAdd = () => {
      this.events.emit("play-fx", SOUND_FX.MATCH_PLAYER_FOUND, {
        volume: 0.2,
      });
      this.dispatch({
        type: "update_game",
        gameAction: "player_count",
        value: this.roomState.players.size,
      });
    };

    this.room.onMessage("effects-cast", (msg) => {
      if (this.scene.isActive("world")) {
        this.scene.getScene("world").castEffect(msg);
      }
    });

    this.room.onMessage("countdown", (msg) => {
      this.scene.getScene("UI").showNumber(msg);
    });

    this.room.onMessage("cancel-move", () => {
      world.cancelMove();
    });

    this.room.onMessage("game-winner", (winnerId) => {
      world.showWinner(winnerId);
    });

    this.room.onMessage("reset-wall", () => {
      world.resetWallProgress();
    });

    this.room.onMessage("reset-lifeclock", () => {
      world.resetLifeProgress();
    });

    this.room.onMessage("failed-cast", (part) => {
      world.failCastAbility(part);
    });

    this.room.onMessage("success-cast", (part) => {
      world.successCastAbility(part);
    });

    this.room.onMessage("match-stats", (data) => {
      if (this.scene.isActive("matchResults")) {
        this.scene.getScene("matchResults").show({
          players: data,
        });
      }
    });

    this.checkRoomState();
    this.scene.getScene("UI").connect(this.room);
  }

  disconnectFromRoom() {
    this.room.removeAllListeners();
    this.room.leave();
  }

  checkRoomState() {
    switch (this.roomState.gameState) {
      case GAME_STATE.WAITING:
        // case GAME_STATE.SELECTING:
        // this.scene.sleep("menu");
        this.soundScene.fadeOutMusic();

        if (this.scene.isActive("menu")) {
          // this.dispatch({ type: "change_view", value: "selection" });
          // this.scene.getScene("menu").scene.transition({
          //   target: "selection",
          //   duration: 500,
          //   // sleep: true,
          //   moveBelow: true,
          // });
          // this.scene.getScene("UI").showLeave();
        } else {
          // this.scene.getScene("matchResults").scene.transition({
          //   target: "selection",
          //   duration: 500,
          //   // moveBelow: true,
          //   // remove: true,
          // });
        }

        setTimeout(() => {
          this.soundScene.stopCurrentMusicTrack();
          this.soundScene.fadeInMusic();
          this.soundScene.playMusicTrack("select");
          this.scene.getScene("UI").startPing();
        }, 500);

        break;

      // this.scene.sleep("menu");
      // this.scene.start("selection");

      // break;

      case GAME_STATE.STARTING:
        // this.scene.sleep("selection");
        this.events.emit("play-fx", SOUND_FX.MATCH_FULL);

        this.scene.getScene("menu").scene.transition({
          target: "world",
          duration: 500,
          // remove: true,
          moveBelow: true,
        });

        this.dispatch({ type: "change_view", value: "match" });

        // this.soundScene.fadeOutMusic();
        setTimeout(() => {
          this.soundScene.stopCurrentMusicTrack();
          // this.scene.stop("selection");
        }, 500);

        // this.scene.start("world");
        this.scene.start("particles");

        break;

      case GAME_STATE.PLAYING:
        this.events.emit("play-fx", SOUND_FX.GO);
        this.scene.getScene("UI").hideNumber();
        // this.soundScene.fadeInMusic();
        setTimeout(() => {
          this.soundScene.playList([
            "track2",
            "track4",
            "track6",
            "track3",
            "track5",
          ]);
        }, 1000);

        break;
      default:
        console.log("unknown gameState", this.roomState.gameState);
        break;
    }
  }

  showResults() {
    this.soundScene.fadeOutMusic();
    this.scene.getScene("particles").reset();
    this.scene.getScene("world").scene.transition({
      target: "matchResults",
      duration: 750,
      remove: true,
    });
    setTimeout(() => {
      this.soundScene.stopCurrentMusicTrack();
      this.soundScene.fadeInMusic();
      this.soundScene.playMusicTrack("funky_results", { loop: true });
      this.reset();
    }, 500);
  }

  addGlobalListeners() {
    const preload = this.scene.getScene("default");
    const UI = this.scene.getScene("UI");
    const matchResults = this.scene.getScene("matchResults");
    // const menu = this.scene.getScene("menu");
    // const profile = this.scene.getScene("profile");
    // const settings = this.scene.getScene("settings");

    // menu.events.on("menu-join", () => {
    //   this.joinGame();
    // });

    // menu.events.on("menu-profile", () => {
    //   this.showProfile();
    // });

    // profile.events.on("profile-exit", () => {
    //   this.hideProfile();
    // });

    // menu.events.on("menu-settings", () => {
    //   this.showSettings();
    // });

    // settings.events.on("settings-exit", () => {
    //   this.hideSettings();
    // });

    // settings.events.on("settings-save", (newSettings) => {
    //   this.saveSettings(newSettings);
    //   this.hideSettings();

    //   // update class in wrapper
    //   const gameWrapper = document.querySelector(".game-wrapper");
    //   gameWrapper.className = `game-wrapper size-${newSettings.graphics.screenSize}`;
    //   window.localStorage.setItem("size", newSettings.graphics.screenSize);
    // });

    UI.events.on("show-results", () => {
      this.showResults();
      this.room.send("request-stats");
    });

    // UI.events.on("leave-waiting", () => {
    //   this.soundScene.fadeOutMusic();

    //   this.disconnectFromRoom();
    //   // this.scene.getScene("selection").scene.transition({
    //   //   target: "menu",
    //   //   duration: 500,
    //   //   remove: true,
    //   //   moveBelow: true,
    //   // });
    //   // this.dispatch({ type: "change_view", value: "mainMenu" });
    //   this.scene.remove("particles");
    //   this.scene.remove("world");

    //   setTimeout(() => {
    //     this.soundScene.stopCurrentMusicTrack();
    //     this.soundScene.fadeInMusic();
    //     this.soundScene.playMusicTrack("track1", { loop: true });
    //   }, 400);
    // });

    preload.events.on("user-login", async () => {
      await this.getSettings();

      this.scene.getScene("default").scene.transition({
        target: "menu",
        duration: 750,
        sleep: true,
        moveBelow: true,
      });
      this.scene.start("sound");
      // this.scene.start("menu");
      this.scene.start("UI");
      this.dispatch({ type: "change_view", value: "mainMenu" });

      this.soundScene.playMusicTrack("track1", { loop: true });
    });

    this.events.on("play-fx", (sound, config) => {
      this.soundScene.playFX(sound, config);
    });
    this.events.on("stop-fx", (sound) => {
      this.soundScene.stopFX(sound);
    });

    matchResults.events.on("play-again", () => {
      // this.scene.getScene("world").events.removeAllListeners();
      // this.scene.stop("world");
      this.disconnectFromRoom();

      if (this.information.gameStatus === SERVER_STATUS.MAINTENANCE) {
        this.exitToMenu();
      } else {
        // this.scene.getScene("matchResults").scene.transition({
        //   target: "selection",
        //   duration: 500,
        //   remove: true,
        //   moveBelow: true,
        // });
        setTimeout(() => {
          // this.soundScene.stopCurrentMusicTrack();
          // this.soundScene.fadeInMusic();
          // this.soundScene.playMusicTrack("select");
          // this.scene.getScene("UI").startPing();
          this.joinGame();
        }, 500);
      }
    });

    matchResults.events.on("back-to-menu", () => {
      this.exitToMenu();
    });

    window.addEventListener("online", () => {
      if (this.room) {
        this.events.emit("reconnect");
      }
    });
  }

  // async reconnect() {
  //   try {
  //     const room = await
  //   }catch(e) {

  //   }
  // }

  leaveLobby() {
    this.scene.getScene("UI").reset();
    this.soundScene.fadeOutMusic();

    if (this.room) {
      this.disconnectFromRoom();
    }

    // this.scene.getScene("selection").scene.transition({
    //   target: "menu",
    //   duration: 500,
    //   remove: true,
    //   moveBelow: true,
    // });
    // this.dispatch({ type: "change_view", value: "mainMenu" });
    this.scene.remove("particles");
    this.scene.remove("world");

    setTimeout(() => {
      this.soundScene.stopCurrentMusicTrack();
      this.soundScene.fadeInMusic();
      this.soundScene.playMusicTrack("track1", { loop: true });
    }, 400);
  }

  logout() {
    this.soundScene.fadeOutMusic();

    window.localStorage.removeItem("token");
    window.localStorage.removeItem("axies");
    window.localStorage.removeItem("favorite");
    this.scene.getScene("menu").scene.transition({
      target: "default",
      duration: 750,
      // sleep: true,
      moveBelow: true,
    });
    this.dispatch({ type: "change_view", value: "login" });
    this.dispatch({ type: "login", value: false });
    // this.changeLogin(false);
    setTimeout(() => {
      this.soundScene.stopCurrentMusicTrack();
      this.soundScene.fadeInMusic();
    }, 400);
  }

  exitToMenu() {
    this.soundScene.fadeOutMusic();

    this.disconnectFromRoom();
    this.scene.getScene("matchResults").scene.transition({
      target: "menu",
      duration: 500,
    });
    this.dispatch({ type: "change_view", value: "mainMenu" });

    setTimeout(() => {
      this.soundScene.stopCurrentMusicTrack();
      this.soundScene.fadeInMusic();
      this.soundScene.playMusicTrack("track1", { loop: true });
    }, 400);
  }

  joinGame(axieId, isStarter) {
    this.addScenes();
    setTimeout(() => {
      this.events.emit("join-game", axieId, isStarter);
    }, 0);
  }

  showProfile() {
    this.soundScene.fadeOutMusic();
    this.scene.getScene("menu").scene.transition({
      target: "profile",
      duration: 500,
      // sleep: true,
      // moveBelow: true,
    });
    this.dispatch({ type: "change_view", value: "profile" });

    setTimeout(() => {
      this.soundScene.stopCurrentMusicTrack();
      this.soundScene.fadeInMusic();
    }, 500);
  }

  hideProfile() {
    this.scene.getScene("profile").scene.transition({
      target: "menu",
      duration: 500,
      // sleep: true,
      // moveBelow: true,
    });
    this.dispatch({ type: "change_view", value: "mainMenu" });
    setTimeout(() => {
      this.soundScene.playMusicTrack("track1", { loop: true });
    }, 400);
  }

  showSettings() {
    this.soundScene.fadeOutMusic();
    this.scene.getScene("menu").scene.transition({
      target: "settings",
      duration: 500,
      // sleep: true,
      // moveBelow: true,
    });
    this.dispatch({ type: "change_view", value: "settings" });

    setTimeout(() => {
      this.soundScene.stopCurrentMusicTrack();
      this.soundScene.fadeInMusic();
    }, 500);
  }

  hideSettings() {
    this.scene.getScene("settings").scene.transition({
      target: "menu",
      duration: 500,
      // sleep: true,
      // moveBelow: true,
    });
    this.dispatch({ type: "change_view", value: "mainMenu" });
    setTimeout(() => {
      this.soundScene.playMusicTrack("track1", { loop: true });
    }, 400);
  }

  async saveSettings(newSettings) {
    const gameWrapper = document.querySelector(".game-wrapper");
    gameWrapper.className = `game-wrapper size-${newSettings.graphics.screenSize}`;
    window.localStorage.setItem("size", newSettings.graphics.screenSize);

    this.settings = newSettings;
    this.scene.getScene("sound").adjustSettings(this.settings.sound.volume);
  }

  addWorldListeners() {
    const world = this.scene.getScene("world");
    const UI = this.scene.getScene("UI");
    const selection = this.scene.getScene("selection");

    const particles = this.scene.getScene("particles");

    world.events.on("action", (data) => {
      this.room.send("action", data);
    });

    world.events.on("start-game", (time) => {
      // this.events.emit("play-fx", SOUND_FX.SIREN_LOOP);
      // UI.start(time, 5);
    });

    world.events.on("clear-block", (data) => {
      particles.showTrail(data);
    });

    world.events.on("cast", (data) => {
      this.room.send("action", { type: "cast", data });
    });

    world.events.on("show-effect", (data) => {
      particles.castEffect(data);
    });

    world.events.on("game-over", (data) => {
      particles.showGameOver(data);
      if (data.isMe) {
        this.scene.bringToTop("UI");
        UI.showContinue();
      }
    });

    world.events.on("winner", (data) => {
      particles.showWinner(data);
      this.soundScene.stopCurrentMusicTrack();
      this.soundScene.playMusicTrack("winner");
      this.scene.bringToTop("UI");
      UI.showContinue();
    });

    // countdown.events.on("countdown-done", () => {
    // this.scene.sleep("countdown");
    // });

    // selection.events.on("axie-selection", (data) => {
    //   this.room.send("axie-selection", data);
    // });
  }

  reset() {
    // const selection = this.scene.getScene("selection");
    this.scene.remove("particles");
    // selection.reset();
  }

  changeLogin(status) {
    this.loggedIn = status;

    if (status === true && this.scene.isActive("default")) {
      const preload = this.scene.getScene("default");
      if (preload.loadComplete) {
        preload.events.emit("user-login");
      }
    }
  }

  playSound(name, config) {
    this.events.emit("play-fx", name, config);
  }
}

export function createGame(parent, client, dispatch) {
  const game = new BlitzGame(
    Object.assign({ parent: parent }, config),
    dispatch
  );

  game.events.on("join-game", async (axieId, isStarter) => {
    try {
      const room = await client.joinOrCreate("blitz_game", {
        entry: 50,
        // fill: game.settings.game.fill ? "fill" : "no-fill",
        fill: "no-fill",
        token: window.localStorage.getItem("token"),
      });

      game.dispatch({ type: "reset_game" });
      room.send("axie-selection", { axieId, isStarter });
      game.connectToRoom(room);
    } catch (e) {
      if (e.code === 401) {
        alert(e.message);
      }
      console.error("joinOrCreate error", e.message, e.code);

      game.leaveLobby();
      game.dispatch({
        type: "change_view",
        value: "mainMenu",
      });
    }
  });

  game.events.on("reconnect", async (data) => {
    try {
      const reconnecRoom = await client.reconnect(game.roomId, game.sessionId);
      console.log("reconnect", reconnecRoom);
    } catch (e) {
      console.log("error reconnect", e);
    }
  });

  return game;

  // game.events.on();
}
