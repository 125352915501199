import Phaser from "phaser";
import { Button } from "../../components/Button";
import { SettingsSideNav } from "./SettingsSideNav";
import { GameSettings } from "./game/GameSettings";
import { SoundSettings } from "./sound/SoundSettings";
import { GraphicsSettings } from "./graphics/GraphicsSettings";

export class Settings extends Phaser.Scene {
  // constructor(config = {}) {
  //   super({ key: "menu", ...config });
  // }

  create() {
    const midX = this.cameras.main.width / 2;
    const midY = this.cameras.main.height / 2;

    this.isDirty = false;

    this.add.rectangle(
      midX,
      midY,
      this.cameras.main.width,
      this.cameras.main.height,
      0xbbac93
    );

    this.fadeMask = this.add.rectangle(
      midX,
      midY,
      this.cameras.main.width,
      this.cameras.main.height,
      0x000000
    );

    this.fadeMask.depth = 10;

    this.tempSettings = {};
    this.buildMenu();

    this.events.on("transitionstart", (from, duration) => {
      this.isDirty = false;
      this.tempSettings = JSON.parse(JSON.stringify(this.game.settings));
      this.sideNav.select();
      this.fadeMask.alpha = 1;

      this.tweens.add({
        targets: this.fadeMask,
        alpha: 0,
        delay: duration,
        duration: duration, // * 0.75,
        onStart: () => {
          // this.fadeMask.visible = true;
        },
        onComplete: () => {
          // this.fadeMask.visible = false;
        },
      });
    });

    this.events.on("transitionout", (target, duration) => {
      this.reset();
      this.fadeMask.alpha = 0;
      this.tweens.add({
        targets: this.fadeMask,
        alpha: 1,
        duration: duration,
        onStart: () => {
          // this.fadeMask.visible = true;
          this.fadeMask.alpha = 0;
          // this.events.removeAllListeners();
        },
      });
    });

    this.events.on("show-option", (option) => {
      this.showView(option);
    });

    this.events.on("setting-change", (change) => {
      console.log(this.tempSettings[change.category]);
      if (!this.tempSettings[change.category]) {
        return;
      }

      const fields = change.field.split(".");
      console.log(this.tempSettings[change.category][fields[0]], change.value);
      if (fields.length === 1) {
        this.tempSettings[change.category][fields[0]] = change.value;
      } else {
        this.tempSettings[change.category][fields[0]][fields[1]] = change.value;
      }

      this.checkChange();
    });

    this.views = {
      game: new GameSettings(this, midX, 0),
      graphics: new GraphicsSettings(this, midX, 0),
      sound: new SoundSettings(this, midX, 0),
    };
  }

  buildMenu() {
    this.sideNav = new SettingsSideNav(this);
  }

  showView(option) {
    if (this.activeView) {
      this.views[this.activeView].hide();
    }

    if (option === "back") {
      this.events.emit("settings-exit");
    } else if (option === "save") {
      this.events.emit("settings-save", this.tempSettings);
    } else {
      this.activeView = option;
      this.views[option].show(this.tempSettings[option]);
    }
  }

  checkChange() {
    if (this.isDirty) {
      return;
    }

    // compare temp and game settings
    if (
      JSON.stringify(this.tempSettings) !== JSON.stringify(this.game.settings)
    ) {
      this.isDirty = true;
      console.log("temp", this.tempSettings);
      this.sideNav.showSave();
    }
  }

  reset() {
    this.input.removeAllListeners();
    this.events.off("show-option");
    this.events.off("setting-change");
  }
}
