import { CSSTransition } from "react-transition-group";
import Box from "../../components/Box";
import AxieSelector from "./AxieSeletor";
import { TRANSITIONS } from "../../../../../constants";
import styles from "../../ui.module.scss";
import gameSelectionStyles from "./gameSelection.module.scss";
import { useState, useReducer, useEffect } from "react";
import { SOUND_FX } from "../../../gameSrc/sound/Sound";
import SelectionBox from "./SelectionBox";
import { getRandomInt } from "../../../gameSrc/utils";

const initState = {
  address: "",
  name: "",
};

const reducer = (state, action) => {
  let newState = { ...state };

  switch (action.type) {
    case "setProfile":
      newState = { ...action.data };
      break;

    case "password":
      newState.password = action.value;
      break;

    default:
      break;
  }

  return newState;
};

const GameSelection = ({ show, playSound, changeView, game }) => {
  const [selection, selectAxie] = useState();
  const [isStarter, selectStarter] = useState(false);
  const [starterAxies, setStarterAxies] = useState();
  const [playerAxies, setAxies] = useState();
  const [showSelectionBox, toggleSelectionBox] = useState(false);
  const [display, setDisplay] = useState(false);
  const [joining, setJoin] = useState(false);
  const [randomSelection, selectRandom] = useState(null);

  useEffect(() => {
    if (!show) {
      return;
    }
    selectRandom(null);
    setJoin(false);
    selectStarter(false);

    const axies = JSON.parse(window.localStorage.getItem("axies"));
    if (axies) {
      setAxies(axies);
    }

    const starters = JSON.parse(window.localStorage.getItem("starters"));
    if (starters) {
      setStarterAxies(starters);
    }
  }, [show]);

  useEffect(() => {
    if (!playerAxies) {
      return;
    }

    const favorite = parseInt(window.localStorage.getItem("favorite"));
    selectAxie(null);
    if (favorite) {
      const favoriteAxie = playerAxies.find(
        (data) => data.axie.axie_id === favorite
      );

      if (favoriteAxie) {
        selectAxie(favoriteAxie.axie);
      }
    }
  }, [playerAxies]);

  const handleJoin = (type) => {
    setJoin(true);

    if (!selection) {
      console.log("need to pick random");

      let randomId;
      let randomStarter;
      if (playerAxies && playerAxies.length) {
        // pick one from the player's axies
        const randomIndex = getRandomInt(0, playerAxies.length - 1);
        selectRandom(playerAxies[randomIndex].axie);

        randomId = playerAxies[randomIndex].axie.axie_id;
        randomStarter = false;
      } else {
        // pick one of the starters
        const randomIndex = getRandomInt(0, starterAxies.length - 1);
        selectRandom(starterAxies[randomIndex].axie);
        randomId = starterAxies[randomIndex].axie.axie_id;
        randomStarter = true;
      }

      playSound(SOUND_FX.MATCH_FULL, { volume: 0.2 });

      setTimeout(() => {
        game.current.joinGame(randomId, randomStarter);
        changeView("gameLobby");
      }, 1500);
    } else {
      game.current.joinGame(selection.axie_id, isStarter);
      changeView("gameLobby");
    }
  };

  return (
    <CSSTransition
      in={show}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      onEnter={() => {
        setDisplay(true);
      }}
      onExit={() => {
        setDisplay(false);
      }}
      unmountOnExit
    >
      <div className={styles.screen} style={{ background: "rgba(0,0,0,0.8)" }}>
        <button
          className={gameSelectionStyles.back}
          onClick={() => {
            playSound(SOUND_FX.CLICK, { volume: 0.25 });
            changeView("mainMenu");
          }}
          onMouseEnter={() => {
            playSound(SOUND_FX.HOVER, { volume: 0.25 });
          }}
          disabled={joining}
        >
          <img src="images/ui/arrow.png" alt="logout" />
        </button>
        <div className={[gameSelectionStyles.title, styles.blitz].join(" ")}>
          Choose your game
        </div>

        <CSSTransition
          in={display}
          timeout={TRANSITIONS.fade + 400}
          classNames="tr-fade-left-bounce-fixed"
          unmountOnExit
        >
          <Box
            className={[gameSelectionStyles.box].join(" ").trim()}
            style={{ left: 155 }}
            onClick={
              joining
                ? null
                : () => {
                    playSound(SOUND_FX.CLICK, { volume: 0.25 });
                    // changeView("mainMenu");
                    handleJoin("normal");
                  }
            }
            onMouseEnter={() => {
              playSound(SOUND_FX.HOVER, { volume: 0.25 });
            }}
            disabled={joining}
          >
            <img src="./images/game-normal.png" alt="normal game selection" />
            <div
              className={[styles.blitz, gameSelectionStyles.gameLabel].join(
                " "
              )}
            >
              normal
            </div>
          </Box>
        </CSSTransition>
        <CSSTransition
          in={display}
          timeout={TRANSITIONS.fade + 400}
          classNames="tr-fade-right-bounce-fixed"
          unmountOnExit
        >
          <Box
            className={[gameSelectionStyles.box, gameSelectionStyles.disabled]
              .join(" ")
              .trim()}
            style={{ right: 155 }}
          >
            <img src="./images/game-ranked.png" alt="ranked game selection" />
            <div
              className={[styles.blitz, gameSelectionStyles.gameLabel].join(
                " "
              )}
            >
              ranked
            </div>
            <div className={gameSelectionStyles.disabledOverlay}>
              <img src="./images/ui/lock.png" />
            </div>
          </Box>
        </CSSTransition>
        <CSSTransition
          in={display}
          timeout={TRANSITIONS.fade + 400}
          classNames="tr-fade-up-fixed"
          unmountOnExit
        >
          <div className={gameSelectionStyles.selectionWrapper}>
            <AxieSelector
              randomSelection={randomSelection}
              selectedAxie={selection}
              playSound={playSound}
              onClick={() => {
                toggleSelectionBox(true);
              }}
              disabled={joining}
            />
          </div>
        </CSSTransition>
        <SelectionBox
          show={showSelectionBox}
          starters={starterAxies}
          axies={playerAxies}
          playSound={playSound}
          onSelection={(axieId, isStarter) => {
            if (selection && selection.axie_id === axieId) {
              selectAxie(null);
            } else {
              if (isStarter) {
                selectStarter(true);
                selectAxie(
                  starterAxies.find((data) => data.axie.axie_id === axieId).axie
                );
              } else {
                selectStarter(false);
                selectAxie(
                  playerAxies.find((data) => data.axie.axie_id === axieId).axie
                );
              }
            }

            toggleSelectionBox(false);
          }}
          selectedAxie={selection}
          onClose={() => {
            playSound(SOUND_FX.CLICK, { volume: 0.25 });
            toggleSelectionBox(false);
          }}
        />
      </div>
    </CSSTransition>
  );
};

export default GameSelection;
