import { CSSTransition } from "react-transition-group";
import { TRANSITIONS } from "../../../../../../constants";
import ReactSlider from "react-slider";

import settingStyles from "../settings.module.scss";

const SoundOptions = ({ show, onChange, values }) => {
  return (
    <CSSTransition
      in={show}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      unmountOnExit
    >
      <div className={settingStyles.optionsContainer}>
        <div
          className={settingStyles.optionLabelContainer}
          style={{ maxWidth: 300, marginBottom: 60, position: "relative" }}
        >
          General Volume
          <ReactSlider
            className={settingStyles.slider}
            defaultValue={values.volume.general * 100}
            renderThumb={(props) => {
              return (
                <div {...props}>
                  <img src="images/ui/thumb-small.png" />
                </div>
              );
            }}
            renderTrack={(props, state) => {
              const { className, ...rest } = props;
              return (
                <div
                  className={[
                    className,
                    settingStyles.track,
                    state.index === 0
                      ? settingStyles.track0
                      : settingStyles.track1,
                  ]
                    .join(" ")
                    .trim()}
                  {...rest}
                ></div>
              );
            }}
            onChange={(val) => {
              onChange("sound", { general: val / 100 });
            }}
          />
          <div className={settingStyles.sliderValue}>
            {parseInt(values.volume.general * 100, 10)}
          </div>
        </div>
        <div
          className={settingStyles.optionLabelContainer}
          style={{ maxWidth: 300, marginBottom: 60, position: "relative" }}
        >
          Music Volume
          <ReactSlider
            defaultValue={values.volume.music * 100}
            renderThumb={(props) => {
              return (
                <div {...props}>
                  <img src="images/ui/thumb-small.png" />
                </div>
              );
            }}
            renderTrack={(props, state) => {
              const { className, ...rest } = props;
              return (
                <div
                  className={[
                    className,
                    settingStyles.track,
                    state.index === 0
                      ? settingStyles.track0
                      : settingStyles.track1,
                  ]
                    .join(" ")
                    .trim()}
                  {...rest}
                ></div>
              );
            }}
            onChange={(val) => {
              onChange("sound", { music: val / 100 });
            }}
          />
          <div className={settingStyles.sliderValue}>
            {parseInt(values.volume.music * 100, 10)}
          </div>
        </div>
        <div
          className={settingStyles.optionLabelContainer}
          style={{ maxWidth: 300, position: "relative" }}
        >
          FX Volume
          <ReactSlider
            defaultValue={values.volume.fx * 100}
            renderThumb={(props) => {
              return (
                <div {...props}>
                  <img src="images/ui/thumb-small.png" />
                </div>
              );
            }}
            renderTrack={(props, state) => {
              const { className, ...rest } = props;
              return (
                <div
                  className={[
                    className,
                    settingStyles.track,
                    state.index === 0
                      ? settingStyles.track0
                      : settingStyles.track1,
                  ]
                    .join(" ")
                    .trim()}
                  {...rest}
                ></div>
              );
            }}
            onChange={(val) => {
              onChange("sound", { fx: val / 100 });
            }}
          />
          <div className={settingStyles.sliderValue}>
            {parseInt(values.volume.fx * 100, 10)}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default SoundOptions;
