import { CSSTransition } from "react-transition-group";
import Box from "../../components/Box";
import MenuButton from "../../components/Form/MenuButton";
import AxieStatsModal from "../../components/AxieStatsModal";
import styles from "../../ui.module.scss";
import boxStyles from "./selectionbox.module.scss";
import { SOUND_FX } from "../../../gameSrc/sound/Sound";
import AxieList from "../../components/AxieList";
import { TRANSITIONS } from "../../../../../constants";
import { useEffect, useState } from "react";

const SelectionBox = ({
  show,
  axies,
  starters,
  onSelection,
  onClose,
  selectedAxie,
  playSound,
}) => {
  const [viewSelected, setViewAxie] = useState(null);
  const [detailsId, viewDetailsFor] = useState(null);
  const [isStarter, setStarter] = useState(false);

  useEffect(() => {
    if (selectedAxie) {
      setViewAxie(selectedAxie.axie_id);
    }
  }, [selectedAxie]);

  return (
    <CSSTransition
      in={show}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      unmountOnExit
      onExited={() => {
        setViewAxie(selectedAxie ? selectedAxie.axie_id : null);
      }}
    >
      <Box className={boxStyles.container}>
        <div>
          <div
            className={styles.blitz}
            style={{ textAlign: "center", fontSize: 32 }}
          >
            Starter axies
          </div>
          <AxieList
            axies={starters}
            onClick={(axieId) => {
              setStarter(true);
              setViewAxie(axieId);
              viewDetailsFor(axieId);
              // onSelection(axieId, true);
            }}
            selection={viewSelected}
            itemsPerRow={9}
            className={`${styles.hideScrollbar}`}
            noStroke
          />
        </div>
        <div className={styles.separator} style={{ marginBottom: 20 }}></div>
        <div className={boxStyles.playerAxies}>
          <div
            className={styles.blitz}
            style={{ textAlign: "center", fontSize: 32 }}
          >
            Your axies
          </div>
          {axies && axies.length ? (
            <AxieList
              axies={axies}
              selection={viewSelected}
              onClick={(axieId) => {
                setStarter(false);
                setViewAxie(axieId);
                viewDetailsFor(axieId);
              }}
              itemsPerRow={6}
              style={{ flex: 1 }}
              className={styles.hideScrollbar}
              noStroke
            />
          ) : (
            <div className={boxStyles.emptyMsg}>
              <p>
                You have no axies. Connect a Ronin Wallet with Axies in it or
                get your own in the Axie Infinity{" "}
                <a
                  href="https://app.axieinfinity.com/marketplace/"
                  target="_blank"
                >
                  marketplace!
                </a>
              </p>
              <p>
                You can still play normal games with one of our starter axies.
              </p>
            </div>
          )}
        </div>

        <AxieStatsModal
          axieId={detailsId}
          className={boxStyles.details}
          onClose={() => {
            viewDetailsFor();
            playSound(SOUND_FX.CLICK, { volume: 0.25 });
          }}
          showArrows={isStarter || (!isStarter && axies && axies.length)}
          nextFn={() => {
            const navAxies = isStarter ? starters : axies;
            let currentIndex = navAxies.findIndex(
              (data) => data.axie.axie_id === detailsId
            );
            currentIndex = navAxies[currentIndex + 1] ? currentIndex + 1 : 0;
            setStarter(isStarter);
            setViewAxie(navAxies[currentIndex].axie.axie_id);
            viewDetailsFor(navAxies[currentIndex].axie.axie_id);
            playSound(SOUND_FX.CLICK, { volume: 0.25 });
          }}
          previousFn={() => {
            const navAxies = isStarter ? starters : axies;
            let currentIndex = navAxies.findIndex(
              (data) => data.axie.axie_id === detailsId
            );
            currentIndex = navAxies[currentIndex - 1]
              ? currentIndex - 1
              : navAxies.length - 1;
            setViewAxie(navAxies[currentIndex].axie.axie_id);
            viewDetailsFor(navAxies[currentIndex].axie.axie_id);
            playSound(SOUND_FX.CLICK, { volume: 0.25 });
          }}
        />
        <button className={boxStyles.close} onClick={onClose}>
          <svg
            style={{ fill: "currentcolor" }}
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.293 6.293a1 1 0 0 1 1.414 0L12 10.586l4.293-4.293a1 1 0 1 1 1.414 1.414L13.414 12l4.293 4.293a1 1 0 0 1-1.414 1.414L12 13.414l-4.293 4.293a1 1 0 0 1-1.414-1.414L10.586 12 6.293 7.707a1 1 0 0 1 0-1.414Z"
              fill="currentColor"
            ></path>
          </svg>
        </button>
        <MenuButton
          text={"select"}
          style={{ width: 240, alignSelf: "center" }}
          throttleMs={1000}
          onClick={() => {
            playSound(SOUND_FX.CLICK, { volume: 0.25 });
            if (viewSelected) {
              onSelection(viewSelected, isStarter);
            } else {
              onClose();
            }
          }}
          onMouseEnter={() => {
            playSound(SOUND_FX.HOVER, { volume: 0.25 });
          }}
          // disabled={disabled}
        />
      </Box>
    </CSSTransition>
  );
};

export default SelectionBox;
