import Phaser from "phaser";
import { OptionView } from "../../OptionView";
import { ToggleButton } from "../../../components/ToggleButton";
import { SOUND_FX } from "../../../sound/Sound";

export class GameSettings extends OptionView {
  constructor(scene, x, y, children) {
    super(scene, x, y, children);

    this.fillToggle = new ToggleButton(scene, -this.width / 2 + 20, 100, {
      text: "wait for game to fill up",
      width: 350,
    });

    this.fillToggle.onClick((value) => {
      this.scene.events.emit("setting-change", {
        category: "game",
        field: "fill",
        value,
      });
    });
    this.add(this.fillToggle);

    this.pingToggle = new ToggleButton(scene, -this.width / 2 + 20, 180, {
      text: "show ping in game",
      width: 330,
    });

    this.pingToggle.onClick((value) => {
      this.scene.events.emit("setting-change", {
        category: "game",
        field: "ping",
        value,
      });
    });
    this.add(this.pingToggle);
  }

  async show(settings) {
    if (this.visibilityTween) {
      this.visibilityTween.stop();
      this.visibilityTween.remove();
      this.visibilityTween = null;
    }

    this.visibilityTween = this.scene.tweens.add({
      targets: this,
      alpha: 1,
      duration: 333,
    });

    this.fillToggle.setState(settings.fill);
    this.pingToggle.setState(settings.ping);
  }

  hide() {
    if (this.visibilityTween) {
      this.visibilityTween.stop();
      this.visibilityTween.remove();
      this.visibilityTween = null;
    }

    this.visibilityTween = this.scene.tweens.add({
      targets: this,
      alpha: 0,
      duration: 333,
    });
  }
}
