import Phaser from "phaser";
import { Button } from "./components/Button";
import { SERVER_STATUS } from "./constants";
import { getUserProfile, getInfo } from "./helpers/api";

export class MenuScene extends Phaser.Scene {
  constructor(config) {
    super(config);
  }

  create() {
    const midX = this.cameras.main.width / 2;
    const midY = this.cameras.main.height / 2;

    const bg = this.make.image({
      key: "main-image",
      x: midX,
      y: midY,
      add: true,
    });

    bg.setDisplaySize(this.cameras.main.width, this.cameras.main.height);

    // this.make.image({
    //   key: "logo",
    //   x: 140,
    //   y: 120,
    //   scale: {
    //     x: 0.75,
    //     y: 0.75,
    //   },
    //   add: true,
    // });

    // setTimeout(() => {
    // this.createMenu();
    // }, 100);

    this.fadeMask = this.add.rectangle(
      midX,
      midY,
      this.cameras.main.width,
      this.cameras.main.height,
      0x000000
    );
    // this.fadeMask.setInteractive();
    this.fadeMask.depth = 10;
    // this.fadeMask.visible = false;
    // this.fadeMask.alpha = 0;

    this.events.on("transitionout", (target, duration) => {
      this.tweens.add({
        targets: this.fadeMask,
        alpha: 1,
        duration: duration,
        onStart: () => {
          // this.fadeMask.visible = true;
          this.fadeMask.alpha = 0;
        },
      });
    });

    this.events.on("transitionstart", (from, duration) => {
      // this.checkUser();
      this.tweens.add({
        targets: this.fadeMask,
        alpha: 0,
        // delay: duration * 2,
        duration: duration * 1.25, // * 0.75,
        onStart: () => {
          // this.fadeMask.visible = true;
          this.fadeMask.alpha = 1;
        },
      });
    });

    this.nameTxt = this.make.text({
      x: 50,
      y: 700,
      text: "",
      style: {
        fontFamily: "Secular One",
        fontSize: "12px",
        // stroke: "#333333",
        // strokeThickness: 1,
        fill: "#000000",
        fixedWidth: 300,
      },
      origin: { x: 0, y: 0 },
      add: true,
    });

    this.showVersion(this.game.information);
  }

  // createMenu() {
  //   const midX = this.cameras.main.width / 2;
  //   const midY = this.cameras.main.height / 2;

  //   const menuBox = this.make.image({
  //     key: "menu-selection",
  //     x: 0,
  //     y: 0 - 280,
  //     scale: {
  //       x: 0.2,
  //       y: 0.2,
  //     },
  //     add: true,
  //   });
  //   menuBox.setOrigin(0.5, 0);

  //   const joinBtn = new Button(this, 0, -165, {
  //     text: "JOIN GAME",
  //   });

  //   const profileBtn = new Button(this, 0, -55, {
  //     text: "PROFILE",
  //   });

  //   const settingsBtn = new Button(this, 0, 55, {
  //     text: "SETTINGS",
  //   });

  //   const logoutBtn = new Button(this, 0, 165, {
  //     text: "LOGOUT",
  //   });

  //   const menu = this.add.container(midX, midY, [
  //     menuBox,
  //     joinBtn,
  //     profileBtn,
  //     settingsBtn,
  //     logoutBtn,
  //   ]);
  //   menu.alpha = 0;

  //   this.tweens.add({
  //     targets: menu,
  //     alpha: 1,
  //     duration: 250,
  //   });

  //   joinBtn.toggle(false);
  //   joinBtn.onClick(() => {
  //     this.events.emit("menu-join");
  //   });

  //   this.joinBtn = joinBtn;

  //   profileBtn.onClick(() => {
  //     this.events.emit("menu-profile");
  //   });
  //   settingsBtn.onClick(() => {
  //     this.events.emit("menu-settings");
  //   });
  //   logoutBtn.onClick(() => {
  //     this.events.emit("logout");
  //   });
  // }

  // async checkUser() {
  //   const data = await getUserProfile();
  //   const axies = JSON.parse(window.localStorage.getItem("axies"));
  //   this.joinBtn.toggle(
  //     axies.length > 0 && this.game.information.gameStatus === SERVER_STATUS.OK
  //   );

  //   this.nameTxt.setText(data.name);
  // }

  async showVersion(info) {
    const { version, data } = await getInfo();

    this.add
      .text(1150, 700, `ver: ${version} | data: ${data}`, {
        fontFamily: "Secular One",
        fontSize: "12px",
        // stroke: "#333333",
        // strokeThickness: 1,
        fill: "#000000",
      })
      .setOrigin(0.5, 0);
  }
}
