import Phaser from "phaser";
import { getRandomInt } from "./utils";
import { SOUND_FX } from "./sound/Sound";
import { Axie } from "./Axie";

const MAX_LIFE_SPAN = 2350;

export class AxieSpot extends Phaser.GameObjects.Container {
  constructor(scene, x, y, children) {
    super(scene, x, y, children);

    scene.add.existing(this);

    this.outerCircle = scene.add.circle(0, 0, 148, 0xeeeeee, 0);
    this.innerCircle = scene.add.circle(0, 0, 140, 0xe5ad88, 0);
    this.maskCircle = scene.add.circle(0, 0, 0, 0x333333);

    this.ellipseScale = 0.4;

    this.circleContainer = scene.add.container(0, 0);
    this.add(this.circleContainer);
    this.circleContainer.add([
      this.outerCircle,
      this.innerCircle,
      this.maskCircle,
    ]);

    this.axie = new Axie({ scene });

    this.interactionArea = new Phaser.Geom.Circle(0, -40, 0);
    this.setInteractive(this.interactionArea, Phaser.Geom.Circle.Contains);
  }

  toggleHover(toggle) {
    this.interactionArea.radius = toggle ? 50 : 0;
  }

  searching() {
    const path = new Phaser.Curves.Ellipse(0, 0, 150, 150, 50, 48, false);

    const particles = this.scene.add.particles("flares");

    this.emitter = particles.createEmitter({
      frame: { frames: ["yellow", "green"], cycle: true },
      scale: { start: 0.35, end: 0.2 },
      blendMode: "ADD",
      emitZone: { type: "edge", source: path, quantity: 140, yoyo: false },
      lifespan: 0,
      timeScale: 1,
    });
    this.circleContainer.add(particles);
    // window.t = this.emitter;

    this.text = this.scene.make.text({
      x: 0,
      y: 0,
      text: "searching for players...",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "24px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#f2d9b6",
        wordWrap: {
          width: 150,
        },
        fixedWidth: 150,
        align: "center",
      },
    });
    this.text.setOrigin(0.5);
    // window.r = this.text;

    this.circleContainer.add(this.text);
  }

  updateBar(percentage) {
    if (this.updateBarTween) {
      this.updateBarTween.stop();
      this.updateBarTween.remove();
    }

    let barLifespan = { val: this.emitter.lifespan.propertyValue };

    this.updateBarTween = this.scene.tweens.add({
      targets: barLifespan,
      val: MAX_LIFE_SPAN * percentage,
      duration: 500,
      onUpdate: (tween, target) => {
        this.emitter.setLifespan(target.val);
        // console.log(target.val, target, percentage, MAX_LIFE_SPAN);
      },
    });
    // this.emitter.setLifespan(MAX_LIFE_SPAN * percentage);
  }

  found(x, y, xScale, yScale) {
    const timeline0 = this.scene.tweens.createTimeline();
    const timeline1 = this.scene.tweens.createTimeline();
    const timeline2 = this.scene.tweens.createTimeline();

    this.scene.tweens.add({
      targets: this.text,
      alpha: 0,
      duration: 300,
      y: this.circleContainer.y - 10,
    });

    timeline0.add({
      targets: this,
      y: this.y - 10,
      duration: 250,
    });
    timeline0.add({
      targets: this,
      x: x,
      y: y,
      duration: 700,
    });

    timeline1.add({
      targets: this.circleContainer,
      scale: 1.2,
      duration: 250,
    });
    timeline1.add({
      targets: this.circleContainer,
      scaleY: yScale,
      scaleX: xScale,
      duration: 700,
    });

    let barAlpha = { val: 1 };

    timeline2.add({
      targets: barAlpha,
      val: 0,
      duration: 300,
      onUpdate: (tween, target) => {
        this.emitter.setAlpha(target.val);
      },
      onComplete: () => {
        this.emitter.setTint(0x33ff99);
      },
    });
    timeline2.add({
      targets: barAlpha,
      val: 1,
      duration: 100,
      onUpdate: (tween, target) => {
        this.emitter.setAlpha(target.val);
      },
    });

    // let barScaleStart = { val: 0.35 };
    // timeline2.add({
    //   targets: barScaleStart,
    //   val: 0.35,
    //   delay: 500,
    //   duration: 500,
    //   onUpdate: (tween, target) => {
    //     this.emitter.setScale({ start: barScaleStart.val, end: 0.2 });
    //   },
    // });

    timeline2.add({
      targets: barAlpha,
      val: 0,
      delay: 1000,
      duration: 200,
      onUpdate: (tween, target) => {
        this.emitter.setAlpha(target.val);
      },
      onComplete: () => {
        this.emitter.setTint(0x333333);
      },
    });
    timeline2.add({
      targets: barAlpha,
      val: 1,
      duration: 200,
      onUpdate: (tween, target) => {
        this.emitter.setAlpha(target.val);
      },
    });
    timeline2.add({
      targets: [this.outerCircle, this.innerCircle],
      fillAlpha: 0.1,
      duration: 500,
      onStart: (tween, targets) => {
        // targets.forEach((t) => t.vi);
      },
    });

    timeline0.play();
    timeline1.play();
    timeline2.play();

    // const up = this.scene.add.tween();
  }

  lockIn() {
    const timeline = this.scene.tweens.createTimeline();

    let barAlpha = { val: 1 };

    timeline.add({
      delay: 700,
      targets: barAlpha,
      val: 0,
      duration: 250,
      onUpdate: (tween, target) => {
        this.emitter.setAlpha(target.val);
      },
      onComplete: () => {
        this.emitter.setTint(0x33ff99);
      },
    });
    timeline.add({
      targets: barAlpha,
      val: 1,
      duration: 250,
      onUpdate: (tween, target) => {
        this.emitter.setAlpha(target.val);
      },
    });

    timeline.play();
  }

  update() {
    if (this.axie) {
      this.axie.tick();
    }
  }

  showWaiting() {
    // this.scaleY = 0.4;
    this.scene.tweens.add({
      targets: [this.outerCircle, this.innerCircle],
      fillAlpha: 0.1,
      duration: 500,
      delay: 1800,
    });
  }

  focus() {
    if (this.focusTimeline) {
      return;
    }

    this.focusTimeline = this.scene.tweens.createTimeline();
    this.focusTimeline.add({
      targets: [this.outerCircle, this.innerCircle],
      fillAlpha: 1,
      duration: 500,
      ease: "Bounce.easeInOut",
    });
    this.focusTimeline.add({
      targets: [this.outerCircle, this.innerCircle],
      fillAlpha: 0.5,
      duration: 500,
      yoyo: true,
      repeat: -1,
    });

    this.focusTimeline.play();
  }

  async showAxie(data, axieScale) {
    if (this.focusTimeline) {
      this.focusTimeline.stop();
      this.focusTimeline.destroy();
    } else {
      this.focus();
    }

    await this.axie.load(data.axieId);
    // await timer(50);
    // console.log("shoqaxie", data.axieId);
    // console.log(this.axie.canvas.scale, axieScale);
    if (this.isRight) {
      this.axie.canvas.flipX = true;
    }
    this.axie.canvas.setScale(this.axie.canvas.scale * axieScale);
    // console.log(
    //   "this.axie.canvas.setScale",
    //   this.axie.canvas.scale * axieScale
    // );
    // this.add(this.axie.canvas);
    this.axie.setPosition(
      -this.axie.canvas.displayWidth / 2,
      -this.axie.canvas.displayHeight / 2 -
        (this.axie.canvas.displayHeight / 2) * this.axie.canvas.scaleY
    );

    this.axie.canvas.alpha = 0;

    this.add(this.axie.canvas);

    const popup = this.scene.tweens.createTimeline();
    popup
      .add({
        targets: [this.outerCircle, this.innerCircle],
        fillAlpha: 1,
        duration: 250,
      })
      .add({
        targets: [this.maskCircle],
        radius: 100,
        duration: 200,
      })
      .add({
        targets: [this.axie.canvas],
        y: this.axie.canvas.y - 40 * this.rowScale,
        alpha: 1,
        duration: 200,
        onStart: () => {
          this.scene.game.events.emit(
            "play-fx",
            [SOUND_FX.JUMP1, SOUND_FX.JUMP2, SOUND_FX.JUMP3, SOUND_FX.JUMP4][
              getRandomInt(0, 3)
            ]
          );
        },
      })
      .add({
        targets: [this.maskCircle],
        radius: 0,
        duration: 200,
      });

    popup.play();

    // 40 * this.rowScale
    // this.scene.tweens.add({
    //   targets: [this.outerCircle, this.innerCircle],
    //   fillAlpha: 1,
    //   duration: 250,
    // });
    // this.scene.tweens.add({
    //   targets: [this.axie.canvas],
    //   alpha: 1,
    //   duration: 200,
    // });

    // this.scene.tweens.add({
    //   targets: [this.maskCircle],
    //   radius: 100,
    //   yoyo: true,
    //   duration: 300,
    // });

    this.axie.reactTo("axie-selection", 500);

    // this.axie.setOrigin(0.5, 0.5);

    // this.axie.setPosition(-75, -75);
    // this.add(this.axie.canvas);
  }

  bringToFront(x, y, scale) {
    this.focusTimeline.stop();
    this.focusTimeline.destroy();

    this.emitter.setTint(0xffffff);

    this.depth = 2;
    this.outerCircle.depth = 2;
    this.innerCircle.depth = 2;
    this.maskCircle.depth = 2;

    this.scene.tweens.add({
      targets: [this.outerCircle, this.innerCircle],
      fillAlpha: 1,
      duration: 250,
    });

    const timeline = this.scene.tweens.createTimeline();

    timeline.add({
      targets: [this],
      x: x,
      y: y,
      scale: scale,
      // scaleX: this.scaleX * scale,
      // scaleY: this.scaleY * scale,
      duration: 500,
    });

    // this.scene.tweens.add({
    //   targets: [this.circleContainer],
    //   scaleX: this.circleContainer.scaleX * scale,
    //   scaleY: this.circleContainer.scaleY * scale,
    //   duration: 500,
    // });

    timeline.play();
  }

  move(x, y, scale, flip = false) {
    this.scene.tweens.add({
      targets: this,
      x,
      y,
      scale,
      duration: 500,
    });

    if (this.axie.canvas) {
      if (this.isRight) {
        this.axie.canvas.flipX = true;
      }
    }

    // if (this.axie.canvas) {
    //   this.axie.canvas.x -=
    //     (this.axie.canvas.width * 1.1 - this.axie.canvas.width) / 2;
    //   this.axie.canvas.scale = 1.1;
    // }
  }

  // updateMana(type, value) {
  //   this.manaBoxes[type.substring(0, type.indexOf("Mana"))].text.setText(value);
  // }
}
