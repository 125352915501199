import React, { useEffect, useRef, useState } from "react";
import { useColyseusClient } from "../../helpers/UseColyseusClient";
import GameNav from "./Nav";
import GameRoom from "./Room";
// import styles from "./AppBar.module.scss";

export const GAME_STATE = {
  LOBBY: "lobby",
  SEARCHING: "searching",
  IN_ROOM: "inRoom",
  WAITING: "waiting",
  SELECTING: "selecting",
  STARTING: "starting",
  PLAYING: "playing",
};

const BlitzGame = () => {
  // const client = useColyseusClient();
  // const [gameState, setGameState] = useState(GAME_STATE.LOBBY);
  // const [roomId, setRoomId] = useState();

  // async function joinRoom() {
  //   setGameState(GAME_STATE.SEARCHING);

  //   try {
  //     const room = await client.joinOrCreate("blitz_game", { entry: 20 });

  //     console.log("ok join", room);
  //     setGameState(GAME_STATE.IN_ROOM);
  //     setRoomId(room.id);
  //   } catch (e) {
  //     console.error("joinOrCreate error", e);
  //   }
  // }

  return (
    <>
      <GameRoom />
    </>
  );
};

export default BlitzGame;
