import Phaser from "phaser";
import { BOARD } from "./constants";
import { SOUND_FX } from "./sound/Sound";
import { getWorldPosition, calcScaledDimensions } from "./utils";

const width = 12;
const height = 12;
const tileSize = 33.3;

const removeSpeed = 150;
// const tileMargin = 2;

const BOARD_STATUS = {
  MOVING: "moving",
  STUNNED: "stunned",
  IDLE: "idle",
  ADDING_BLOCKS: "addingBlocks",
};

const STATUS = {
  RESTRICT_SELECT: [
    BOARD_STATUS.MOVING,
    // BOARD_STATUS.ADDING_BLOCKS,
    BOARD_STATUS.STUNNED,
  ],
};

const SPECIAL_STATUS = {
  FREE_ROAM: "free-roam",
};

const SPECIAL = {
  WALL_TILE: 6,
  WILDCARD: 7,
  FREEZE_AQUA: 8,
  FREEZE_BIRD: 9,
  FREEZE_PLANT: 10,
  FREEZE_BEAST: 11,
  FREEZE_REPTILE: 12,
  FREEZE_BUG: 13,
};

const SPECIAL_BLOCKS = [
  "wildcard",
  "frozen-blue",
  "frozen-pink",
  "frozen-green",
  "frozen-yellow",
  "frozen-purple",
  "frozen-red",
];

const LAYOUT = {};

export const tiles = [
  { color: 0x28b3d5, type: "mana", label: "blue" },
  { color: 0xffc0cb, type: "mana", label: "pink" },
  { color: 0x5abd00, type: "mana", label: "green" },
  { color: 0xfff700, type: "mana", label: "yellow" },
  { color: 0xa900ff, type: "mana", label: "purple" },
  { color: 0xa10f0f, type: "mana", label: "red" },

  { type: "blocker", unselectable: true, label: "wall" }, // 6
  { type: "wildcard", color: "wildcard", label: "wildcard" }, // 7

  {
    color: 0x28b3d5,
    type: "frozen",
    unselectable: true,
    texture: "frozen-blue",
    label: "blue",
  }, // 8
  {
    color: 0xffc0cb,
    type: "frozen",
    unselectable: true,
    texture: "frozen-pink",
    label: "pink",
  },
  {
    color: 0x5abd00,
    type: "frozen",
    unselectable: true,
    texture: "frozen-green",
    label: "green",
  },
  {
    color: 0xfff700,
    type: "frozen",
    unselectable: true,
    texture: "frozen-yellow",
    label: "yellow",
  },
  {
    color: 0xa900ff,
    type: "frozen",
    unselectable: true,
    texture: "frozen-purple",
    label: "purple",
  },
  {
    color: 0xa10f0f,
    type: "frozen",
    unselectable: true,
    texture: "frozen-red",
    label: "red",
  }, // 13
];

export class Board extends Phaser.GameObjects.Container {
  constructor(scene, x, y, playerId) {
    super(scene, x, y);

    this.lastClear = 0;
    this.manaSoundIndex = 1;
    this.lastAddSound = 0;
    this.ownerId = playerId;
    this.mine = false;
    scene.add.existing(this);

    this.grid = [];
    const rect = scene.add.image(0, 0, "board");
    // const rect = scene.add.rectangle(121, 241, 242, 482, 0x333333, 0.3);
    // rect.setStrokeStyle(1, 0x000000, 0.5);
    rect.setOrigin(0, 0);
    this.add(rect);
    this.boardRect = rect;

    this.selectedBlockCoords = null;
    this.status = BOARD_STATUS.IDLE;
    this.specialStatus = null;

    // contains temp proxy blocks for swaps
    this.swapBlocks = [];
    this.moveTweens = [];

    this.scene.anims.create({
      key: "wildcard",
      frames: this.scene.anims.generateFrameNames("orbs"),
      repeat: -1,
    });

    this.effectTweens = {};
  }

  setupGrid(grid) {
    for (let y = 0; y < height; y++) {
      this.grid[y] = [];

      for (let x = 0; x < width; x++) {
        const key = (y * width + x).toString();

        this.addBlockInGrid(
          { x, y },
          this.generateStaticBlock(grid.get(key)),
          this.mine
        );
      }
    }
  }

  setStats(stats) {
    this.stats = stats;
    this.stats.effectiveSpeed = 1000 - stats.speed + 75;
  }

  // setSpeed(speed) {
  //   this.speed = speed;
  // }

  parseGridValue(value) {
    return typeof value === "number"
      ? value
      : parseInt(value.split("-")[0], 10);
  }

  getCoordinatesByPosition(position) {
    return { x: position % width, y: Math.floor(position / width) };
  }

  generateSpecialBlock(tilesIndex) {
    const tile = tiles[tilesIndex];
    const label = tile ? tile.label : "";
    let block;

    switch (label) {
      case "wildcard":
        block = this.scene.add.sprite(0, 0, "orbs");
        block.play("wildcard");
        block.setData("tilesIndex", tilesIndex);
        break;

      case "blue":
      case "pink":
      case "green":
      case "yellow":
      case "purple":
      case "red":
        block = this.scene.add.sprite(0, 0, "frozen-orbs", tile.texture);
        block.setData("tilesIndex", tilesIndex);
        break;

      default:
        console.log("unknown tile", tilesIndex);
        break;
    }

    return block;
  }

  generateStaticBlock(tilesIndex, specialLabel) {
    let block;

    if (tilesIndex > 5) {
      block = this.scene.add.image(0, 0, specialLabel);
    } else {
      block = this.scene.add.sprite(
        0,
        0,
        "orbs",
        `${tiles[tilesIndex].label}Orb`
      );
    }
    block.setData("tilesIndex", tilesIndex);

    return block;
  }

  addBlockInGrid(coords, block, interactive) {
    try {
      const midBlock = tileSize / 2;
      const x = 7 + midBlock + tileSize * coords.x;
      const y = 6 + midBlock + tileSize * coords.y;
      // let block;

      // if (specialLabel) {
      //   block = this.scene.add.image(x, y, specialLabel);
      // } else {
      //   block = this.scene.add.sprite(
      //     x,
      //     y,
      //     "orbs",
      //     `${tiles[tilesIndex].label}Orb`
      //   );
      // }
      block.setPosition(x, y);
      block.setDisplaySize(tileSize, tileSize);
      block.setData("x", coords.x);
      block.setData("y", coords.y);

      this.add(block);
      this.grid[coords.y][coords.x] = block;

      if (interactive) {
        block.setInteractive();
        // this.scene.rexGestures.add.swipe(block)
      }
    } catch (e) {
      console.log(">>>>>> error in addBlock", e);
    }
  }

  updateGridBlock(position, value, interactive = true, specialLabel) {
    const coords = this.getCoordinatesByPosition(position);
    const currentBlock = this.grid[coords.y][coords.x];

    // TODO maybe reuse block if it's a normal one
    this.remove(currentBlock);
    currentBlock.destroy();

    if (isNaN(this.parseGridValue(value))) {
      console.log(value);
    }

    let block;
    if (specialLabel && SPECIAL_BLOCKS.includes(specialLabel)) {
      block = this.generateSpecialBlock(this.parseGridValue(value));
    } else {
      if (!specialLabel && this.parseGridValue(value) > 5) {
        console.log("WTFFF", value);
      }
      block = this.generateStaticBlock(
        this.parseGridValue(value),
        specialLabel
      );
    }

    this.addBlockInGrid(coords, block, interactive);
  }

  checkStateStatus(current, previous, isClient) {
    if (current === previous) {
      // console.log("no status change");
      return;
    }

    if (!isClient) {
      if (previous === BOARD_STATUS.MOVING) {
        // console.log("stop mov", current);
        // hide the swap blocks
        this.swapBlocks.forEach((swap) => {
          const block = this.getGridBlock({
            x: swap.getData("x"),
            y: swap.getData("y"),
          });
          block.visible = true;

          this.remove(swap);
          swap.destroy();
        });

        this.swapBlocks = [];
      }
      // } else {
      // this.status = current;
    }

    this.status = current;
  }

  getGridBlock(coords) {
    if (!this.grid[coords.y]) {
      return null;
    }
    return this.grid[coords.y][coords.x];
  }

  selectBlock(block) {
    const blockCoords = { x: block.getData("x"), y: block.getData("y") };
    const blockTile = tiles[block.getData("tilesIndex")];

    if (STATUS.RESTRICT_SELECT.includes(this.status)) {
      console.log("selections restricted", this.status);
      return;
    }

    if (blockTile.unselectable) {
      console.log("block unselectable", blockTile);
      return;
    }

    this.scene.game.events.emit("play-fx", SOUND_FX.SELECT_BLOCK, {
      volume: 0.75,
    });
    // check for possible move
    if (this.selectedBlockCoords) {
      const distance = Math.sqrt(
        Math.pow(this.selectedBlockCoords.x - blockCoords.x, 2) +
          Math.pow(this.selectedBlockCoords.y - blockCoords.y, 2)
      );

      if (distance === 0) {
        // deselect
        this.deselectBlock(block);
        // this.scene.events.emit("deselect-block", block);
        // this.selectedBlockCoords = null;
      } else {
        // process a move
        if (distance === 1) {
          // check for free roam status
          // if (free roam or there are matches) {
          this.scene.game.events.emit("play-fx", SOUND_FX.MOVE);
          if (
            this.checkForMatches([this.selectedBlockCoords, blockCoords]) ||
            this.specialStatus === SPECIAL_STATUS.FREE_ROAM
          ) {
            this.scene.events.emit(
              "swap",
              this.selectedBlockCoords,
              blockCoords
            );
            this.moveBlocks(this.selectedBlockCoords, blockCoords);
            this.deselectBlock(this.getGridBlock(this.selectedBlockCoords));
          } else {
            // make a bounce failed move
            this.moveBlocks(this.selectedBlockCoords, blockCoords, true);
            this.deselectBlock(this.getGridBlock(this.selectedBlockCoords));
          }
          //   this.scene.events.emit("swap", this.selectedBlockCoords, blockCoords);
          //   this.moveBlocks(this.selectedBlockCoords, blockCoords);
          //   this.deselectBlock(this.getGridBlock(this.selectedBlockCoords));
          // }
        } else {
          this.deselectBlock(this.getGridBlock(this.selectedBlockCoords));
          this.scene.events.emit("select-block", block);
          this.selectedBlockCoords = blockCoords;
        }
      }
    } else {
      // select

      this.scene.events.emit("select-block", block);
      this.selectedBlockCoords = blockCoords;
    }

    // console.log(this.selectedBlockCoords);
  }

  get2Match(coordinate) {
    function check(b1, b2) {
      if (
        !b1 ||
        !b2 ||
        tiles[b1.getData("tilesIndex")].label === "wall" ||
        tiles[b2.getData("tilesIndex")].label === "wall"
      ) {
        return false;
      } else if (
        tiles[b1.getData("tilesIndex")].label ===
        tiles[b2.getData("tilesIndex")].label
      ) {
        return tiles[b1.getData("tilesIndex")].label;
      } else {
        return false;
      }
    }

    // 2 up
    let w1 = this.getGridBlock({ x: coordinate.x, y: coordinate.y - 2 });
    let w2 = this.getGridBlock({ x: coordinate.x, y: coordinate.y - 1 });
    let result = check(w1, w2);
    if (result) {
      return result;
    }

    // 2 down
    w1 = this.getGridBlock({ x: coordinate.x, y: coordinate.y + 2 });
    w2 = this.getGridBlock({ x: coordinate.x, y: coordinate.y + 1 });
    result = check(w1, w2);
    if (result) {
      return result;
    }

    // 1up 1down
    w1 = this.getGridBlock({ x: coordinate.x, y: coordinate.y + 1 });
    w2 = this.getGridBlock({ x: coordinate.x, y: coordinate.y - 1 });
    result = check(w1, w2);
    if (result) {
      return result;
    }

    // 2 left
    w1 = this.getGridBlock({ x: coordinate.x - 2, y: coordinate.y });
    w2 = this.getGridBlock({ x: coordinate.x - 1, y: coordinate.y });
    result = check(w1, w2);
    if (result) {
      return result;
    }

    // 2 right
    w1 = this.getGridBlock({ x: coordinate.x + 2, y: coordinate.y });
    w2 = this.getGridBlock({ x: coordinate.x + 1, y: coordinate.y });
    result = check(w1, w2);
    if (result) {
      return result;
    }

    // 1r 1l
    w1 = this.getGridBlock({ x: coordinate.x + 1, y: coordinate.y });
    w2 = this.getGridBlock({ x: coordinate.x - 1, y: coordinate.y });
    result = check(w1, w2);

    return result;
  }

  checkForMatches(coords) {
    let match = false;

    // temp swap blocks for the comparison
    const tempBlock = this.grid[coords[0].y][coords[0].x];
    this.grid[coords[0].y][coords[0].x] = this.grid[coords[1].y][coords[1].x];
    this.grid[coords[1].y][coords[1].x] = tempBlock;

    coords.forEach((coordinate, i) => {
      const blockObj = this.getGridBlock(coordinate);
      const block = tiles[blockObj.getData("tilesIndex")];

      const verticalMatches = [];
      let matchY = coordinate.y - 1;
      let compBlock = this.getGridBlock({ x: coordinate.x, y: matchY });

      // if (!block) {
      //   console.log("whoa", coordinate);
      // }

      let compLabel = block.label;
      // get block comp label, we do this in case moving block is special
      if (block.label === "wildcard") {
        const wildcardLabel = this.get2Match(coordinate);
        // console.log("wildmatch", wildcardLabel);
        compLabel = wildcardLabel ? wildcardLabel : compLabel;
      }

      // console.log(block, tiles[compBlock.getData("tilesIndex")]);
      // check up
      while (
        matchY >= 0 &&
        compBlock &&
        block &&
        (tiles[compBlock.getData("tilesIndex")].label === compLabel ||
          tiles[compBlock.getData("tilesIndex")].label === "wildcard") &&
        !match
      ) {
        verticalMatches.push({
          x: coordinate.x,
          y: matchY,
          label: tiles[compBlock.getData("tilesIndex")].label,
        });
        matchY -= 1;
        compBlock = this.getGridBlock({ x: coordinate.x, y: matchY });
      }

      matchY = coordinate.y + 1;
      compBlock = this.getGridBlock({ x: coordinate.x, y: matchY });

      // check down
      while (
        matchY < height &&
        compBlock &&
        block &&
        (tiles[compBlock.getData("tilesIndex")].label === compLabel ||
          tiles[compBlock.getData("tilesIndex")].label === "wildcard") &&
        !match
      ) {
        verticalMatches.push({
          x: coordinate.x,
          y: matchY,
          label: tiles[compBlock.getData("tilesIndex")].label,
        });
        matchY += 1;
        compBlock = this.getGridBlock({ x: coordinate.x, y: matchY });
      }

      // check horizontal
      const horizontalMatches = [];
      let matchX = coordinate.x - 1;
      compBlock = this.getGridBlock({ x: matchX, y: coordinate.y });
      // check left
      while (
        matchX >= 0 &&
        compBlock &&
        block &&
        (tiles[compBlock.getData("tilesIndex")].label === compLabel ||
          tiles[compBlock.getData("tilesIndex")].label === "wildcard") &&
        !match
      ) {
        horizontalMatches.push({
          x: matchX,
          y: coordinate.y,
          label: tiles[compBlock.getData("tilesIndex")].label,
        });
        matchX -= 1;
        compBlock = this.getGridBlock({ x: matchX, y: coordinate.y });
      }

      matchX = coordinate.x + 1;
      compBlock = this.getGridBlock({ x: matchX, y: coordinate.y });
      // check right
      while (
        matchX < width &&
        compBlock &&
        block &&
        (tiles[compBlock.getData("tilesIndex")].label === compLabel ||
          tiles[compBlock.getData("tilesIndex")].label === "wildcard") &&
        !match
      ) {
        horizontalMatches.push({
          x: matchX,
          y: coordinate.y,
          label: tiles[compBlock.getData("tilesIndex")].label,
        });
        matchX += 1;
        compBlock = this.getGridBlock({ x: matchX, y: coordinate.y });
      }

      if (verticalMatches.length > 1 || horizontalMatches.length > 1) {
        // console.log(verticalMatches, horizontalMatches);
        match = true;
      }
    });

    this.grid[coords[1].y][coords[1].x] = this.grid[coords[0].y][coords[0].x];
    this.grid[coords[0].y][coords[0].x] = tempBlock;

    return match;
  }

  checkSwipe(direction) {
    if (!this.selectedBlockCoords) {
      return;
    }

    switch (direction) {
      case "left":
        if (this.selectedBlockCoords.x - 1 >= 0) {
          this.scene.game.events.emit("play-fx", SOUND_FX.MOVE);
          this.selectBlock(
            this.getGridBlock({
              x: this.selectedBlockCoords.x - 1,
              y: this.selectedBlockCoords.y,
            })
          );
        } else if (this.getGridBlock(this.selectedBlockCoords)) {
          this.deselectBlock(this.selectedBlockCoords);
        }
        break;

      case "right":
        if (this.selectedBlockCoords.x + 1 < width) {
          this.scene.game.events.emit("play-fx", SOUND_FX.MOVE);
          this.selectBlock(
            this.getGridBlock({
              x: this.selectedBlockCoords.x + 1,
              y: this.selectedBlockCoords.y,
            })
          );
        } else if (this.getGridBlock(this.selectedBlockCoords)) {
          this.deselectBlock(this.selectedBlockCoords);
        }
        break;

      case "up":
        if (this.selectedBlockCoords.y - 1 >= 0) {
          this.scene.game.events.emit("play-fx", SOUND_FX.MOVE);
          this.selectBlock(
            this.getGridBlock({
              x: this.selectedBlockCoords.x,
              y: this.selectedBlockCoords.y - 1,
            })
          );
        } else if (this.getGridBlock(this.selectedBlockCoords)) {
          this.deselectBlock(this.selectedBlockCoords);
        }
        break;

      case "down":
        if (this.selectedBlockCoords.y + 1 < height) {
          this.scene.game.events.emit("play-fx", SOUND_FX.MOVE);
          this.selectBlock(
            this.getGridBlock({
              x: this.selectedBlockCoords.x,
              y: this.selectedBlockCoords.y + 1,
            })
          );
        } else if (this.getGridBlock(this.selectedBlockCoords)) {
          this.deselectBlock(this.selectedBlockCoords);
        }
        break;

      default:
        console.log("unknown swipe direction", direction);
        break;
    }
  }

  deselectBlock(block) {
    this.scene.events.emit("deselect-block", block);
    this.selectedBlockCoords = null;
  }

  gridToScreenCoords(gridCoords) {
    const midBlock = tileSize / 2;

    return {
      x: 7 + midBlock + tileSize * gridCoords.x,
      y: 6 + midBlock + tileSize * gridCoords.y,
    };
  }

  moveBlocks(b1, b2, failed) {
    this.checkStateStatus(BOARD_STATUS.MOVING, this.status, true);
    // this.status = BOARD_STATUS.MOVING;

    const block1 = this.getGridBlock(b1);
    const block2 = this.getGridBlock(b2);
    const tile1 = tiles[block1.getData("tilesIndex")];
    const tile2 = tiles[block2.getData("tilesIndex")];
    const screenXY1 = this.gridToScreenCoords(b1);
    const screenXY2 = this.gridToScreenCoords(b2);
    const tweens = [];
    block1.visible = false;
    block2.visible = false;

    const swap1 = SPECIAL_BLOCKS.includes(tile1.label)
      ? this.generateSpecialBlock(block1.getData("tilesIndex"))
      : this.generateStaticBlock(block1.getData("tilesIndex"), tile1.label);
    // this.scene.add.sprite(
    //     block1.x,
    //     block1.y,
    //     "orbs",
    //     `${tiles[block1.getData("tilesIndex")].label}Orb`
    //   );
    swap1.setPosition(screenXY1.x, screenXY1.y);
    swap1.setData("x", b1.x);
    swap1.setData("y", b1.y);
    swap1.setDisplaySize(tileSize, tileSize);
    this.add(swap1);
    this.swapBlocks.push(swap1);

    // const swap2 = this.scene.add.sprite(
    //   block2.x,
    //   block2.y,
    //   "orbs",
    //   `${tiles[block2.getData("tilesIndex")].label}Orb`
    // );

    const swap2 = SPECIAL_BLOCKS.includes(tile2.label)
      ? this.generateSpecialBlock(block2.getData("tilesIndex"))
      : this.generateStaticBlock(block2.getData("tilesIndex"), tile2.label);

    swap2.setPosition(screenXY2.x, screenXY2.y);
    swap2.setData("x", b2.x);
    swap2.setData("y", b2.y);
    swap2.setDisplaySize(tileSize, tileSize);
    this.add(swap2);
    this.swapBlocks.push(swap2);

    function checkSwaps(board, failed) {
      if (tweens[0].progress < 1 || tweens[1].progress < 1) {
        return;
      }

      if (failed) {
        board.cancelMove();
      }

      board.checkStateStatus(BOARD_STATUS.IDLE, board.status, true);

      // board.checkStateStatus(BOARD_STATUS.IDLE, board.status, true);
      // console.log("board", board.status, ">>", BOARD_STATUS.IDLE);
      // board.status = BOARD_STATUS.IDLE;
      // block1.setData("x", b2.x);
      // block1.setData("y", b2.y);

      // block2.setData("x", b1.x);
      // block2.setData("y", b1.y);

      // // perform swap in grid temp until final change from server
      // board.grid[b2.y][b2.x] = block1;
      // board.grid[b1.y][b1.x] = block2;
      // console.log("f", tweens[0].progress, tweens[1].progress);
    }

    tweens[0] = this.scene.tweens.add({
      duration: this.stats.modSpeed,
      targets: swap1,
      x: block2.x,
      y: block2.y,
      yoyo: failed,
      onComplete: () => {
        // this.status = BOARD_STATUS.IDLE;
        // block1.setData("x", b2.x);
        // block1.setData("y", b2.y);
        checkSwaps(this, failed);
        // perform swap in grid temp until final change from server
        // this.grid[b2.y][b2.x] = block1;
        // this.grid[b1.y][b1.x] = block2;
      },
    });

    tweens[1] = this.scene.tweens.add({
      duration: this.stats.modSpeed,
      targets: swap2,
      x: block1.x,
      y: block1.y,
      yoyo: failed,
      onComplete: () => {
        checkSwaps(this, failed);
        // console.log(this.status);
        // block2.setData("x", b1.x);
        // block2.setData("y", b1.y);
      },
    });

    this.moveTweens = tweens;
  }

  cancelMove() {
    this.moveTweens.forEach((tween) => {
      tween.remove();
    });

    this.swapBlocks.forEach((sb) => {
      this.getGridBlock({
        x: sb.getData("x"),
        y: sb.getData("y"),
      }).visible = true;

      this.remove(sb);
      sb.destroy();
    });

    this.swapBlocks = [];
  }

  clearBlock(position) {
    const coords = this.getCoordinatesByPosition(position);
    const block = this.getGridBlock(coords);
    // console.log("clear", coords);
    this.scene.tweens.add({
      duration: removeSpeed,
      targets: block,
      scale: 0,
    });

    if (!this.mine) {
      return;
    }

    const tempMatrix = new Phaser.GameObjects.Components.TransformMatrix();

    block.getWorldTransformMatrix(tempMatrix);
    const worldPos = tempMatrix.decomposeMatrix();

    const tilesIndex = block.getData("tilesIndex");

    if (tilesIndex >= 6) {
      if (tilesIndex === 7 && this.mine) {
        this.scene.game.events.emit("play-fx", SOUND_FX.WILDCARD_CONSUME);
        // console.log("clear wildcard", coords);
      }
      return;
    }

    const manaColor = tiles[tilesIndex].label;
    const manaBox = this.scene.me.stats.manaBoxes[manaColor];

    const manaTempMatrix = new Phaser.GameObjects.Components.TransformMatrix();
    manaBox.icon.getWorldTransformMatrix(manaTempMatrix);
    const manaWorldPos = manaTempMatrix.decomposeMatrix();

    const now = new Date().getTime();

    if (now - this.lastClear > 50) {
      if (now - this.lastClear < BOARD.CLEAR_SEQ) {
        this.manaSoundIndex++;
        this.manaSoundIndex = this.manaSoundIndex > 4 ? 4 : this.manaSoundIndex;
      } else {
        this.manaSoundIndex = 1;
      }

      this.lastClear = now;

      this.scene.game.events.emit(
        "play-fx",
        SOUND_FX[`MANA_${this.manaSoundIndex}`]
      );
    }

    this.scene.events.emit("clear-block", {
      mana: manaColor,
      sourceX: worldPos.translateX,
      sourceY: worldPos.translateY,
      destX: manaWorldPos.translateX + 5,
      destY: manaWorldPos.translateY + 5,
    });
  }

  addSpecial(position, value) {
    const tile = this.parseGridValue(value);
    const coords = this.getCoordinatesByPosition(position);

    if (
      this.selectedBlockCoords &&
      this.selectedBlockCoords.x === coords.x &&
      this.selectedBlockCoords.y === coords.y
    ) {
      this.deselectBlock(this.getGridBlock(coords));
    }

    switch (tile) {
      case SPECIAL.WALL_TILE:
        // check if there's a move in progress
        if (this.swapBlocks) {
          this.swapBlocks.forEach((sb) => {
            if (sb.getData("x") === coords.x && sb.getData("y") === coords.y) {
              if (this.selectedBlockCoords) {
                this.deselectBlock(this.getGridBlock(this.selectedBlockCoords));
              }
              this.checkStateStatus(BOARD_STATUS.IDLE, this.status, true);
              this.cancelMove();
            }
          });
        }

        if (this.mine) {
          this.scene.events.emit("reaction", "wall");
          this.scene.game.events.emit("play-fx", SOUND_FX.WALL, {
            volume: 0.6,
          });
        }

        if (coords.x === 0 && coords.y === 0) {
          this.updateGridBlock(position, SPECIAL.WALL_TILE, false, "wall-tl");
        } else if (coords.x === width - 1 && coords.y === 0) {
          this.updateGridBlock(position, SPECIAL.WALL_TILE, false, "wall-tr");
        } else if (coords.x === 0 && coords.y === height - 1) {
          this.updateGridBlock(position, SPECIAL.WALL_TILE, false, "wall-bl");
        } else if (coords.x === width - 1 && coords.y === height - 1) {
          this.updateGridBlock(position, SPECIAL.WALL_TILE, false, "wall-br");
        } else {
          this.updateGridBlock(position, SPECIAL.WALL_TILE, false, "wall");
        }
        break;

      case SPECIAL.WILDCARD:
        if (this.mine) {
          this.scene.game.events.emit("play-fx", SOUND_FX.WILDCARD);
        }

        this.updateGridBlock(position, SPECIAL.WILDCARD, true, "wildcard");
        break;

      case SPECIAL.FREEZE_AQUA:
      case SPECIAL.FREEZE_BEAST:
      case SPECIAL.FREEZE_BIRD:
      case SPECIAL.FREEZE_BUG:
      case SPECIAL.FREEZE_PLANT:
      case SPECIAL.FREEZE_REPTILE:
        // console.log("add frozen", value);
        // check if there's a move in progress
        if (this.swapBlocks) {
          this.swapBlocks.forEach((sb) => {
            if (sb.getData("x") === coords.x && sb.getData("y") === coords.y) {
              if (this.selectedBlockCoords) {
                this.deselectBlock(this.getGridBlock(this.selectedBlockCoords));
              }
              this.checkStateStatus(BOARD_STATUS.IDLE, this.status, true);
              this.cancelMove();
            }
          });
        }

        this.updateGridBlock(position, tile, false, tiles[tile].texture);
        break;

      default:
        console.error("error trying to add unexisting special tile", tile);
        break;
    }
  }

  toggleEffect(name, show = false) {
    if (show) {
      if (this.effectTweens[name]) {
        return;
      }

      let pipeline;
      if (name === "blur") {
        const postFxPlugin = this.scene.plugins.get("rexKawaseBlurPipeline");
        pipeline = postFxPlugin.add(this, {
          blur: 2.4,
          quality: 3,
          pixelWidth: 2,
          pixelHeight: 2,
        });

        this.effectTweens[name] = this.scene.tweens.add({
          targets: pipeline,
          blur: 5.25,
          repeat: -1,
          yoyo: true,
          duration: 1000,
        });
      }
    } else {
      if (this.effectTweens[name]) {
        this.effectTweens[name].stop();
        this.effectTweens[name].remove();
        this.effectTweens[name] = null;
        this.scene.plugins.get("rexKawaseBlurPipeline").remove(this);
      }
    }
  }

  toggleSpecialStatus(name) {
    if (name) {
      this.specialStatus = name;
    } else {
      this.specialStatus = null;
    }
  }

  showShield() {
    if (!this.shieldObj) {
      this.shieldObj = this.scene.add.image(this.x, this.y, "board-shield");
      this.shieldObj.setOrigin(0, 0);
      this.shieldObj.setScale(this.scaleX, this.scaleY);
      // this.add(this.shieldObj);
      this.shieldObj.visible = false;
      this.shieldObj.depth = 6;
    }

    if (this.removeShieldTween) {
      this.removeShieldTween.stop();
      this.removeShieldTween.remove();
      this.removeShieldTween = null;
    }

    const glowPlugin = this.scene.plugins.get("rexGlowFilterPipeline");
    const glowPipeline = glowPlugin.add(this.shieldObj);
    this.shieldTween = this.scene.tweens.add({
      targets: glowPipeline,
      intensity: 0.01,
      repeat: -1,
      yoyo: true,
      onStart: () => {
        glowPipeline.intensity = 0;
        this.shieldObj.visible = true;
      },
    });

    this.showShieldTween = this.scene.tweens.add({
      targets: this.shieldObj,
      alpha: 1,
      duration: 250,
      onStart: () => {
        this.shieldObj.alpha = 0;
      },
    });
  }

  removeShield() {
    if (this.shieldTween) {
      this.shieldTween.stop();
      this.shieldTween.remove();
      this.shieldTween = null;
      this.shieldObj.visible = false;

      this.showShieldTween.stop();
      this.showShieldTween.remove();
      this.showShieldTween = null;

      this.removeShieldTween = this.scene.tweens.add({
        targets: this.shieldObj,
        alpha: 0,
        // delay: 500,
        duration: 200,
        onComplete: () => {
          this.shieldObj.visible = false;
          const glowPlugin = this.scene.plugins.get("rexGlowFilterPipeline");
          glowPlugin.remove(this.shieldObj);
        },
      });
    }
  }

  flashTarget() {
    if (!this.targetObj) {
      this.targetObj = this.scene.add.image(this.x, this.y, "board-target");
      this.targetObj.setOrigin(0, 0);
      this.targetObj.setScale(this.scaleX, this.scaleY);
      this.targetObj.setInteractive({
        cursor: "url(images/target.png), pointer",
      });
      this.targetObj.visible = false;
      this.targetObj.depth = 7;
      this.targetObj.setData("target", this.ownerId);
    }

    if (this.targetTween) {
      this.targetTween.stop();
      this.targetTween.remove();
      this.targetTween = null;
    }

    if (this.removeTargetTween) {
      this.removeTargetTween.stop();
      this.removeTargetTween.remove();
      this.removeTargetTween = null;
    }

    const glowPlugin = this.scene.plugins.get("rexGlowFilterPipeline");
    const glowPipeline = glowPlugin.add(this.targetObj);

    this.showTargetTween = this.scene.tweens.add({
      targets: this.targetObj,
      alpha: 1,
      duration: 100,
      onStart: () => {
        this.targetObj.alpha = 0;
        this.targetObj.visible = true;
      },
      onComplete: () => {
        this.scene.tweens.add({
          targets: this.targetObj,
          alpha: 0,
          duration: 100,
          delay: 200,
          onComplete: () => {
            this.targetObj.visible = false;
          },
        });
      },
    });

    this.targetTween = this.scene.tweens.add({
      targets: glowPipeline,
      intensity: 0.015,
      yoyo: true,
      delay: 100,
      duration: 200,
      onStart: () => {
        glowPipeline.intensity = 0;
      },
      onComplete: () => {
        glowPlugin.remove(this.targetObj);
      },
    });
  }

  showTarget() {
    if (!this.targetObj) {
      this.targetObj = this.scene.add.image(this.x, this.y, "board-target");
      this.targetObj.setOrigin(0, 0);
      this.targetObj.setScale(this.scaleX, this.scaleY);
      this.targetObj.setInteractive({
        cursor: "url(images/target.png), pointer",
      });
      this.targetObj.visible = false;
      this.targetObj.depth = 7;
      this.targetObj.setData("target", this.ownerId);
    }

    if (this.removeTargetTween) {
      this.removeTargetTween.stop();
      this.removeTargetTween.remove();
      this.removeTargetTween = null;
    }

    const glowPlugin = this.scene.plugins.get("rexGlowFilterPipeline");
    const glowPipeline = glowPlugin.add(this.targetObj);
    this.targetTween = this.scene.tweens.add({
      targets: glowPipeline,
      intensity: 0.011,
      repeat: -1,
      yoyo: true,
      duration: 500,
      onStart: () => {
        glowPipeline.intensity = 0;
        this.targetObj.visible = true;
      },
    });

    this.showTargetTween = this.scene.tweens.add({
      targets: this.targetObj,
      alpha: 1,
      duration: 250,
      onStart: () => {
        this.targetObj.alpha = 0;
      },
    });
  }

  removeTarget() {
    const glowPlugin = this.scene.plugins.get("rexGlowFilterPipeline");

    if (this.targetTween) {
      this.targetTween.stop();
      this.targetTween.remove();
      this.targetTween = null;
    }

    if (this.showTargetTween) {
      this.showTargetTween.stop();
      this.showTargetTween.remove();
      this.showTargetTween = null;
    }

    this.removeTargetTween = this.scene.tweens.add({
      targets: this.targetObj,
      alpha: 0,
      // delay: 500,
      duration: 200,
      onComplete: () => {
        this.targetObj.visible = false;
      },
    });

    glowPlugin.remove(this.targetObj);
  }

  getBoardSceneCoordinates() {
    const msgPosition = getWorldPosition(this.boardRect);
    const targetDimensions = calcScaledDimensions(this.boardRect);

    return {
      x: msgPosition.translateX + targetDimensions.width / 2,
      y: msgPosition.translateY + targetDimensions.height / 2,
    };
  }

  gameOver() {
    const msgPosition = getWorldPosition(this.boardRect);
    const targetDimensions = calcScaledDimensions(this.boardRect);
  }

  setMine(owned) {
    this.mine = owned;
  }
}
