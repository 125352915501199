import Phaser from "phaser";
import { MatchDetails } from "./MatchDetails";
import { Button } from "../../components/Button";

export class MatchResults extends Phaser.Scene {
  // constructor(config = {}) {
  //   super({ key: "menu", ...config });
  // }

  create() {
    const midX = this.cameras.main.width / 2;
    const midY = this.cameras.main.height / 2;

    this.fadeMask = this.add.rectangle(
      midX,
      midY,
      this.cameras.main.width,
      this.cameras.main.height,
      0x000000
    );
    // this.fadeMask.setInteractive();
    this.fadeMask.depth = 10;
    // this.fadeMask.visible = false;
    this.fadeMask.alpha = 1;

    this.add.rectangle(
      midX,
      midY,
      this.cameras.main.width,
      this.cameras.main.height,
      0xbbac93
    );

    // this.playAgainBtn = new Button(this, midX + 130, midY + 320, {
    //   text: "PLAY AGAIN",
    // });
    // // this.playAgainBtn.visible = false;
    // this.playAgainBtn.onClick(() => {
    //   this.events.emit("play-again");
    // });

    this.exitBtn = new Button(this, midX - 65, midY + 320, {
      text: "EXIT",
    });
    // this.playAgainBtn.visible = false;
    this.exitBtn.onClick(() => {
      this.events.emit("back-to-menu");
    });

    this.events.on("transitionstart", (from, duration) => {
      this.tweens.add({
        targets: this.fadeMask,
        alpha: 0,
        // delay: duration * 0.25,
        duration: duration, // * 0.75,
        onStart: () => {
          // this.fadeMask.visible = true;
          this.fadeMask.alpha = 1;
        },
        onComplete: () => {
          // this.fadeMask.visible = false;
        },
      });
    });

    this.events.on("transitionout", (target, duration) => {
      console.log("match out");
      this.tweens.add({
        targets: this.fadeMask,
        alpha: 1,
        duration: duration,
        onStart: () => {
          // this.fadeMask.visible = true;
          this.fadeMask.alpha = 0;
          // this.events.removeAllListeners();
        },
      });
    });

    this.events.on("destroy", (a, b) => {
      console.log(">>>>>", a, b);
      this.reset();
    });

    this.anims.create({
      key: "wildcard",
      frames: this.anims.generateFrameNames("orbs"),
      repeat: -1,
      duration: 1000,
    });

    this.matchDetails = new MatchDetails(this, midX, 0);

    // this.show();
  }

  show(data) {
    this.matchDetails.renderDetails(data);
  }

  setState(roomState) {
    this.state = roomState;
  }

  reset() {
    console.log("destroy matchresults");
    this.events.removeAllListeners();
  }
}
