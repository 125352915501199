import { CSSTransition } from "react-transition-group";
import { SOUND_FX } from "../../../../gameSrc/sound/Sound";
import { TRANSITIONS } from "../../../../../../constants";
import ModalSpinner from "../../../components/ModalLoader";
import { syncUserAxies, updateFavorite } from "../../../../gameSrc/helpers/api";
import { useState, useReducer, useEffect } from "react";
import Button from "../../../components/Form/Button";
import AxieList from "../../../components/AxieList";
import AxieStatsModal from "../../../components/AxieStatsModal";

import styles from "./MyAxies.module.scss";

const initState = {
  optionSelected: "",
};

const reducer = (state, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "change_option":
      newState.optionSelected = action.value;
      break;

    default:
      break;
  }

  return newState;
};

const MyAxies = ({ show, playSound }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [axies, setAxies] = useState();
  const [favorite, setFavorite] = useState();
  const [updating, submit] = useState(false);
  const [detailsId, viewAxieId] = useState();

  useEffect(() => {
    if (show) {
      setAxies(JSON.parse(window.localStorage.getItem("axies")));
      setFavorite(parseInt(window.localStorage.getItem("favorite")), 10);
    }
  }, [show]);

  const handleFavorite = async (axieId) => {
    submit(true);
    try {
      const response = await updateFavorite(axieId == favorite ? null : axieId);

      window.localStorage.setItem("favorite", response.favorite);
      setFavorite(response.favorite);
    } catch (e) {
      console.log(e);
      alert("Error saving favorite");
    }
    submit(false);
  };

  const handleSync = async () => {
    submit(true);
    try {
      const { axies, favorite } = await syncUserAxies();
      window.localStorage.setItem("axies", JSON.stringify(axies));
      window.localStorage.setItem("favorite", favorite);
      setAxies(axies);
      setFavorite(favorite);
    } catch (e) {
      console.log(e);
      alert("Error syncing axies");
    }
    submit(false);
  };

  return (
    <CSSTransition
      in={show}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      unmountOnExit
    >
      <>
        <Button
          text={
            <>
              <img src="./images/ui/sync.png" />
              sync axies
            </>
          }
          display="inline"
          type="plain"
          style={{ padding: 0, position: "absolute", top: 20 }}
          onClick={() => {
            playSound(SOUND_FX.CLICK, { volume: 0.25 });
            handleSync();
          }}
          onMouseEnter={() => {
            playSound(SOUND_FX.HOVER, { volume: 0.25 });
          }}
        />
        {axies && axies.length ? (
          <AxieList
            axies={axies}
            selection={favorite}
            favoriteId={favorite}
            showFavorites
            disableFavorite={updating}
            onFavoriteClick={handleFavorite}
            onClick={viewAxieId}
          />
        ) : (
          <div className={styles.emptyMsg}>
            <p>
              You have no axies. Connect a Ronin Wallet with Axies in it or get
              your own in the Axie Infinity{" "}
              <a
                href="https://app.axieinfinity.com/marketplace/"
                target="_blank"
              >
                marketplace!
              </a>
            </p>
            <p>
              You can still play normal games with one of our starter axies.
            </p>
          </div>
        )}

        <AxieStatsModal
          axieId={detailsId}
          className={styles.details}
          showFavorite
          disableFavorite={updating}
          onFavoriteClick={handleFavorite}
          favoriteId={favorite}
          onClose={() => {
            viewAxieId();
            playSound(SOUND_FX.CLICK, { volume: 0.25 });
          }}
          showArrows={axies && axies.length}
          nextFn={() => {
            let currentIndex = axies.findIndex(
              (data) => data.axie.axie_id === detailsId
            );
            currentIndex = axies[currentIndex + 1] ? currentIndex + 1 : 0;
            viewAxieId(axies[currentIndex].axie.axie_id);
            playSound(SOUND_FX.CLICK, { volume: 0.25 });
          }}
          previousFn={() => {
            let currentIndex = axies.findIndex(
              (data) => data.axie.axie_id === detailsId
            );
            currentIndex = axies[currentIndex - 1]
              ? currentIndex - 1
              : axies.length - 1;
            viewAxieId(axies[currentIndex].axie.axie_id);
            playSound(SOUND_FX.CLICK, { volume: 0.25 });
          }}
        />
        <ModalSpinner show={updating} style={{ borderRadius: 24 }} />
      </>
    </CSSTransition>
  );
};

export default MyAxies;
