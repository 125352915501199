import { CSSTransition } from "react-transition-group";
import { TRANSITIONS } from "../../../../../../constants";
import Select from "react-select";

import settingStyles from "../settings.module.scss";
import { useEffect, useState } from "react";

const screenSizeOptions = [
  {
    label: "default (1440 x 720)",
    value: "default",
  },
  { label: "1920 x 1080", value: "1920" },
  { label: "100%", value: "100" },
];

const particlesOptions = [
  {
    label: "Low",
    value: "low",
  },
  { label: "Medium", value: "mid" },
  { label: "High", value: "high" },
];

const dropdownStyles = {
  menuList: (provided, state) => {
    return { ...provided, background: "#baa53c" };
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "transparent",
      ":active": { backgroundColor: "transparent" },
    };
  },
  control: (provided, state) => {
    return {
      ...provided,
      background: "#baa53c",
      border: 0,
      boxShadow: "none",
      "&:hover": { border: 0, boxShadow: "none" },
    };
  },
  indicatorsContainer: (provided, state) => {
    return { ...provided, color: "#ffffff" };
  },
  indicatorSeparator: (provided, state) => {
    return { ...provided, backgroundColor: "#ffffff" };
  },
  singleValue: (provided, state) => {
    return { ...provided, color: "#ffffff" };
  },
};

const GraphicOptions = ({ show, onChange, values }) => {
  return (
    <CSSTransition
      in={show}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      unmountOnExit
    >
      <div className={settingStyles.optionsContainer}>
        <div
          className={settingStyles.optionLabelContainer}
          style={{ maxWidth: 300 }}
        >
          Container Size
          <Select
            options={screenSizeOptions}
            defaultValue={screenSizeOptions.filter(
              (option) => option.value === values.screenSize
            )}
            // value={values.screenSize}
            onChange={(option) => {
              onChange("graphics", { screenSize: option.value });
            }}
            className={settingStyles.dropdown}
            styles={dropdownStyles}
          />
        </div>
        <div
          className={settingStyles.optionLabelContainer}
          style={{ maxWidth: 300, marginTop: 50 }}
        >
          Particles count
          <Select
            options={particlesOptions}
            defaultValue={particlesOptions.filter(
              (option) => option.value === values.particles
            )}
            // value={values.screenSize}
            onChange={(option) => {
              onChange("graphics", { particles: option.value });
            }}
            className={settingStyles.dropdown}
            styles={dropdownStyles}
          />
        </div>
      </div>
    </CSSTransition>
  );
};

export default GraphicOptions;
