import Phaser from "phaser";
import { GAME_STATE } from "../Game";
import { Board, tiles } from "./Board";
import { StatsBoard } from "./StatsBoard";
import { getWorldPosition, calcScaledDimensions, getRandomInt } from "./utils";
import { Layouts, getLayout } from "./Layouts";
import { SOUND_FX } from "./sound/Sound";
import {
  EFFECTS_HIT_SOUND,
  EFFECTS_TEXT,
  EFFECTS_CAST_SOUND,
  EFFECTS_TARGET,
  EFFECTS_TARGET_NAMES,
  EFFECTS_HIT,
  PLAYER_STATE,
  EFFECTS_NAME,
} from "./constants";

const LAYOUT = {
  col: 200,
  spaceHorizontal: 18,
  spaceVertical: 28,
};

export class WorldScene extends Phaser.Scene {
  constructor(config) {
    super({
      ...config,
      // pack: {
      //   files: [
      //     {
      //       type: "scenePlugin",
      //       key: "SpinePlugin",
      //       url: "/SpinePlugin.min.js",
      //       sceneKey: "spine",
      //     },
      //   ],
      // },
    });

    this.me = null;
    this.otherPlayers = {};

    // this.selected;
  }

  preload() {
    // this.load.atlas("gems", "gems.png", "gems.json");

    // make this fancier
    this.load.image("axie-eyes", "/images/skills/bird-eyes-02.png");
    this.load.image("axie-ears", "/images/skills/bird-ears-08.png");
    this.load.image("axie-mouth", "/images/skills/bird-mouth-08.png");
    this.load.image("axie-horn", "/images/skills/bird-horn-02.png");
    this.load.image("axie-tail", "/images/skills/bird-tail-06.png");
    this.load.image("axie-back", "/images/skills/bird-back-05.png");

    // this.rexUI = this.game.scene.getScene("default").rexUI;

    // this.load.scenePlugin(
    //   "rexuiplugin",
    //   "/rexuiplugin.min.js",
    //   "rexUI",
    //   "rexUI"
    // );
  }

  create() {
    const midX = this.cameras.main.width / 2;
    const midY = this.cameras.main.height / 2;

    this.bg = this.add.image(midX, midY, "battle-bg");
    this.bg.setScale(2);

    // this.plugins.get("rexHSLAdjustPipeline").add(this.bg, { lumAdjust: 1 });
    // this.bg.alpha = 0.4;

    this.bg.setDisplaySize(this.cameras.main.width, this.cameras.main.height);

    this.setupEventListeners();

    if (this.state) {
      this.setupState();
    }

    this.fadeMask = this.add.rectangle(
      midX,
      midY,
      this.cameras.main.width,
      this.cameras.main.height,
      0x000000
    );
    this.fadeMask.setInteractive();
    this.fadeMask.depth = 10;
    this.fadeMask.visible = false;
    this.fadeMask.alpha = 1;

    this.targetMask = this.add.rectangle(
      midX,
      midY,
      this.cameras.main.width,
      this.cameras.main.height,
      0x000000
    );
    this.targetMask.depth = 2;
    this.targetMask.visible = false;
    this.targetMask.alpha = 0;

    this.events.on("transitionstart", (from, duration) => {
      this.tweens.add({
        targets: this.fadeMask,
        alpha: 0,
        delay: duration * 0.25,
        duration: duration, // * 0.75,
        onStart: () => {
          this.fadeMask.visible = true;
          this.fadeMask.alpha = 1;
        },
        onComplete: () => {
          this.fadeMask.visible = false;
        },
      });
    });

    this.events.on("transitionout", (target, duration) => {
      this.tweens.add({
        targets: this.fadeMask,
        alpha: 1,
        duration: duration,
        onStart: () => {
          this.fadeMask.visible = true;
          this.fadeMask.alpha = 0;
        },
      });
    });

    this.events.on("destroy", () => {
      this.reset();
    });
  }

  setupEventListeners() {
    const glowPlugin = this.plugins.get("rexGlowFilterPipeline");
    const outlinePlugin = this.plugins.get("rexOutlinePipeline");

    this.input.on("gameobjectdown", (pointer, gameObject, event) => {
      if (
        this.state.gameState !== GAME_STATE.PLAYING ||
        this.me.state.status === PLAYER_STATE.GAMEOVER
      ) {
        return;
      }

      if (this.selectingTargetFor && gameObject.getData("target")) {
        this.castTargetedAbility(gameObject.getData("target"));
        return;
      }

      if (gameObject.getData("part-skill") && gameObject.ready) {
        this.game.events.emit("play-fx", SOUND_FX.SPELL_CLICK);
        this.events.emit("press-ability", gameObject.getData("part-skill"));
        return;
      }

      if (gameObject.parentContainer === this.me.board) {
        if (this.selectingBlockFor) {
          this.castTargetedBlockAbility(gameObject);
          return;
        } else {
          this.me.board.selectBlock(gameObject);
        }
      }

      if (gameObject.getData("mana") && gameObject.getData("active")) {
        this.events.emit("action", {
          type: "mana-effect",
          args: {
            color: gameObject.getData("mana"),
          },
        });
      }
    });

    this.input.on("gameobjectover", (pointer, gameObject, event) => {
      if (this.state.gameState !== GAME_STATE.PLAYING) {
        return;
      }

      if (gameObject.getData("mana")) {
        this.me.stats.toggleManaTooltip(gameObject.getData("mana"));
        return;
      }

      if (gameObject.getData("part-skill")) {
        this.me.stats.togglePartTooltip(gameObject.getData("part-skill"));
        return;
      }

      if (gameObject.parentContainer === this.me.board) {
        const glowPipeline = glowPlugin.add(gameObject);

        gameObject.glowTask = gameObject.scene.tweens.add({
          targets: glowPipeline,
          intensity: 0.0075,
          ease: "Sine.easeIn",
          duration: 300,
          repeat: -1,
          yoyo: true,
        });
      }
    });

    this.input.on("gameobjectout", (pointer, gameObject, event) => {
      if (this.state.gameState !== GAME_STATE.PLAYING) {
        return;
      }

      if (gameObject.getData("mana")) {
        this.me.stats.toggleManaTooltip(gameObject.getData("mana"), false);
        return;
      }

      if (gameObject.getData("part-skill")) {
        this.me.stats.togglePartTooltip(
          gameObject.getData("part-skill"),
          false
        );
        return;
      }

      if (!gameObject.glowTask) {
        return;
      }

      if (gameObject.parentContainer === this.me.board) {
        glowPlugin.remove(gameObject);
        gameObject.glowTask.stop();
        gameObject.glowTask = null;
      }
    });

    this.events.on("select-block", (block) => {
      outlinePlugin.add(block, {
        thickness: 1.5,
        outlineColor: 0x333333,
        quality: 0.05,
      });
    });

    this.events.on("deselect-block", (block) => {
      outlinePlugin.remove(block);
    });

    this.events.on("swap", (b1, b2) => {
      this.events.emit("action", { type: "swap", args: [b1, b2] });
    });

    this.rexGestures.add
      .swipe({
        dir: "4dir",
        velocityThreshold: 200,
        threshold: 20,
      })
      .on("swipe", (swipe) => {
        this.me.board.checkSwipe(
          ["left", "right", "up", "down"].reduce((acc, dir) =>
            swipe[dir] ? dir : acc
          )
        );
      });

    // update axies
    this.events.on("preupdate", () => {
      if (this.me && this.me.state.axie && this.me.stats) {
        this.me.stats.update();
      }
    });

    this.events.on("reaction", (type) => {
      this.me.stats.axie.reactTo(type);
    });

    this.events.on("press-ability", (part) => {
      const ability = this.me.state.axie[part];

      if (
        ability.casting ||
        this.selectingTargetFor === part ||
        this.selectingBlockFor === part
      ) {
        return;
      }

      if (this.selectingTargetFor || this.selectingBlockFor) {
        this.me.stats.deactivateSkillBox(
          this.selectingTargetFor || this.selectingBlockFor
        );
      }

      this.hideAbilityTargets();

      if (
        [
          EFFECTS_TARGET[EFFECTS_TARGET_NAMES.self],
          // EFFECTS_TARGET[EFFECTS_TARGET_NAMES.singleFriendly],
          EFFECTS_TARGET[EFFECTS_TARGET_NAMES.allFriendly],
          EFFECTS_TARGET[EFFECTS_TARGET_NAMES.allOpponents],
          EFFECTS_TARGET[EFFECTS_TARGET_NAMES.all],
        ].includes(ability.targetType)
      ) {
        this.game.events.emit("play-fx", SOUND_FX.SPELL_CLICK);
        ability.casting = true;
        this.events.emit("cast-ability", { part });
      } else if (
        ability.targetType ===
        EFFECTS_TARGET[EFFECTS_TARGET_NAMES.singleFriendly]
      ) {
        this.game.events.emit("play-fx", SOUND_FX.SPELL_CLICK);
        ability.casting = true;
        this.events.emit("cast-ability", {
          part,
          target: this.ownId,
        });
      } else if (
        ability.targetType === EFFECTS_TARGET[EFFECTS_TARGET_NAMES.block]
      ) {
        this.game.events.emit("play-fx", SOUND_FX.SPELL_ACTIVE);
        this.selectingBlockFor = part;

        this.toggleTargetMask(true);
        this.me.board.flashTarget();
        this.me.board.depth = 3;
        this.me.stats.activateSkillBox(part);
      } else {
        this.game.events.emit("play-fx", SOUND_FX.SPELL_ACTIVE);
        // mark as target needed
        this.selectingTargetFor = part;

        this.showAbilityTargets();
        this.me.stats.abilityPressed(part);
        this.me.stats.activateSkillBox(part);
      }
    });

    this.events.on("cancel-ability", () => {
      if (this.selectingBlockFor || this.selectingTargetFor) {
        this.me.stats.deactivateSkillBox(
          this.selectingBlockFor || this.selectingTargetFor
        );
        this.selectingBlockFor = null;
        tiles.selectingTargetFor = null;
      }

      this.toggleTargetMask(false);
      this.hideAbilityTargets();
    });

    this.events.on("cast-ability", (data) => {
      this.events.emit("cast", data);
    });
  }

  getFriendlyBoards() {
    return [this.me.board];
  }

  getOpponentBoards() {
    return Object.keys(this.otherPlayers).map((key) => {
      return this.otherPlayers[key].board;
    });
  }

  failCastAbility(part) {
    const ability = this.me.state.axie[part];
    ability.casting = false;
  }

  successCastAbility(part) {
    const ability = this.me.state.axie[part];
    ability.casting = false;
    this.me.stats.abilityExec(part);
  }

  castTargetedAbility(targetId) {
    if (!this.selectingTargetFor) {
      return;
    }

    const ability = this.me.state.axie[this.selectingTargetFor];
    ability.casting = true;

    this.me.stats.deactivateSkillBox(this.selectingTargetFor);

    this.events.emit("cast-ability", {
      part: this.selectingTargetFor,
      target: targetId,
    });

    this.hideAbilityTargets();
    this.selectingTargetFor = null;
  }

  castTargetedBlockAbility(block) {
    if (!this.selectingBlockFor) {
      return;
    }

    this.toggleTargetMask(false);

    const ability = this.me.state.axie[this.selectingBlockFor];
    ability.casting = true;

    this.me.stats.deactivateSkillBox(this.selectingBlockFor);

    this.events.emit("cast-ability", {
      part: this.selectingBlockFor,
      target: this.ownId,
      data: { blockCoords: { x: block.getData("x"), y: block.getData("y") } },
    });

    this.selectingBlockFor = null;
  }

  assignState(state, ownId) {
    this.state = state;
    this.ownId = ownId;
  }

  setupState() {
    async function addPlayerBoard(scene, playerState, key, layout) {
      const board = new Board(scene, 118, 28, key); // 18 leftmargin +  80 mana bar + 20 space + 252 board + 30 rightmargin
      const player = {};

      player.state = playerState;
      player.board = board;

      if (scene.ownId === key) {
        scene.me = player;
        board.setMine(true);
        board.setPosition(layout.mine.boardLeft, layout.vPadding);

        const stats = new StatsBoard(
          scene,
          layout.mine.containerleft + layout.mine.stats.padding,
          layout.vPadding + layout.mine.height + layout.mine.stats.marginTop,
          playerState
        ); // 18 leftMargin;
        player.stats = stats;
        stats.loadAxie(playerState.axie.axieId);

        for (let i = 0; i < playerState.life; i++) {
          stats.addHeart(i);
        }

        stats.depth = 4;

        playerState.onChange = (changes) => {
          changes.forEach((change) => {
            if (change.field.indexOf("Mana") >= 0) {
              // console.log("manan change", change.field, change.value);
              stats.updateMana(change.field, change.value);
              stats.checkAllAbilities();
            } else if (change.field === "life") {
              if (change.value > change.previousValue) {
                // console.log("more life", change.value, change.previousValue);
                stats.addHeart(change.value - 1);
              } else {
                stats.removeHeart(change.value);
                scene.game.events.emit("play-fx", SOUND_FX.VOICE_SCARED);

                if (change.value > 0) {
                  scene.resetLifeProgress();
                }
              }
            }
          });
        };

        playerState.specialEffects.onAdd = (value) => {
          scene.processSpecialEffect(value, true);
        };

        playerState.specialEffects.onRemove = (value) => {
          scene.processSpecialEffect(value, false);
        };

        // board.visible = false;
      } else {
        const opponentsCount = Object.keys(scene.otherPlayers).length;

        // set board position
        const boardSide = layout.getSide(opponentsCount);
        const boardRow = layout.getRow(opponentsCount);
        const opponentLeft =
          boardSide.side === "left"
            ? layout.hPadding +
              layout.fields.hPadding +
              boardSide.col * layout.fields.hSpacing +
              boardSide.col * layout.fields.width
            : layout.hPadding +
              layout.fields.hPadding +
              boardSide.col * (layout.fields.width + layout.fields.hSpacing) +
              layout.mine.width;
        const opponentTop =
          layout.vPadding +
          boardRow * layout.fields.height +
          boardRow * layout.fields.vSpacing;

        const stats = new StatsBoard(
          scene,
          opponentLeft + 14,
          opponentTop + 113,
          playerState,
          true
        );
        player.stats = stats;

        for (let i = 0; i < playerState.life; i++) {
          stats.addHeart(i);
        }

        board.setScale(layout.fields.scale);
        board.setPosition(opponentLeft, opponentTop);

        scene.otherPlayers[key] = player;

        playerState.onChange = (changes) => {
          changes.forEach((change) => {
            if (change.field === "life") {
              if (change.value > change.previousValue) {
                // console.log("more life", change.value, change.previousValue);
                stats.addHeart(change.value - 1);
              } else {
                stats.removeHeart(change.value);
              }
            }
          });
        };
      }

      playerState.axie.stats.onChange = (changes) => {
        changes.forEach((change) => {
          if (change.field === "shield") {
            if (change.value > 0) {
              if (playerState.sessionId === scene.ownId) {
                player.stats.showShield();
              } else {
                board.showShield();
              }
            } else {
              if (playerState.sessionId === scene.ownId) {
                player.stats.removeShield();
              } else {
                board.removeShield();
              }
            }
          }
        });
      };

      // board.setScale(0.5);

      // board.setSize(242, 482);
      board.depth = 1;

      scene.add.existing(board);

      board.setupGrid(playerState.board.grid);
      // board.setSpeed(playerState.speed);

      board.setStats(playerState.axie.stats);

      // update blocks in grid after status change
      playerState.board.grid.onChange = (value, position) => {
        const tile = board.parseGridValue(value);

        if (tile <= 5) {
          board.updateGridBlock(position, value);
        } else {
          // special block is added here
          board.addSpecial(position, value);
          // board.updateGridBlock(position, value, false);
        }
      };

      playerState.board.grid.onAdd = (value, position) => {
        const now = new Date().getTime();
        // console.log("add", board.getCoordinatesByPosition(position));
        if (isNaN(board.parseGridValue(value))) {
          console.log(">>>> player.board.grid.onAdd", value);
        }

        const tile = board.parseGridValue(value);
        if (tile <= 5) {
          if (now - board.lastAddSound > 100) {
            scene.game.events.emit("play-fx", SOUND_FX.ADD_BLOCK, {
              volume: board.mine ? 1 : 0.4,
            });
          }
          board.updateGridBlock(position, value);
        } else {
          board.addSpecial(position, value);
        }
      };

      // player.board.grid.onRemove = (value, position) => {
      //   console.log("remove", board.getCoordinatesByPosition(position));
      // };

      playerState.board.removeBlocks.onAdd = (value, position) => {
        // console.log(value, position);
        board.clearBlock(value);
      };

      // player.board.removeBlocks.onRemove = (value, p) => {
      //   console.log("remo", value, p);
      // };

      // check for player board status change
      playerState.board.listen("status", (current, previous) => {
        // console.log(">>>>>>>>> WTF", current, previous);
        board.checkStateStatus(current, previous);
        // console.log("status change", current, previous);
      });

      playerState.listen("status", (current, previous) => {
        if (current === PLAYER_STATE.GAMEOVER) {
          scene.showGameOver(playerState.sessionId);
        } else if (current === PLAYER_STATE.WINNER) {
          scene.showWinner(playerState.sessionId);
        }
      });
    }

    this.state.listen("gameState", (currentValue, previousValue) => {
      if (currentValue === GAME_STATE.STARTING) {
        this.startGame();
        this.me.stats.axie.reactTo("start-game");
      }

      if (currentValue === GAME_STATE.PLAYING) {
        setTimeout(() => {
          this.me.stats.startWallCounter();
          this.me.stats.startLifeCounter();
        }, 250);
      }
    });

    this.state.playerOrder.forEach((id) => {
      addPlayerBoard(
        this,
        this.state.players.get(id),
        id,
        Layouts[getLayout(this.state.players.size)]
      );
    });

    setTimeout(() => {
      if (this.state.gameState === GAME_STATE.STARTING) {
        this.startGame();
        this.me.stats.axie.reactTo("start-game");
      }
    }, 100);
  }

  toggleTargetMask(show = true) {
    if (this.targetMaskTween) {
      this.targetMaskTween.stop();
      this.targetMaskTween.remove();
    }

    this.targetMaskTween = this.tweens.add({
      targets: this.targetMask,
      alpha: show ? 0.8 : 0,
      duration: 250,
      onStart: () => {
        this.targetMask.visible = true;
        if (show) {
          this.targetMask.alpha = 0;
        } else {
          this.targetMask.alpha = 0.8;
        }
      },
      onComplete: () => {
        if (!show) {
          this.targetMask.visible = false;
          this.getFriendlyBoards().forEach((board) => {
            board.depth = 1;
          });
          this.getOpponentBoards().forEach((board) => {
            board.depth = 1;
          });
        }
      },
    });
  }

  showAbilityTargets() {
    if (this.selectingTargetFor) {
      const selectingAbility = this.me.state.axie[this.selectingTargetFor];
      if (
        selectingAbility.targetType ===
        EFFECTS_TARGET[EFFECTS_TARGET_NAMES.singleFriendly]
      ) {
        this.toggleTargetMask(true);
        this.getFriendlyBoards().forEach((board) => {
          board.showTarget();
          board.depth = 3;
        });
      } else if (
        selectingAbility.targetType ===
        EFFECTS_TARGET[EFFECTS_TARGET_NAMES.singleOpponent]
      ) {
        this.toggleTargetMask(true);
        this.getOpponentBoards().forEach((board) => {
          board.showTarget();
          board.depth = 3;
        });
      }
    }
  }

  hideAbilityTargets() {
    if (this.selectingTargetFor) {
      const selectingAbility = this.me.state.axie[this.selectingTargetFor];
      if (
        selectingAbility.targetType ===
        EFFECTS_TARGET[EFFECTS_TARGET_NAMES.singleFriendly]
      ) {
        this.toggleTargetMask(false);
        this.getFriendlyBoards().forEach((board) => {
          board.removeTarget();
          // board.depth = 1;
        });
      } else if (
        selectingAbility.targetType ===
        EFFECTS_TARGET[EFFECTS_TARGET_NAMES.singleOpponent]
      ) {
        this.toggleTargetMask(false);
        this.getOpponentBoards().forEach((board) => {
          board.removeTarget();
          // board.depth = 1;
        });
      }
    }
  }

  resetWallProgress() {
    this.me.stats.resetWallProgress();
  }

  resetLifeProgress() {
    this.me.stats.resetLifeProgress();
  }

  showGameOver(id) {
    let position;
    const isMe = this.ownId === id;
    if (isMe) {
      position = this.me.board.getBoardSceneCoordinates();
    } else {
      position = this.otherPlayers[id].board.getBoardSceneCoordinates();
    }

    this.events.emit("game-over", {
      msgX: position.x,
      msgY: position.y,
      isMe: this.ownId === id,
    });
    this.game.events.emit(
      "play-fx",
      isMe ? SOUND_FX.GAMEOVER_ME : SOUND_FX.GAMEOVER_OTHER
    );
  }

  showWinner(id) {
    let position;
    if (this.ownId === id) {
      position = this.me.board.getBoardSceneCoordinates();
    } else {
      position = this.otherPlayers[id].board.getBoardSceneCoordinates();
    }

    this.events.emit("winner", {
      msgX: position.x,
      msgY: position.y,
      isMe: this.ownId === id,
    });

    this.me.stats.reset();

    setTimeout(() => {
      this.game.events.emit("play-fx", SOUND_FX.WIN, {
        volume: this.mine ? 1 : 0.6,
      });
    }, 600);

    const winner = this.state.players.get(id);
  }

  castEffect(data) {
    if (!data.length) {
      return;
    }
    data.forEach((effect) => {
      console.log(effect);
      const isSourceMe = effect.source === this.ownId;
      const source = isSourceMe ? this.me : this.otherPlayers[effect.source];
      // console.log(
      //   ">>> CAST effect",
      //   effect,
      //   isSourceMe,
      //   source.stats.axie,
      //   source.stats.axie.canvas
      // );
      const sourcePosition = getWorldPosition(
        isSourceMe ? source.stats.axie.canvas : source.board.boardRect
      );
      const sourceDimensions = calcScaledDimensions(
        isSourceMe ? source.stats.axie.canvas : source.board.boardRect
      );

      if (isSourceMe) {
        this.me.stats.axie.reactTo("attack-" + effect.effectName);
      }

      Object.keys(effect.targets).forEach((key) => {
        // debugger;
        const isTargetMe = key === this.ownId;
        const targetPlayer = isTargetMe ? this.me : this.otherPlayers[key];
        const targetPosition = getWorldPosition(
          isTargetMe
            ? targetPlayer.stats.axie.canvas
            : targetPlayer.board.boardRect
        );
        const targetDimensions = calcScaledDimensions(
          isTargetMe
            ? targetPlayer.stats.axie.canvas
            : targetPlayer.board.boardRect
        );
        // get scaled width and height

        const defaultText = EFFECTS_TEXT.DEFAULT;
        const effectText =
          effect.args && effect.args.text
            ? effect.args.text
            : EFFECTS_TEXT[effect.effectName]
            ? EFFECTS_TEXT[effect.effectName]
            : {};
        const isHit = effect.targets[key];
        const castSound = EFFECTS_CAST_SOUND[effect.effectName]
          ? EFFECTS_CAST_SOUND[effect.effectName]
          : EFFECTS_CAST_SOUND.DEFAULT;
        if (castSound) {
          this.game.events.emit(
            "play-fx",
            castSound.fx,
            isSourceMe ? castSound.config : castSound.opponentConfig
          );
        }

        const hitSound =
          isHit === true
            ? effect.args && effect.args.fx && effect.args.fx.hit
              ? { fx: effect.args.fx.hit }
              : EFFECTS_HIT_SOUND[effect.effectName]
              ? EFFECTS_HIT_SOUND[effect.effectName]
              : EFFECTS_HIT_SOUND[`DEFAULT${getRandomInt(1, 2)}`]
            : isHit === EFFECTS_HIT.shielded
            ? EFFECTS_HIT_SOUND[EFFECTS_HIT.shielded]
            : EFFECTS_HIT_SOUND[`DEFAULT${getRandomInt(1, 2)}`];
        this.events.emit("show-effect", {
          name: effect.effectName,
          type: effect.type,
          castTime: effect.castTime,
          sourceX: sourcePosition.translateX + sourceDimensions.width / 2,
          sourceY: sourcePosition.translateY + sourceDimensions.height / 2,
          destX: targetPosition.translateX + targetDimensions.width / 2,
          destY: targetPosition.translateY + targetDimensions.height / 2,
          hit: isHit,
          isFriendly:
            effect.targetType <= EFFECTS_TARGET[EFFECTS_TARGET_NAMES.friendly],
          text:
            isHit === true
              ? effectText.hit || defaultText.hit
              : isHit === EFFECTS_HIT.shielded
              ? effectText.shielded || defaultText.shielded
              : isSourceMe
              ? effectText.evade || defaultText.evade
              : effectText.miss || defaultText.miss,
          onComplete: () => {
            if (hitSound) {
              this.game.events.emit(
                "play-fx",
                hitSound.fx,
                isTargetMe
                  ? hitSound.config
                  : isSourceMe
                  ? hitSound.opponentConfig
                  : {
                      volume: 0.4,
                    }
              );
            }

            if (isHit === EFFECTS_HIT.shielded) {
              if (isTargetMe) {
                targetPlayer.stats.removeShield();
              } else {
                targetPlayer.board.removeShield();
              }
            }
          },
        });

        if (isTargetMe) {
          if (isHit === EFFECTS_HIT.miss) {
            setTimeout(() => {
              this.game.events.emit("play-fx", SOUND_FX.EVADE);
            }, effect.castTime * 0.7);
          } else if (isHit === EFFECTS_HIT.hit && !isSourceMe) {
            setTimeout(() => {
              this.game.events.emit("play-fx", SOUND_FX.VOICE_HIT);
            }, effect.castTime * 0.7);
          } else if (isHit === EFFECTS_HIT.shielded) {
            setTimeout(() => {
              this.game.events.emit("play-fx", SOUND_FX.SHIELD_HIT);
            }, effect.castTime * 0.7);
          }

          this.me.stats.axie.reactTo(
            isHit ? "hit-" + effect.effectName : "evade",
            effect.castTime * 0.75
          );
        }
      });
    });
  }

  processSpecialEffect(name, add) {
    switch (name) {
      case "blur":
        this.me.board.toggleEffect(name, add);
        break;

      case "stun":
        this.me.stats.toggleEffect(name, add);
        break;

      case "free-roam":
        this.me.board.toggleSpecialStatus(add ? name : null);
        break;

      default:
        console.log("unknown special effect", name);
        break;
    }
  }

  cancelMove() {
    this.me.board.cancelMove();
  }

  startGame() {
    this.events.emit("start-game", this.state.startTime);
  }

  reset() {
    console.log("destroy world");
    this.targetMask.alpha = 0;
    this.me.state.onChange = null;
    this.me.state.specialEffects.onAdd = null;
    this.me.state.specialEffects.onRemove = null;
    this.me.state.axie.onChange = null;
    this.me.state.board.grid.onChange = null;
    this.me.state.board.grid.onAdd = null;
    this.me.state.board.removeBlocks.onAdd = null;

    Object.keys(this.otherPlayers).forEach((key) => {
      this.otherPlayers[key].state.board.grid.onChange = null;
      this.otherPlayers[key].state.board.grid.onAdd = null;
      this.otherPlayers[key].state.board.removeBlocks.onAdd = null;
    });

    this.input.removeAllListeners();
    this.events.removeAllListeners();
  }
}
