import Phaser from "phaser";
import { getRandomInt } from "../utils";

export const MUSIC_TRACKS = {
  track1: "audio/track1.ogg",
  select: "audio/select.ogg",
  track2: "audio/track2.ogg",
  track3: "audio/track3.ogg",
  track4: "audio/track4.ogg",
  track5: "audio/track5.ogg",
  track6: "audio/track6.ogg",
  winner: "audio/winner.ogg",
  funky_results: "audio/funky-results.ogg",
};

export const SOUND_FX = {
  ADD_BLOCK: "add-block",
  HOVER: "hover",
  MATCH_PLAYER_FOUND: "match-player-found",
  MATCH_FULL: "match-full",
  CLICK: "click",
  JUMP1: "jump1",
  JUMP2: "jump2",
  JUMP3: "jump3",
  JUMP4: "jump4",
  LOCK: "lock",
  MANA_1: "mana1",
  MANA_2: "mana2",
  MANA_3: "mana3",
  MANA_4: "mana4",
  WILDCARD: "wildcard",
  WILDCARD_CONSUME: "wildcard-consume",
  TICK: "tick",
  TOCK: "tock",
  WALL: "wall",
  BEEP: "beep",
  GO: "go",
  SIREN_LOOP: "siren-loop",
  SHOW_DETAILS: "show-details",
  MOVE: "move",
  SELECT_BLOCK: "select-block",
  CAST_WALL: "cast-wall",
  EVADE: "evade",
  ORB_READY: "orb-ready",
  VOICE_HIT: "voice-hit",
  VOICE_SCARED: "voice-scared",
  CAST_EFFECT: "cast-effect",
  FREEZE_HIT: "freeze-hit",
  SHIELD_HIT: "shield-hit",
  SHIELD_UP: "shield-up",
  STUN_CAST: "stun-cast",
  STUN_HIT: "stun-hit",
  GAMEOVER_OTHER: "gameover-other",
  GAMEOVER_ME: "gameover-me",
  WIN: "win",
  COLOR_SWAP: "color-swap",
  SPELL_ACTIVE: "spell-active",
  SPELL_CLICK: "spell-click",
  SPELL_READY: "spell-ready",
  DEFAULT_HIT1: "default-hit1",
  DEFAULT_HIT2: "default-hit2",
};

export const PRIORITY_LOAD = {
  [SOUND_FX.CLICK]: "audio/fx/click.ogg",
};

export const FX_FILES = {
  [SOUND_FX.HOVER]: "audio/fx/hover.ogg",
  [SOUND_FX.ADD_BLOCK]: "audio/fx/add-block.ogg",
  [SOUND_FX.MATCH_PLAYER_FOUND]: "audio/fx/match-player-found.ogg",
  [SOUND_FX.MATCH_FULL]: "audio/fx/match-full.ogg",
  [SOUND_FX.JUMP1]: "audio/fx/jump1.ogg",
  [SOUND_FX.JUMP2]: "audio/fx/jump2.ogg",
  [SOUND_FX.JUMP3]: "audio/fx/jump3.ogg",
  [SOUND_FX.JUMP4]: "audio/fx/jump4.ogg",
  [SOUND_FX.LOCK]: "audio/fx/lock.ogg",
  [SOUND_FX.MANA_1]: "audio/fx/mana1.ogg",
  [SOUND_FX.MANA_2]: "audio/fx/mana2.ogg",
  [SOUND_FX.MANA_3]: "audio/fx/mana3.ogg",
  [SOUND_FX.MANA_4]: "audio/fx/mana4.ogg",
  [SOUND_FX.WILDCARD]: "audio/fx/wildcard.ogg",
  [SOUND_FX.WILDCARD_CONSUME]: "audio/fx/wildcard-consume.ogg",
  [SOUND_FX.TICK]: "audio/fx/tick.ogg",
  [SOUND_FX.TOCK]: "audio/fx/tock.ogg",
  [SOUND_FX.WALL]: "audio/fx/wall.ogg",
  [SOUND_FX.BEEP]: "audio/fx/beep.wav",
  [SOUND_FX.GO]: "audio/fx/go.ogg",
  [SOUND_FX.SIREN_LOOP]: "audio/fx/siren-loop.ogg",
  [SOUND_FX.SHOW_DETAILS]: "audio/fx/show-details.ogg",
  [SOUND_FX.MOVE]: "audio/fx/move.ogg",
  [SOUND_FX.SELECT_BLOCK]: "audio/fx/select-block.ogg",
  [SOUND_FX.CAST_WALL]: "audio/fx/cast-wall.ogg",
  [SOUND_FX.EVADE]: "audio/fx/evade.ogg",
  [SOUND_FX.ORB_READY]: "audio/fx/orb-ready.ogg",
  [SOUND_FX.VOICE_HIT]: "audio/fx/voice-hit.ogg",
  [SOUND_FX.VOICE_SCARED]: "audio/fx/voice-scared.ogg",
  [SOUND_FX.CAST_EFFECT]: "audio/fx/voice-attack-laugh.ogg",
  [SOUND_FX.FREEZE_HIT]: "audio/fx/freeze-hit.ogg",
  [SOUND_FX.SHIELD_HIT]: "audio/fx/shield-hit.ogg",
  [SOUND_FX.SHIELD_UP]: "audio/fx/shield-up.ogg",
  [SOUND_FX.STUN_CAST]: "audio/fx/stun-cast.ogg",
  [SOUND_FX.STUN_HIT]: "audio/fx/stun-hit.ogg",
  [SOUND_FX.GAMEOVER_OTHER]: "audio/fx/gameover-other.ogg",
  [SOUND_FX.GAMEOVER_ME]: "audio/fx/gameover-me.ogg",
  [SOUND_FX.WIN]: "audio/fx/win.ogg",
  [SOUND_FX.COLOR_SWAP]: "audio/fx/color-swap.ogg",
  [SOUND_FX.SPELL_ACTIVE]: "audio/fx/spell-active.ogg",
  [SOUND_FX.SPELL_CLICK]: "audio/fx/spell-click.ogg",
  [SOUND_FX.SPELL_READY]: "audio/fx/spell-ready.ogg",
  [SOUND_FX.DEFAULT_HIT1]: "audio/fx/voice-hit2.ogg",
  [SOUND_FX.DEFAULT_HIT2]: "audio/fx/voice-hit3.ogg",
};

export class SoundScene extends Phaser.Scene {
  constructor(config) {
    super(config);

    this.volume = {
      general: 1,
      music: 1,
      fx: 1,
    };

    this.currentMusicTrack = "";
  }

  adjustSettings(settings) {
    this.adjustMusic(settings.music);
    this.adjustFX(settings.fx);
    this.adjustGeneral(settings.general);
  }

  adjustMusic(level) {
    this.volume.music = level;
    if (this.currentMusicTrack) {
      this.sound.get(this.currentMusicTrack).setVolume(this.volume.music);
    }
  }

  adjustFX(level) {
    this.volume.fx = level;
  }

  adjustGeneral(level) {
    this.volume.general = level;
    this.sound.setVolume(level);
  }

  playFX(key, { volume = 1, loop = false, play = true } = {}) {
    let fx = this.sound.get(key);

    if (!fx) {
      fx = this.sound.add(key, {
        volume: this.volume.fx,
        loop,
      });
    }

    if (play) {
      fx.setVolume(this.volume.fx * this.volume.general * volume);
      fx.play();
    }
  }

  stopFX(key) {
    this.sound.stopByKey(key);
  }

  playNextInList() {
    if (!this.musicList || !this.musicList.length) {
      return;
    }

    // let music = this.sound.get(this.musicList[this.musicListIndex]);
    // if (!music) {
    //   console.log("PLAY NEW MUSIC", this.musicList[this.musicListIndex]);
    //   music = this.playMusicTrack(this.musicList[this.musicListIndex], {
    //     loop: false,
    //   });
    // } else {
    //   console.log("PLAY EXISTING TRACK", this.musicList[this.musicListIndex]);
    //   music.play();
    // }
    const music = this.playMusicTrack(this.musicList[this.musicListIndex]);

    music.once("complete", (music) => {
      this.musicListIndex++;
      if (this.musicListIndex === this.musicList.length) {
        this.musicListIndex = 0;
      }

      this.playNextInList();
    });
  }

  playList(list) {
    this.musicList = list;
    this.musicListIndex = 0;
    this.playNextInList();
  }

  playMusicTrack(key, { loop = false, play = true } = {}) {
    let musicKey;
    if (!key) {
      const keys = Object.keys(MUSIC_TRACKS);
      musicKey = keys[getRandomInt(0, keys.length - 1)];
    } else {
      musicKey = key;
    }

    let music = this.sound.get(musicKey);

    if (!music) {
      music = this.sound.add(musicKey, {
        volume: this.volume.music,
        loop,
      });
    } else {
      // console.log(">>>>>>> PLAY EXISTING MUSIC", musicKey);
    }

    this.currentMusicTrack = musicKey;

    if (play) {
      music.setVolume(this.volume.music * this.volume.general);
      music.play();
    }

    return music;
  }

  stopCurrentMusicTrack() {
    this.sound.stopByKey(this.currentMusicTrack);
  }

  fadeOutMusic({ duration = 500, delay = 0 } = {}) {
    if (this.musicTween) {
      this.musicTween.stop();
    }

    this.musicTween = this.tweens.addCounter({
      from: this.volume.music,
      to: 0,
      duration,
      repeat: 0,
      delay: delay + 50,
      ease: "Linear",
      yoyo: false,
      onUpdate: (tween) => {
        this.sound.setVolume(tween.getValue());
      },
      onComplete: () => {
        this.stopCurrentMusicTrack();
      },
    });

    // setTimeout;
    // tween.play();
  }

  fadeInMusic({ duration = 500, delay = 0 } = {}) {
    if (this.musicTween) {
      this.musicTween.stop();
    }

    this.musicTween = this.tweens.addCounter({
      from: 0,
      to: this.volume.general,
      duration,
      delay: delay + 50,
      onUpdate: (tween) => {
        this.sound.setVolume(tween.getValue());
      },
    });
  }
}
