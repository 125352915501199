const apiUrl =
  process.env.REACT_APP_APIURL ||
  `${window.location.protocol}//${window.location.hostname}:2567`;

export function getRonNonce() {
  return fetch(apiUrl + "/user/ronnonce")
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }

      return response;
    })
    .then((response) => response.json())
    .then((respJson) => {
      if (respJson.error) {
        throw Error(respJson.error);
      }

      return respJson.data.nonce;
    })
    .catch((e) => {});
}

export function validateRonSignature(msg, nonce) {
  return fetch(apiUrl + "/user/validate", {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ msg, nonce }),
  })
    .then((response) => response.json())
    .then((respJson) => {
      if (respJson.error) {
        throw Error(respJson.error);
      } else {
        return respJson.data;
      }
    });
}

export function connectRonin(msg, nonce) {
  return fetch(apiUrl + "/user/connectRonin", {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": window.localStorage.getItem("token"),
    },
    body: JSON.stringify({ msg, nonce }),
  })
    .then((response) => response.json())
    .then((respJson) => {
      if (respJson.error) {
        throw Error(respJson.error);
      } else {
        return respJson.data;
      }
    });
}

export function signUp(email, password) {
  return fetch(apiUrl + "/user/signup", {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  })
    .then((response) => response.json())
    .then((respJson) => {
      if (respJson.error) {
        throw Error(respJson.error.message);
      } else {
        return respJson;
      }
    });
}

export function signIn(email, password) {
  return fetch(apiUrl + "/user/signin", {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  })
    .then((response) => response.json())
    .then((respJson) => {
      if (respJson.error) {
        throw Error(respJson.error.message);
      } else {
        return respJson.data;
      }
    });
}

export function getStarterAxies() {
  return fetch(apiUrl + "/blitz/starter-axies", {
    headers: {
      "x-access-token": window.localStorage.getItem("token"),
    },
  })
    .then((responce) => responce.json())
    .then((respJson) => {
      if (respJson.error) {
        throw Error(respJson.error);
      }
      return respJson.data.axies;
    });
}

export function getUserProfile() {
  return fetch(apiUrl + "/user/profile", {
    headers: {
      "x-access-token": window.localStorage.getItem("token"),
    },
  })
    .then((responce) => responce.json())
    .then((respJson) => {
      if (respJson.error) {
        throw Error(respJson.error);
      }

      return respJson.data;
    });
}

export function getUserAxies() {
  return fetch(apiUrl + "/user/myaxies", {
    headers: {
      "x-access-token": window.localStorage.getItem("token"),
    },
  })
    .then((responce) => responce.json())
    .then((respJson) => {
      if (respJson.error) {
        throw Error(respJson.error);
      }

      return respJson.data.axies;
    });
}

export function syncUserAxies() {
  return fetch(apiUrl + "/user/syncaxies", {
    method: "post",
    mode: "cors",
    headers: {
      "x-access-token": window.localStorage.getItem("token"),
    },
  })
    .then((response) => response.json())
    .then((respJson) => {
      if (respJson.error) {
        throw Error(respJson.error);
      } else {
        return respJson.data;
      }
    });
}

export function getInfo() {
  return fetch(apiUrl + "/info", {
    headers: {
      "x-access-token": window.localStorage.getItem("token"),
    },
  })
    .then((response) => response.json())
    .then((respJson) => {
      if (respJson.error) {
        throw Error(respJson.error);
      } else {
        return respJson.data;
      }
    });
}

export function getUserSettings() {
  return fetch(apiUrl + "/user/settings", {
    headers: {
      "x-access-token": window.localStorage.getItem("token"),
    },
  })
    .then((response) => response.json())
    .then((respJson) => {
      if (respJson.error) {
        throw Error(respJson.error);
      } else {
        return respJson.data.settings;
      }
    });
}

export function updateUserSettings(newSettings) {
  return fetch(apiUrl + "/user/settings", {
    method: "post",
    mode: "cors",
    headers: {
      "x-access-token": window.localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ newSettings: newSettings }),
  })
    .then((response) => response.json())
    .then((respJson) => {
      if (respJson.error) {
        throw Error(respJson.error);
      } else {
        return respJson.data.settings;
      }
    });
}

export function updateFavorite(axieId) {
  return fetch(apiUrl + "/user/favorite", {
    method: "post",
    mode: "cors",
    headers: {
      "x-access-token": window.localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ axieId }),
  })
    .then((response) => response.json())
    .then((respJson) => {
      if (respJson.error) {
        throw Error(respJson.error);
      } else {
        return respJson.data;
      }
    });
}

export function getAxieStats(axieId, abortController) {
  return fetch(apiUrl + "/blitz/axie/" + axieId, {
    signal: abortController.signal,
    headers: {
      "x-access-token": window.localStorage.getItem("token"),
    },
  })
    .then((response) => response.json())
    .then((respJson) => {
      if (respJson.error) {
        throw Error(respJson.error);
      } else {
        return respJson.data.axie;
      }
    });
}

export function getEnergy() {
  return fetch(apiUrl + "/user/energy", {
    headers: {
      "x-access-token": window.localStorage.getItem("token"),
    },
  })
    .then((response) => response.json())
    .then((respJson) => {
      if (respJson.error) {
        throw Error(respJson.error);
      } else {
        return respJson.data;
      }
    });
}
