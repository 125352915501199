import { CSSTransition } from "react-transition-group";
import Box from "../../components/Box";
import MenuButton from "../../components/Form/MenuButton";
import RoninButton from "../../components/Form/RoninButton";
import { TRANSITIONS } from "../../../../../constants";
import { getUserProfile } from "../../../gameSrc/helpers/api";
import styles from "../../ui.module.scss";
import mainMenuStyles from "./mainMenu.module.scss";
import { useState, useReducer, useEffect } from "react";
import { SOUND_FX } from "../../../gameSrc/sound/Sound";
import { DAOConnect } from "../../../gameSrc/helpers/login";
import { SERVER_STATUS } from "../../../gameSrc/constants";
import Maintenance from "./Maintenance";
import Alpha from "./Alpha";

const initState = {
  address: "",
  name: "",
};

const reducer = (state, action) => {
  let newState = { ...state };

  switch (action.type) {
    case "setProfile":
      newState = { ...action.data };
      break;

    case "password":
      newState.password = action.value;
      break;

    default:
      break;
  }

  return newState;
};

const MainMenu = ({ show, playSound, changeView, onLogout, energy, info }) => {
  const [hideWelcome, setHideWelcome] = useState(
    window.localStorage.getItem("hideWelcome")
  );
  const [state, dispatch] = useReducer(reducer, initState);
  const [connecting, sendConnect] = useState(false);
  // const [canJoin, setJoin] = useState(false);

  useEffect(() => {
    if (!show) {
      return;
    }

    // const axies = JSON.parse(window.localStorage.getItem("axies"));

    // if (axies && axies.length) {
    //   setJoin(true);
    // }

    async function callGetProfile() {
      const profile = await getUserProfile();

      dispatch({ type: "setProfile", data: profile });
    }
    callGetProfile();
  }, [show]);

  const handleConnect = async () => {
    sendConnect(true);
    playSound(SOUND_FX.CLICK, { volume: 0.25 });

    try {
      await DAOConnect();

      const profile = await getUserProfile();

      dispatch({ type: "setProfile", data: profile });
    } catch (e) {
      alert(e.message);
    }

    sendConnect(false);
  };

  return (
    <CSSTransition
      in={show}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      unmountOnExit
    >
      <div className={styles.screen}>
        {info &&
        info.gameStatus &&
        info.gameStatus === SERVER_STATUS.MAINTENANCE ? (
          <Maintenance />
        ) : hideWelcome ? (
          <>
            {!state.address && (
              <RoninButton
                text={"Connect your Ronin Wallet"}
                size="small"
                onClick={handleConnect}
                style={{ position: "absolute", top: 10, left: 20 }}
                disabled={connecting}
              />
            )}

            <Box
              className={[styles.center, styles.transparent].join(" ").trim()}
              style={{ width: 340, padding: "60px 60px 80px", top: "46.5%" }}
            >
              <img
                src="./images/axie-blitz.png"
                alt="logo"
                className={mainMenuStyles.logo}
              />
              <MenuButton
                text={"Join Game"}
                throttleMs={1000}
                disabled={!energy.count}
                onClick={() => {
                  playSound(SOUND_FX.CLICK, { volume: 0.25 });
                  changeView("game_selection");
                }}
                onMouseEnter={() => {
                  playSound(SOUND_FX.HOVER, { volume: 0.25 });
                }}
                style={{ marginBottom: 20 }}
              />
              <MenuButton
                text={"Profile"}
                // disabled={!validForm}
                throttleMs={1000}
                onClick={() => {
                  playSound(SOUND_FX.CLICK, { volume: 0.25 });
                  changeView("profile");
                }}
                onMouseEnter={() => {
                  playSound(SOUND_FX.HOVER, { volume: 0.25 });
                }}
                style={{ marginBottom: 20 }}
              />
              <MenuButton
                text={"Settings"}
                // disabled={!validForm}
                throttleMs={1000}
                onClick={() => {
                  playSound(SOUND_FX.CLICK, { volume: 0.25 });
                  changeView("settings");
                }}
                onMouseEnter={() => {
                  playSound(SOUND_FX.HOVER, { volume: 0.25 });
                }}
              />
            </Box>

            <div className={[mainMenuStyles.energyBox, styles.blitz].join(" ")}>
              <img src="images/ui/energy.png" alt="energy icon" />
              {energy.count}
            </div>

            <button
              className={mainMenuStyles.logout}
              onClick={() => {
                playSound(SOUND_FX.CLICK, { volume: 0.25 });
                onLogout();
              }}
              onMouseEnter={() => {
                playSound(SOUND_FX.HOVER, { volume: 0.25 });
              }}
            >
              <img src="images/ui/logout.png" alt="logout" />
            </button>
          </>
        ) : (
          <Alpha
            onClick={(value) => {
              if (value === true) {
                window.localStorage.setItem("hideWelcome", true);
              } else {
                window.localStorage.removeItem("hideWelcome");
              }
            }}
            onClose={() => {
              setHideWelcome(true);
            }}
          />
        )}
      </div>
    </CSSTransition>
  );
};

export default MainMenu;
