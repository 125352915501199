import styles from "../ui.module.scss";

const Box = ({ children, className, ...rest }) => {
  return (
    <div className={[styles.box, className].join(" ").trim()} {...rest}>
      {children}
    </div>
  );
};

export default Box;
