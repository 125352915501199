import Phaser from "phaser";
import { SOUND_FX } from "./sound/Sound";
import { Button } from "./components/Button";

export class UIScene extends Phaser.Scene {
  constructor(config) {
    super(config);

    this.currentNumber = null;
    // document.addEventListener("visibilitychange", () => {
    //   if (document.visibilityState === "visible") {
    //     const now = new Date().getTime();

    //     if (this.targetStart + this.serverTimeDiff > now) {
    //       this.start();
    //     }
    //     // if (this.hiddenTime) {
    //     //   this.currentCount += new Date().getTime() - this.hiddenTime;
    //     //   this.hiddenTime = 0;
    //     //   // if (this.outItem) {
    //     //   //   this.outItem.alpha = 0;
    //     //   // }
    //     //   // if (this.activeItem) {
    //     //   //   this.activeItem.alpha = 0;
    //     //   // }
    //     //   this.children.setAll("alpha", 0);
    //     //   if (
    //     //     Math.ceil(
    //     //       (this.targetStart + this.serverTimeDiff - this.currentCount) /
    //     //         1000
    //     //     ) >= 0
    //     //   ) {
    //     //     this.updateCount(
    //     //       Math.ceil(
    //     //         (this.targetStart + this.serverTimeDiff - this.currentCount) /
    //     //           1000
    //     //       )
    //     //     );
    //     //   } else {
    //     //     this.started = false;
    //     //   }
    //     // }
    //   } else {
    //     if (this.started) {
    //       this.clearTimers();
    //     }
    //   }
    // });
  }

  create() {
    const rect = this.add.rectangle(0, 0, 242, 482, 0x333333, 0.3);
    rect.setDisplaySize(this.cameras.main.width, this.cameras.main.height);
    rect.setOrigin(0, 0);
    // this.setS;

    this.readyText = this.createText("Ready?!");
    this.readyText.alpha = 0;
    this.goText = this.createText("Go!!");
    this.goText.alpha = 0;

    this.numbers = [this.createText("0"), this.createText("0")];
    this.numberPoolIndex = 0;

    // this.events.on("update", (time, delta) => {
    //   if (this.started) {
    //     this.currentCount += delta;

    //     this.updateCount(
    //       Math.ceil(
    //         (this.targetStart + this.serverTimeDiff - this.currentCount) / 1000
    //       )
    //     );
    //   }
    // });

    // TODO set this from config
    this.showPing = true;
    this.pingTimes = [];
    this.serverTimeDiff = 0;

    this.continueBtn = new Button(
      this,
      this.cameras.main.width / 2,
      this.cameras.main.height / 2 + 100,
      {
        text: "CONTINUE",
      }
    );
    this.continueBtn.visible = false;
    this.continueBtn.onClick(() => {
      this.reset();
      this.events.emit("show-results");
    });

    this.leaveBtn = new Button(
      this,
      this.cameras.main.width / 2,
      this.cameras.main.height / 2 + 240,
      {
        text: "LEAVE",
      }
    );
    this.leaveBtn.visible = false;
    this.leaveBtn.onClick(() => {
      this.reset();
      this.events.emit("leave-waiting");
    });

    this.leaveBtn.alpha = 0;
  }

  createText(text, visible = false) {
    const midX = this.cameras.main.width / 2;
    const midY = this.cameras.main.height / 2;

    const copy = this.make
      .text({
        x: midX - 2,
        y: midY - 50,
        text,
        style: {
          fontFamily: "Blaka",
          fontSize: "160px",
          stroke: "#fff700",
          strokeThickness: 16,
          fill: "#2094f3",
        },
        align: "center",
      })
      .setOrigin(0.5);

    copy.visible = visible;
    copy.alpha = 0;
    copy.scale = 0.2;
    return copy;
  }

  showNumber(number) {
    console.log(number, this.currentNumber);
    if (number > 0) {
      if (this.currentNumber === null) {
        this.readyText.scale = 1;
        this.readyText.alpha = 1;
        this.readyText.visible = true;

        this.currentNumber = number;
        // console.log("play", SOUND_FX.SIREN_LOOP);
        this.game.events.emit("play-fx", SOUND_FX.SIREN_LOOP);
      }

      if (number !== this.currentNumber && number > 5) {
        this.currentNumber = number;
        // console.log("play", SOUND_FX.SIREN_LOOP);
        this.game.events.emit("play-fx", SOUND_FX.SIREN_LOOP);
      } else if (number !== this.currentNumber) {
        this.currentNumber = number;
        // console.log("play", SOUND_FX.BEEP);
        this.game.events.emit("play-fx", SOUND_FX.BEEP);
      }
    }
  }

  hideNumber() {
    this.readyText.scale = 0;
    this.readyText.alpha = 0;
    this.readyText.visible = false;
  }

  start(startTime, countdownStartSeconds) {
    this.started = true;

    if (this.timers && this.timers.length) {
      this.clearTimers();
    } else {
      this.timers = [];
    }

    // this.currentCount = new Date().getTime();
    if (startTime) {
      this.targetStart = startTime;
    }

    this.numbers = [];
    for (let i = 0; i < 5; i++) {
      const number = this.createText((5 - i).toString());
      number.alpha = 0;
      number.scale = 0.2;
      this.numbers.push(number);
    }

    const now = new Date().getTime();
    let firstDelay = this.targetStart + this.serverTimeDiff - 5000 - now;
    // console.log("firstDelay", firstDelay);
    if (this.targetStart + this.serverTimeDiff <= now) {
      this.started = false;
      return;
    }

    if (firstDelay > 750) {
      this.readyText.scale = 1;
      this.readyText.alpha = 1;
      this.readyText.visible = true;
    }

    if (firstDelay > 1000) {
      // this.game.events.emit("play-fx", SOUND_FX.SIREN_LOOP);
      const sirenRepeats = Math.floor(firstDelay / 1000);
      console.log("sirenRepeats", sirenRepeats);

      if (sirenRepeats > 1) {
        this.timers.push(
          this.time.addEvent({
            delay: Math.round(firstDelay / sirenRepeats),
            callback: () => {
              console.log("repeat");
              this.game.events.emit("play-fx", SOUND_FX.SIREN_LOOP);
            },
            repeat: sirenRepeats - 2,
          })
        );
      }
    }

    let numberIndex = 0;
    if (firstDelay < 0) {
      firstDelay = (this.targetStart + this.serverTimeDiff - now) % 1000;
      numberIndex =
        5 - Math.floor((this.targetStart + this.serverTimeDiff - now) / 1000);
      console.log("firstD", firstDelay, numberIndex);
    }

    this.timers.push(
      this.time.delayedCall(
        firstDelay > 200 ? firstDelay - 200 : firstDelay,
        () => {
          this.readyText.alpha = 0;
          this.readyText.visible = false;

          if (this.numbers[numberIndex]) {
            this.numbers[numberIndex].visible = true;
            this.numbers[numberIndex].scale = 1.2;
            this.numbers[numberIndex].alpha = 1;
            this.game.events.emit("play-fx", SOUND_FX.BEEP);
          }

          if (this.numbers && this.numbers.length) {
            this.timers.push(
              this.time.addEvent({
                delay: 1000,
                callback: () => {
                  const number = this.numbers[numberIndex];
                  number.visible = false;
                  number.alpha = 0;
                  numberIndex++;

                  if (this.numbers[numberIndex]) {
                    this.numbers[numberIndex].visible = true;
                    this.numbers[numberIndex].alpha = 1;
                    this.numbers[numberIndex].scale = 1;
                    this.game.events.emit("play-fx", SOUND_FX.BEEP);
                  } else {
                    // this.game.events.emit("play-fx", SOUND_FX.GO);
                    this.goText.visible = true;
                    this.goText.alpha = 1;
                    this.goText.scale = 1;

                    this.timers.push(
                      this.time.delayedCall(350, () => {
                        if (document.visibilityState === "visible") {
                          this.tweens.add({
                            targets: [this.goText],
                            scale: 1.2,
                            alpha: 0,
                            duration: 250,
                            onComplete: () => {
                              this.goText.visible = false;
                              this.goText.scale = 0.2;

                              this.clearTimers();
                            },
                          });
                        } else {
                          this.goText.visible = false;
                          this.goText.alpha = 0;
                        }
                      })
                    );
                  }
                },
                repeat: this.numbers.length - 1,
              })
            );
          }
        }
      )
    );
    //   timeline.add({
    //     targets: this.readyText,
    //     scale: 1,
    //     alpha: 1,
    //     duration: 500,
    //     onStart: () => {
    //       this.readyText.visible = true;
    //     },
    //   });
    //   timeline.add({
    //     targets: this.readyText,
    //     scale: 1.2,
    //     alpha: 0,
    //     duration: 250,
    //     delay: firstDelay - 300,
    //     onComplete: () => {
    //       this.readyText.visible = false;
    //     },
    //   });
    // }

    // for (let j = 0; j < 5; j++) {
    //   let delay = j === 0 ? firstDelay - 200 : 0;
    //   timeline.add({
    //     targets: numbers[j],
    //     scale: 1,
    //     alpha: 1,
    //     duration: 400,
    //     delay,
    //     offset: j === 0 ? 0 : "-=150",
    //     onStart: () => {
    //       numbers[j].visible = true;
    //     },
    //   });
    //   timeline.add({
    //     targets: numbers[j],
    //     scale: 1.2,
    //     alpha: 0,
    //     duration: 250,
    //     delay: 500,
    //     onComplete: () => {
    //       numbers[j].visible = false;
    //     },
    //   });
    // }

    // timeline.add({
    //   targets: this.goText,
    //   scale: 1,
    //   alpha: 1,
    //   duration: 200,
    //   offset: "-=200",
    //   onStart: () => {
    //     this.goText.visible = true;
    //   },
    // });
    // timeline.add({
    //   targets: this.goText,
    //   scale: 1.2,
    //   alpha: 0,
    //   duration: 200,
    //   onComplete: () => {
    //     this.goText.visible = false;
    //   },
    // });

    // timeline.on("start", () => {
    //   console.log("start", new Date().getTime(), numbers);
    //   this.readyText.alpha = 0;
    //   this.readyText.scale = 0.2;
    //   this.goText.alpha = 0;
    //   this.goText.scale = 0.2;
    // });

    // timeline.play();
    // console.log(timeline);
  }

  clearTimers() {
    if (!this.timers || !this.timers.length) {
      return;
    }

    this.timers.forEach((timer) => {
      timer.remove();
      this.time.removeAllEvents();
    });

    this.timers = [];
    this.readyText.scale = 0.2;
    this.readyText.visible = false;
    this.readyText.alpha = 0;
    this.goText.scale = 0.2;
    this.goText.alpha = 0;

    if (this.numbers && this.numbers.length) {
      this.numbers.forEach((number) => {
        number.destroy();
      });
    }
  }

  connect(room) {
    this.room = room;
    this.room.onMessage("pong", (data) => {
      const now = new Date().getTime();
      this.pingTimes.unshift(new Date().getTime() - data.ct);
      this.pingTimes.splice(10);
      this.serverTimeDiff = now - data.st - Math.floor(this.getPingAvg());
      // console.log("this.serverTimeDiff", this.serverTimeDiff);

      if (this.game.settings.game.ping) {
        this.updatePingMsg();
      }
    });
  }

  startPing() {
    this.pinging = true;

    setInterval(() => {
      if (this.pinging && this.room) {
        this.room.send("ping", new Date().getTime());
      }
    }, 1000);
  }

  getPingAvg() {
    return (
      this.pingTimes.reduce((acc, val) => acc + val, 0) / this.pingTimes.length
    );
  }

  updatePingMsg() {
    if (!this.pingText) {
      this.pingText = this.make.text({
        x: this.cameras.main.width - 5,
        y: 5,
        add: true,
      });
      this.pingText.setOrigin(1, 0);
    }
    // console.log(
    //   this.pingTimes.reduce((acc, val) => acc + val, 0),
    //   this.pingTimes.length,
    //   this.pingTimes
    // );
    this.pingText.setText(`${Math.floor(this.getPingAvg())}ms`);
  }

  showContinue() {
    this.tweens.add({
      targets: this.continueBtn,
      alpha: 1,
      onStart: () => {
        this.continueBtn.visible = true;
        this.continueBtn.alpha = 0;
      },
    });
  }

  showLeave() {
    this.tweens.add({
      targets: this.leaveBtn,
      alpha: 1,
      duration: 250,
      onStart: () => {
        this.leaveBtn.visible = true;
        this.leaveBtn.alpha = 0;
      },
    });
  }

  hideLeave() {
    this.tweens.add({
      targets: this.leaveBtn,
      alpha: 0,
      duration: 200,
      onStart: () => {
        this.leaveBtn.visible = false;
        this.leaveBtn.alpha = 1;
      },
    });
  }

  reset() {
    // document.removeEventListener("visibilitychange");
    this.room = null;
    this.pinging = false;
    this.showPing = false;
    this.currentNumber = null;
    this.tweens.add({
      targets: [this.continueBtn, this.leaveBtn],
      alpha: 0,
      onComplete: () => {
        this.continueBtn.visible = false;
        this.continueBtn.alpha = 1;
      },
    });
  }
}
