import React, { useEffect, useRef, useState, useReducer } from "react";
import { useColyseusClient } from "../../helpers/UseColyseusClient";
import { SERVER_STATUS } from "./gameSrc/constants";
import { GAME_STATE } from "./Game";
import { createGame } from "./gameSrc";
import UI from "./UI";

import styles from "./Room.module.scss";
import { getEnergy, getInfo } from "./gameSrc/helpers/api";
import useInterval from "../../helpers/useInterval";

const initState = {
  view: "login",
  loggedIn: false,
  gameState: {},
  energy: { count: 0, lastHourCheck: -1 },
  info: { gameStatus: SERVER_STATUS.OK },
};

const reducer = (state, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "change_view":
      newState.view = action.value;
      break;

    case "login":
      newState.loggedIn = action.value;
      break;

    case "update_game":
      if (action.gameAction === "player_count") {
        newState.gameState.playerCount = action.value;
      }
      break;

    case "reset_game":
      newState.gameState = {};
      break;

    case "update_energy":
      newState.energy.count = action.value;
      newState.energy.lastHourCheck = new Date().getHours();
      break;

    case "update_info":
      newState.info = action.value;
      break;

    default:
      break;
  }

  return newState;
};

const GameRoom = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const roomRef = useRef();
  const gameRef = useRef();
  const client = useColyseusClient();
  const containerSize = window.localStorage.getItem("size") || "1920";

  useEffect(() => {
    if (roomRef.current) {
      gameRef.current = createGame(roomRef.current, client, dispatch);
    }
  }, [roomRef]);

  useEffect(() => {
    if (gameRef.current) {
      gameRef.current.changeLogin(state.loggedIn);
    }
  }, [state.loggedIn]);

  const handleSound = (name, config) => {
    if (gameRef.current) {
      gameRef.current.playSound(name, config);
    }
  };

  const requestEnergy = async () => {
    const data = await getEnergy();

    dispatch({ type: "update_energy", value: data.bonus_energy + data.energy });
  };

  const requestInfo = async () => {
    const data = await getInfo();

    console.log(data);
    dispatch({ type: "update_info", value: data });
  };

  useInterval(() => {
    if (!state.loggedIn) {
      return;
    }

    const now = new Date().getHours();

    if (now !== state.energy.lastHourCheck) {
      requestEnergy();
    }
  }, 15000);

  useInterval(() => {
    if (!state.loggedIn) {
      return;
    }

    requestInfo();
  }, 120000);

  return (
    <div ref={roomRef} className={`game-wrapper size-${containerSize}`}>
      <UI
        view={state.view}
        changeLogin={(status) => {
          dispatch({ type: "login", value: status });
          if (status) {
            requestEnergy();
            requestInfo();
            dispatch({ type: "change_view", value: "" });
          } else {
            gameRef.current.logout();
          }
        }}
        changeView={(newView) => {
          dispatch({ type: "change_view", value: newView });
        }}
        onJoinMatch={() => {
          requestEnergy();
        }}
        playSound={handleSound}
        game={gameRef}
        gameState={state.gameState}
        energy={state.energy}
        info={state.info}
      />
    </div>
  );
};

export default GameRoom;
