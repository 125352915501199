import Phaser from "phaser";
import { SOUND_FX } from "../sound/Sound";

export class ToggleButton extends Phaser.GameObjects.Container {
  constructor(scene, x, y, config) {
    super(scene, x, y);

    scene.add.existing(this);
    this.config = Object.assign({ throttle: 250 }, config);

    this.state = !!config.state;
    this.offState = scene.make.image({
      x: 0,
      y: 0,
      key: "toggle-off",
      add: true,
    });
    this.offState.depth = 5;
    this.add(this.offState);

    this.offStateText = this.scene.make.text({
      x: 24,
      y: 0,
      text: config.offText || config.text,
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "24px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#ffffff",
      },
      add: true,
      origin: {
        x: 0,
        y: 0.5,
      },
    });
    this.offStateText.depth = 5;
    this.add(this.offStateText);

    this.activeState = scene.make.image({
      x: 0,
      y: 0,
      key: "toggle-on",
      add: true,
    });
    this.activeState.depth = 5;
    this.add(this.activeState);

    this.activeStateText = scene.make.text({
      x: 24,
      y: 0,
      text: config.onText || config.text,
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "24px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#ffffff",
      },
      add: true,
      origin: {
        x: 0,
        y: 0.5,
      },
    });
    this.activeStateText.depth = 5;
    this.add(this.activeStateText);

    this.setInteractive(
      new Phaser.Geom.Rectangle(
        -20,
        config.height ? -config.height / 2 : -30,
        config.width || 200,
        config.height || 60
      ),
      Phaser.Geom.Rectangle.Contains
    );

    this.enabled = true;
    this.lastPressed = 0;
    this.setListeners();
    this.showState();
  }

  setListeners() {
    this.scene.input.on("gameobjectover", (pointer, gameObject, event) => {
      if (gameObject === this) {
        this.scene.game.events.emit("play-fx", SOUND_FX.HOVER);
      }
    });

    this.scene.input.on("gameobjectdown", (pointer, gameObject, event) => {
      if (gameObject === this && this.enabled) {
        this.onClick();
      }
    });
  }

  onClick(fn) {
    const clickThrottle = this.config.throttle;
    const now = new Date().getTime();

    if (fn) {
      this.onClickFn = fn;
      return;
    }

    if (now - this.lastPressed < clickThrottle) {
      return;
    }

    this.lastPressed = now;
    this.scene.game.events.emit("play-fx", SOUND_FX.CLICK);

    this.state = !this.state;
    this.showState();

    if (this.onClickFn) {
      this.onClickFn(this.state);
    }
  }

  setState(state) {
    this.state = state;
    this.showState();
  }

  showState() {
    if (this.state) {
      this.activeState.visible = true;
      this.activeStateText.visible = true;
      this.offState.visible = false;
      this.offStateText.visible = false;
    } else {
      this.activeState.visible = false;
      this.activeStateText.visible = false;
      this.offState.visible = true;
      this.offStateText.visible = true;
    }
  }

  toggle(enabled = true) {
    this.enabled = enabled;
    // this.hlsPipeline.setSatAdjust(enabled ? 1 : 0.5);
    // this.hlsPipeline.setLumAdjust(enabled ? 0.5 : 0.6);
  }
}
