import Phaser from "phaser";
import { SOUND_FX } from "../sound/Sound";

export class Button extends Phaser.GameObjects.Container {
  constructor(scene, x, y, config) {
    super(scene, x, y);

    scene.add.existing(this);
    this.config = Object.assign({ throttle: 1000 }, config);

    this.defaultState = scene.make.image({
      x: 0,
      y: 0,
      key: "menu-button",
      scale: {
        x: 0.165,
        y: 0.12,
      },
      add: true,
    });
    this.defaultState.depth = 5;
    this.add(this.defaultState);

    this.defaultStateText = this.scene.make.text({
      x: 0,
      y: 0,
      text: config.text,
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "20px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#ffffff",
        wordWrap: {
          width: 180,
        },
        fixedWidth: 180,
        align: "center",
      },
      add: true,
      origin: {
        x: 0.5,
        y: 0.5,
      },
    });
    this.defaultStateText.depth = 5;
    this.add(this.defaultStateText);

    this.activeState = scene.make.image({
      x: 0,
      y: 0,
      key: "menu-button-active",
      scale: {
        x: 0.165,
        y: 0.12,
      },
      add: true,
    });
    this.activeState.depth = 5;
    this.activeState.visible = false;
    this.add(this.activeState);

    this.activeStateText = scene.make.text({
      x: 0,
      y: 0,
      text: config.text,
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "20px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#ffffff",
        wordWrap: {
          width: 180,
        },
        fixedWidth: 180,
        align: "center",
      },
      add: true,
      origin: {
        x: 0.5,
        y: 0.5,
      },
    });
    this.activeStateText.depth = 5;
    this.activeStateText.visible = false;
    this.add(this.activeStateText);

    this.setInteractive(
      new Phaser.Geom.Rectangle(-100, -30, 200, 60),
      Phaser.Geom.Rectangle.Contains
    );

    this.enabled = true;
    this.pressed = false;
    this.lastPressed = 0;
    this.setListeners();

    this.hlsPipeline = this.scene.plugins.get("rexHSLAdjustPipeline").add(this);
  }

  setListeners() {
    this.scene.input.on("gameobjectover", (pointer, gameObject, event) => {
      if (gameObject === this && this.enabled) {
        this.scene.game.events.emit("play-fx", SOUND_FX.HOVER);
        this.defaultState.alpha = 0.9;
        this.defaultStateText.alpha = 0.9;
      }
    });

    this.scene.input.on("gameobjectout", (pointer, gameObject, event) => {
      if (gameObject === this && this.enabled) {
        this.defaultState.alpha = 1;
        this.defaultStateText.alpha = 1;
      }
    });

    this.scene.input.on("gameobjectdown", (pointer, gameObject, event) => {
      if (gameObject === this && this.enabled) {
        this.pressed = true;
        this.activeState.visible = true;
        this.activeStateText.visible = true;
      }
    });

    this.scene.input.on("gameobjectup", (pointer, gameObject, event) => {
      if (gameObject === this && this.enabled) {
        this.pressed = false;
        this.activeState.visible = false;
        this.activeStateText.visible = false;

        this.onClick();
      }
    });
  }

  onClick(fn) {
    const clickThrottle = this.config.throttle;
    const now = new Date().getTime();

    if (fn) {
      this.onClickFn = fn;
      return;
    }

    if (now - this.lastPressed < clickThrottle) {
      return;
    }

    this.lastPressed = now;

    this.scene.game.events.emit("play-fx", SOUND_FX.CLICK);

    if (this.onClickFn) {
      this.onClickFn();
    }
  }

  toggle(enabled = true) {
    this.enabled = enabled;
    this.hlsPipeline.setSatAdjust(enabled ? 1 : 0.5);
    this.hlsPipeline.setLumAdjust(enabled ? 0.5 : 0.6);
  }
}
