import { DAOlogin, MetamaskLogin } from "./helpers/login";
import { FX_FILES, MUSIC_TRACKS, PRIORITY_LOAD, SOUND_FX } from "./sound/Sound";

export function PreloadScene() {
  this.signingIn = false;
  this.loginDone = false;

  this.load.rexWebFont({
    google: {
      families: ["Luckiest Guy", "Secular One"],
    },
  });

  Object.keys(PRIORITY_LOAD).forEach((fx) => {
    this.load.audio(fx, PRIORITY_LOAD[fx]);
  });

  this.load.image("main-image", "images/main.jpg");

  // this.load.image("ronin-logo", "images/ronin-white.png");
  // this.load.image("metamask-logo", "images/metamask.png");

  this.load.image("loading-bar", "images/ui/loading-bar.png");
  this.load.image("loading-glare", "images/ui/loading-glare.png");
  this.load.image("loading-box", "images/ui/loading-box.png");

  this.input.setDefaultCursor(`url(images/cursor.png), pointer`);

  const midX = this.cameras.main.width / 2;
  const midY = this.cameras.main.height / 2;

  if (this.loadComplete) {
    if (this.scene.isActive("default")) {
      this.loadingText.alpha = 0;
      this.loadingBox.alpha = 0;
      this.loadingCanvas.alpha = 0;
      this.loadingGlare.alpha = 0;
    }
  }

  const completeLoad = () => {
    this.loadingBox = this.make.image({
      x: midX,
      y: midY + 290,
      key: "loading-box",
      add: true,
    });

    const barCanvas = this.textures.createCanvas("loading-canvas", 677, 75);
    const ctx = barCanvas.getContext();
    const barImg = this.textures.get("loading-bar").getSourceImage();

    this.loadingCanvas = this.make.image({
      x: midX + 1,
      y: midY + 289,
      key: "loading-canvas",
      add: true,
    });

    this.loadingGlare = this.make.image({
      x: midX + 2,
      y: midY + 267,
      key: "loading-glare",
      add: true,
    });

    this.loadingText = this.make.text({
      x: midX,
      y: midY + 290,
      text: "loading . . .",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "32px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#ffffff",
        align: "center",
      },
      add: true,
      origin: {
        x: 0.5,
        y: 0.5,
      },
    });

    this.sound.pauseOnBlur = false;

    // load rest
    this.load.rexWebFont({
      google: {
        families: ["Blaka", "Do Hyeon"],
      },
    });
    Object.keys(MUSIC_TRACKS).forEach((track) => {
      this.load.audio(track, MUSIC_TRACKS[track]);
    });
    Object.keys(FX_FILES).forEach((fx) => {
      this.load.audio(fx, FX_FILES[fx]);
    });

    this.load.image("heart", "images/heart.png");
    this.load.image("down-arrow", "images/ui/down-arrow.png");
    this.load.image("toggle-on", "images/ui/on.png");
    this.load.image("toggle-off", "images/ui/off.png");
    this.load.image("battle-bg", "images/battle-bg.png");
    this.load.image("select-bg", "images/select-bg.png");
    this.load.image("menu-button", "images/ui/menu-button.png");
    this.load.image("menu-button-active", "images/ui/menu-button-over.png");
    this.load.image("menu-selection", "images/ui/menu-bg.png");
    this.load.image("menu-green-button", "images/ui/green-button.png");
    this.load.image("selection-details", "images/ui/selection-details.png");
    this.load.image("menu-thumb", "images/ui/thumb.png");
    this.load.image("small-thumb", "images/ui/thumb-small.png");
    this.load.image("body-placeholder", "images/body-placeholder.png");
    this.load.image("skill-mask", "images/skill-mask.png");
    this.load.image("skills-container", "images/skills-container.png");
    this.load.image("skills-circle", "/images/skills-circle.png");
    this.load.image("skill-box", "/images/skill-box.png");
    this.load.image("skill-key", "/images/skill-key.png");
    this.load.image("skill-placeholder", "images/ui/skill-placeholder.png");
    this.load.image("blue-skill-mana", "images/blue-skill-mana.png");
    this.load.image("green-skill-mana", "images/green-skill-mana.png");
    this.load.image("pink-skill-mana", "images/pink-skill-mana.png");
    this.load.image("purple-skill-mana", "images/purple-skill-mana.png");
    this.load.image("red-skill-mana", "images/red-skill-mana.png");
    this.load.image("yellow-skill-mana", "images/yellow-skill-mana.png");
    this.load.image("dawn-tag", "images/dawn.png");
    this.load.image("mech-tag", "images/mech.png");
    this.load.image("dusk-tag", "images/dusk.png");
    this.load.image("glow-particle", "images/glow_particle.png");
    this.load.image("effect-particle", "images/effect_particle.png");
    this.load.image("logo", "images/logo.png");
    this.load.image("menu-bg", "images/menu-bg.png");
    this.load.image("shield", "images/shield.png");
    this.load.image("board-shield", "images/board-shield416.png");
    this.load.image("board-target", "images/board-target416.png");
    this.load.image("sync", "images/ui/sync.png");
    this.load.image("skill-detail", "images/skill-detail.png");

    this.load.atlas("stun", "images/stun.png", "images/stun.json");
    this.load.atlas("flares", "images/flares.png", "images/flares.json");
    this.load.atlas("orbs", "images/orbs.png", "images/orbs.json");

    this.load.atlas(
      "frozen-orbs",
      "images/frozen-orbs1.png",
      "images/frozen-orbs.json"
    );
    this.load.atlas(
      "class-tags",
      "images/class-tags.png",
      "images/class-tags.json"
    );

    this.load.image("wall", "wall.png");
    this.load.image("wall-tl", "wall-tl.png");
    this.load.image("wall-tr", "wall-tr.png");
    this.load.image("wall-bl", "wall-bl.png");
    this.load.image("wall-br", "wall-br.png");
    this.load.image("board", "images/board-sq416.png");
    // this.load.image("board", "board.png");

    this.load.image("blue-crest", "/aquatic-crest.png");
    this.load.image("red-crest", "/bug-crest.png");
    this.load.image("yellow-crest", "/beast-crest.png");
    this.load.image("purple-crest", "/reptile-crest.png");
    this.load.image("green-crest", "/plant-crest.png");
    this.load.image("pink-crest", "/bird-crest.png");
    this.load.image("mana-box", "/manaBox.png");

    // this.load.image("img", "images/skills/ab_1_slow-0.png");
    this.load.on("progress", (val) => {
      ctx.drawImage(barImg, 0, 0, 677 * val, 75, 0, 0, 677 * val, 75);
      barCanvas.refresh();

      if (val === 1) {
        this.loadComplete = true;
        this.load.off("progress");

        this.loadingText.setText("loading complete");

        setTimeout(() => {
          if (this.scene.isActive("default")) {
            this.tweens.add({
              targets: [
                this.loadingText,
                this.loadingBox,
                this.loadingCanvas,
                this.loadingGlare,
              ],
              alpha: 0,
              duration: 1000,
            });
          }
          // console.log("this.game.loggedIn", this.game.loggedIn);
          if (this.game.loggedIn) {
            this.events.emit("user-login");
          }
        }, 2000);
      }
    });

    this.load.start();
  };
  this.load.on("progress", (val) => {
    if (val < 1) {
      return;
    }

    this.load.off("progress");

    setTimeout(() => {
      const bg = this.add.image(midX, midY, "main-image");
      bg.alpha = 0.1;

      bg.setDisplaySize(this.cameras.main.width, this.cameras.main.height);

      completeLoad();
    }, 100);
  });

  // this.load.on("filecomplete", (key) => {
  //   if (key === "ronin-logo") {
  //     setTimeout(() => {
  //       this.loginBtn = this.add.rexRoundRectangleCanvas(
  //         midX,
  //         midY - 140,
  //         380,
  //         70,
  //         20,
  //         0x046cfc
  //       );
  //       this.loginBtn.setInteractive();

  //       this.roninLogo = this.make.image({
  //         x: midX - 190 + 40,
  //         y: midY - 140,
  //         key: "ronin-logo",
  //         add: true,
  //         scale: {
  //           x: 0.75,
  //           y: 0.75,
  //         },
  //       });

  //       this.loginTxt = this.make.text({
  //         x: midX,
  //         y: midY - 140,
  //         text: "Login with Ronin",
  //         style: {
  //           fontFamily: "Luckiest Guy",
  //           fontSize: "24px",
  //           fill: "#046cfc",
  //           stroke: "#ffffff",
  //           strokeThickness: 4,
  //           align: "center",
  //         },
  //         add: true,
  //         origin: {
  //           x: 0.5,
  //           y: 0.5,
  //         },
  //       });

  //       // this.metamaskLoginBtn = this.add.rexRoundRectangleCanvas(
  //       //   midX,
  //       //   midY - 40,
  //       //   380,
  //       //   70,
  //       //   20,
  //       //   0xfafafa
  //       // );
  //       // this.metamaskLoginBtn.setInteractive();

  //       // this.metamaskLogo = this.make.image({
  //       //   x: midX - 190 + 40,
  //       //   y: midY - 40,
  //       //   key: "metamask-logo",
  //       //   add: true,
  //       //   scale: {
  //       //     x: 0.75,
  //       //     y: 0.75,
  //       //   },
  //       // });

  //       // this.metamaskLoginTxt = this.make.text({
  //       //   x: midX + 14,
  //       //   y: midY - 40,
  //       //   text: "Login with Metamask",
  //       //   style: {
  //       //     fontFamily: "Luckiest Guy",
  //       //     fontSize: "24px",
  //       //     fill: "#f2d9b6",
  //       //     stroke: "#7d5d2a",
  //       //     strokeThickness: 4,
  //       //     align: "center",
  //       //   },
  //       //   add: true,
  //       //   origin: {
  //       //     x: 0.5,
  //       //     y: 0.5,
  //       //   },
  //       // });

  //       const { ethereum } = window;

  //       // metamask is installed
  //       if (ethereum && ethereum.isMetaMask) {
  //         this.metamaskOK = true;
  //       } else {
  //         // TODO do the install metamask thing
  //       }

  //       this.input.on("gameobjectdown", async (pointer, gameObject, event) => {
  //         if (gameObject === this.loginBtn && !this.signingIn) {
  //           this.signingIn = true;
  //           this.game.events.emit("play-fx", SOUND_FX.CLICK, { volume: 0.25 });

  //           // handle login here
  //           try {
  //             await DAOlogin();

  //             this.loginDone = true;

  //             this.tweens.add({
  //               targets: [this.loginBtn, this.roninLogo, this.loginTxt],
  //               alpha: 0,
  //               duration: 500,
  //               onComplete: () => {
  //                 this.loginBtn.visible = false;

  //                 if (this.loadComplete) {
  //                   this.events.emit("user-login");
  //                   setTimeout(() => {
  //                     this.signingIn = false;
  //                     this.loginDone = false;

  //                     this.loginBtn.visible = true;
  //                     this.loginBtn.alpha = 1;
  //                     this.roninLogo.alpha = 1;
  //                     this.loginTxt.alpha = 1;
  //                   }, 1550);
  //                 }
  //               },
  //             });
  //           } catch (e) {
  //             console.log("error during login", e);
  //           }
  //         } else if (
  //           gameObject === this.metamaskLoginBtn &&
  //           !this.signingIn &&
  //           this.metamaskOK
  //         ) {
  //           await MetamaskLogin();
  //           this.game.events.emit("play-fx", SOUND_FX.CLICK, { volume: 0.25 });
  //           this.signingIn = true;
  //         }
  //       });
  //     }, 1000);
  //   }
  // });
}
