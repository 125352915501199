import { SOUND_FX } from "./sound/Sound";

export const SERVER_STATUS = {
  MAINTENANCE: "0",
  OK: "1",
};

export const PLAYER_STATE = {
  WAITING: "waiting",
  SELECTING: "selecting",
  READY: "ready",
  GAMEOVER: "gameover",
  WINNER: "winner",
};

export const EFFECTS_TARGET_NAMES = {
  self: "self",
  singleFriendly: "singleFriendly",
  allFriendly: "allFriendly",
  singleOpponent: "singleOpponent",
  allOpponents: "allOpponents",
  all: "all",
  block: "block",
};

export const EFFECTS_TYPE = {
  board: "board",
  mana: "mana",
};

export const EFFECTS_TARGET = {
  [EFFECTS_TARGET_NAMES.self]: 0,
  [EFFECTS_TARGET_NAMES.singleFriendly]: 1,
  [EFFECTS_TARGET_NAMES.allFriendly]: 2,
  [EFFECTS_TARGET_NAMES.singleOpponent]: 3,
  [EFFECTS_TARGET_NAMES.allOpponents]: 4,
  [EFFECTS_TARGET_NAMES.all]: 5,
  [EFFECTS_TARGET_NAMES.block]: 6,
};

export const EFFECTS_NAME = {
  addWall: "addWall",
  manaReptile: "manaReptile",
  manaBird: "manaBird",
  manaBug: "manaBug",
  manaAquatic: "manaAquatic",
  manaPlant: "manaPlant",
  manaBeast: "manaBeast",
};

const MISSED = "missed!";
const EVADED = "dodged!";

export const EFFECTS_TEXT = {
  DEFAULT: {
    hit: "hit!",
    miss: MISSED,
    shielded: "-1 shield",
    evade: "evaded!",
  },
  [EFFECTS_NAME.addWall]: {
    hit: "+1 wall",
    evade: "wall dodged!",
  },
  [EFFECTS_NAME.manaReptile]: {
    hit: "-evasion\n-speed",
    evade: "bite dogded!",
  },
  [EFFECTS_NAME.manaBird]: {
    hit: "+evasion\n+speed",
  },
  [EFFECTS_NAME.manaBug]: {
    hit: "+blind",
    evade: "sting dogded!",
  },
  [EFFECTS_NAME.manaAquatic]: {
    hit: "+freeze",
    evade: "freeze dogded!",
  },
  [EFFECTS_NAME.manaPlant]: {
    hit: "+2 shield\nslowed wall",
  },
  [EFFECTS_NAME.manaBeast]: {
    hit: "stunned!",
  },
};

export const EFFECTS_HIT = {
  hit: true,
  miss: false,
  shielded: "shielded",
  deflected: "deflected",
};

export const EFFECTS_HIT_SOUND = {
  DEFAULT1: {
    fx: SOUND_FX.DEFAULT_HIT1,
    opponentConfig: {
      volume: 0.35,
    },
  },
  DEFAULT2: {
    fx: SOUND_FX.DEFAULT_HIT2,
    opponentConfig: {
      volume: 0.35,
    },
  },
  [EFFECTS_NAME.addWall]: { fx: SOUND_FX.WALL, config: { volume: 0.4 } },
  [EFFECTS_NAME.manaBug]: {
    fx: SOUND_FX.VOICE_SCARED,
    opponentConfig: {
      volume: 0.35,
    },
  },
  [EFFECTS_NAME.manaAquatic]: {
    fx: SOUND_FX.FREEZE_HIT,
    opponentConfig: {
      volume: 0.3,
    },
  },
  [EFFECTS_HIT.shielded]: {
    FX: SOUND_FX.SHIELD_HIT,
    opponentConfig: {
      volume: 0.3,
    },
  },
  [EFFECTS_NAME.manaBeast]: {
    fx: SOUND_FX.STUN_HIT,
    opponentConfig: {
      volume: 0.35,
    },
  },
};

export const EFFECTS_CAST_SOUND = {
  DEFAULT: {
    fx: SOUND_FX.CAST_EFFECT,
    opponentConfig: {
      volume: 0.4,
    },
  },
  [EFFECTS_NAME.addWall]: {
    fx: SOUND_FX.CAST_WALL,
    opponentConfig: {
      volume: 0.4,
    },
  },
  [EFFECTS_NAME.manaReptile]: {
    fx: SOUND_FX.CAST_EFFECT,
    opponentConfig: {
      volume: 0.4,
    },
  },
  [EFFECTS_NAME.manaBird]: {
    fx: SOUND_FX.CAST_EFFECT,
    opponentConfig: {
      volume: 0.4,
    },
  },
  [EFFECTS_NAME.manaBug]: {
    fx: SOUND_FX.CAST_EFFECT,
    opponentConfig: {
      volume: 0.4,
    },
  },
  [EFFECTS_NAME.manaAquatic]: {
    fx: SOUND_FX.CAST_EFFECT,
    opponentConfig: {
      volume: 0.4,
    },
  },
  [EFFECTS_NAME.manaPlant]: {
    fx: SOUND_FX.SHIELD_UP,
    opponentConfig: {
      volume: 0.6,
    },
  },
  [EFFECTS_NAME.manaBeast]: {
    fx: SOUND_FX.STUN_CAST,
    opponentConfig: {
      volume: 0.5,
    },
  },
};

export const BOARD = {
  MAX_MANA: 50,
  MOVE_TIMEOUT: 10000,
  CLEAR_SEQ: 1200,
};

export const classToManaProp = {
  bird: "pinkMana",
  plant: "greenMana",
  reptile: "purpleMana",
  aquatic: "blueMana",
  beast: "yellowMana",
  bug: "redMana",
};

export const manaAbilities = {
  bird: {
    name: "Speedy Bird",
    text: "Apply [b]100% evasion[/b] and [b]70%[/b] speed buffs to all friendly axies for [b]20[/b] seconds.",
    img: "ab_mana_bird",
  },
  plant: {
    name: "Root Wall",
    text: "Toughen up! [b]+2 shield[/b] and [b]+0.2s[/b] to wall counter for all friendly axies. Duration: [b]20[/b] seconds.",
    img: "ab_mana_plant",
  },
  reptile: {
    name: "Crippling Bite",
    text: "All opponents suffer a [b]-75%[/b] evasion and [b]70%[/b] speed debuff. Duration: [b]20[/b] seconds.",
    img: "ab_mana_reptile",
  },
  aquatic: {
    name: "Ice Storm",
    text: "Freeze up to [b]5 random[/b] blocks for each opponent. Frozen blocks can't be moved but can be cleared.",
    img: "ab_mana_aquatic",
  },
  beast: {
    name: "Axie Smash",
    text: "Stun all opponents for [b]5 seconds[/b], then apply a [b]75%[/b] speed reduction for [b]10 seconds[/b].",
    img: "ab_mana_beast",
  },
  bug: {
    name: "Sting Shot",
    text: "Apply [b]blurry vision[/b] to all opponents for [b]10 seconds[/b].",
    img: "ab_mana_bug",
  },
};

export const VIEWPORT = {
  width: 1280,
  height: 720,
};
