import Phaser from "phaser";

import { Button } from "../../components/Button";
import { ProfileSideNav } from "./ProfileSideNav";
import { MyAxies } from "./axies/MyAxies";
import { MatchHistory } from "./matches/History";
import { ViewRankings } from "./rankings/ViewRankings";
export class Profile extends Phaser.Scene {
  // constructor(config = {}) {
  //   super({ key: "menu", ...config });
  // }

  create() {
    const midX = this.cameras.main.width / 2;
    const midY = this.cameras.main.height / 2;

    this.add.rectangle(
      midX,
      midY,
      this.cameras.main.width,
      this.cameras.main.height,
      0xbbac93
    );

    this.fadeMask = this.add.rectangle(
      midX,
      midY,
      this.cameras.main.width,
      this.cameras.main.height,
      0x000000
    );
    // this.fadeMask.setInteractive();
    this.fadeMask.depth = 10;
    // this.fadeMask.visible = false;
    // this.fadeMask.alpha = 1;

    this.buildMenu();

    this.events.on("transitionstart", (from, duration) => {
      this.sideNav.select();
      this.fadeMask.alpha = 1;
      this.tweens.add({
        targets: this.fadeMask,
        alpha: 0,
        delay: duration,
        duration: duration, // * 0.75,
        onStart: () => {
          // this.fadeMask.visible = true;
        },
        onComplete: () => {
          // this.fadeMask.visible = false;
        },
      });
    });

    this.events.on("transitionout", (target, duration) => {
      this.reset();
      this.fadeMask.alpha = 0;
      this.tweens.add({
        targets: this.fadeMask,
        alpha: 1,
        duration: duration,
        onStart: () => {
          // this.fadeMask.visible = true;
          this.fadeMask.alpha = 0;
          // this.events.removeAllListeners();
        },
      });
    });

    this.events.on("show-option", (option) => {
      this.showView(option);
    });

    this.views = {
      axies: new MyAxies(this, midX, 0),
      history: new MatchHistory(this, midX, 0),
      rankings: new ViewRankings(this, midX, 0),
    };
  }

  buildMenu() {
    this.sideNav = new ProfileSideNav(this);
  }

  showView(option) {
    if (this.activeView) {
      this.views[this.activeView].hide();
    }

    if (option === "back") {
      this.events.emit("profile-exit");
    } else {
      this.activeView = option;
      this.views[option].show();
    }
  }

  reset() {
    this.events.off("show-option");
  }
}
