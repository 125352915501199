import { useEffect, useReducer, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import Input from "../../components/Form/Input";
import PasswordField from "../../components/Form/PasswordField";
import MenuButton from "../../components/Form/MenuButton";
import { TRANSITIONS } from "../../../../../constants";
import { validateEmail, testPassword } from "../../utils";
import { signUp } from "../../../gameSrc/helpers/api";
import ModalSpinner from "../../components/ModalLoader";
import styles from "./login.module.scss";

const initState = {
  email: "",
  password: "",
  repeat: "",
};

const reducer = (state, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "update_email":
      newState.email = action.value;
      return newState;
      break;

    case "update_password":
      newState.password = action.value;
      return newState;
      break;

    case "update_repeat":
      newState.repeat = action.value;
      return newState;
      break;

    default:
      return newState;
  }
  console.log("state", newState);
};

const Register = ({ show, onClose }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [validForm, setValidForm] = useState(false);
  const [sending, submitForm] = useState(false);
  const [success, setSuccess] = useState(false);
  const emailRef = useRef();

  useEffect(() => {
    if (show && emailRef.current) {
      emailRef.current.focus();
    }
  }, [show]);

  useEffect(() => {
    if (
      validateEmail(state.email) &&
      testPassword(state.password) &&
      state.password === state.repeat
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [state]);

  const handleSubmit = async () => {
    submitForm(true);
    try {
      const resp = await signUp(state.email, state.password);

      if (resp.status === "ok") {
        setSuccess(true);
      }
    } catch (e) {
      alert(
        "Error submitting your registration. Please try again in a few minutes"
      );
    }

    submitForm(false);
  };

  return (
    <CSSTransition
      in={show}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      unmountOnExit
    >
      <div className={styles.modal}>
        <button
          className={styles.close}
          onClick={() => {
            setSuccess(false);
            onClose();
          }}
        >
          <svg
            style={{ fill: "currentcolor" }}
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.293 6.293a1 1 0 0 1 1.414 0L12 10.586l4.293-4.293a1 1 0 1 1 1.414 1.414L13.414 12l4.293 4.293a1 1 0 0 1-1.414 1.414L12 13.414l-4.293 4.293a1 1 0 0 1-1.414-1.414L10.586 12 6.293 7.707a1 1 0 0 1 0-1.414Z"
              fill="currentColor"
            ></path>
          </svg>
        </button>
        <div className={styles.modalContent}>
          {success ? (
            <CSSTransition
              in={show}
              timeout={TRANSITIONS.fade}
              classNames="tr-fade"
              unmountOnExit
            >
              <>
                <p style={{ fontSize: 24, fontWeight: 600, marginTop: 0 }}>
                  Account created!
                </p>
                <p style={{ marginBottom: 20 }}>
                  Please check your inbox and look for an email from{" "}
                  <b>support@axieblitz.com</b> containing a link to activate
                  your account.
                </p>
              </>
            </CSSTransition>
          ) : (
            <>
              <p style={{ fontSize: 24, fontWeight: 600, marginTop: 0 }}>
                Create your Axie Blitz account
              </p>
              <p style={{ marginBottom: 20 }}>
                Enter your email address. We will send you a link to reset your
                password.
              </p>
              <Input
                ref={emailRef}
                name="register-email"
                label="Email"
                required
                style={{ marginBottom: 20 }}
                validationFn={validateEmail}
                errorMsg="Enter valid email address"
                changeFn={(val) => {
                  dispatch({ type: "update_email", value: val });
                }}
              />
              <PasswordField
                name="password"
                label="Password"
                required
                style={{ marginBottom: 5 }}
                validationFn={(val) => {
                  return testPassword(val);
                }}
                errorMsg="Password should be at least 8 characters long and contain at least 1 number"
                changeFn={(val) => {
                  dispatch({ type: "update_password", value: val });
                }}
              />
              <PasswordField
                name="repeat"
                label="Repeat password"
                required
                style={{ marginBottom: 5 }}
                validationFn={(val) => {
                  return state.password === state.repeat;
                }}
                errorMsg="Passwords do not match"
                changeFn={(val) => {
                  dispatch({ type: "update_repeat", value: val });
                }}
              />
              <MenuButton
                text={"Join Now"}
                disabled={sending || !validForm}
                onClick={handleSubmit}
                style={{ marginTop: 14 }}
              />
            </>
          )}
        </div>
        <ModalSpinner show={sending} style={{ borderRadius: 24 }} />
      </div>
    </CSSTransition>
  );
};

export default Register;
