import { CSSTransition } from "react-transition-group";
import Box from "../../components/Box";
import MenuButton from "../../components/Form/MenuButton";
import styles from "../../ui.module.scss";
import selectorStyles from "./axieselector.module.scss";
import { SOUND_FX } from "../../../gameSrc/sound/Sound";

const AxieSelector = ({
  randomSelection,
  selectedAxie,
  onClick,
  playSound,
  disabled,
}) => {
  return (
    <Box className={selectorStyles.container}>
      <div className={`${styles.blitz} ${selectorStyles.copy}`}>Your axie</div>
      <CSSTransition
        in={!!randomSelection}
        timeout={600}
        classNames="tr-bounce-z"
        unmountOnExit
      >
        <div className={selectorStyles.selection}>
          {randomSelection && (
            <>
              <img
                src={`https://axiecdn.axieinfinity.com/axies/${randomSelection.axie_id}/axie/axie-full-transparent.png`}
              />
              <span className={styles.blitz}>{randomSelection.name}</span>
            </>
          )}
        </div>
      </CSSTransition>
      {!randomSelection && (
        <div className={selectorStyles.selection}>
          {selectedAxie ? (
            <img
              src={`https://axiecdn.axieinfinity.com/axies/${selectedAxie.axie_id}/axie/axie-full-transparent.png`}
            />
          ) : (
            <img src="images/random.png" />
          )}
          <span className={styles.blitz}>
            {selectedAxie ? selectedAxie.name : "random"}
          </span>
        </div>
      )}
      <MenuButton
        text={"select"}
        size="small"
        style={{ flex: 1, padding: "0 30px", marginLeft: 20 }}
        throttleMs={1000}
        onClick={() => {
          playSound(SOUND_FX.CLICK, { volume: 0.25 });
          onClick();
        }}
        onMouseEnter={() => {
          playSound(SOUND_FX.HOVER, { volume: 0.25 });
        }}
        disabled={disabled}
      />
    </Box>
  );
};

export default AxieSelector;
