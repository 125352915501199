import Phaser from "phaser";
import { OptionView } from "../../OptionView";
import { getUserAxies, syncUserAxies } from "../../../helpers/api";
import { SOUND_FX } from "../../../sound/Sound";

export class MatchHistory extends OptionView {
  constructor(scene, x, y, children) {
    super(scene, x, y, children);

    this.soon = this.scene.make.text({
      x: 0,
      y: this.height / 2,
      text: "Soon...",
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "26px",
        stroke: "#7d5d2a",
        strokeThickness: 4,
        fill: "#ffffff",
      },
      add: true,
      origin: {
        x: 0.5,
        y: 0.5,
      },
    });

    this.alpha = 0;

    this.add(this.soon);
  }

  async show() {
    if (this.visibilityTween) {
      this.visibilityTween.stop();
      this.visibilityTween.remove();
      this.visibilityTween = null;
    }

    this.visibilityTween = this.scene.tweens.add({
      targets: this,
      alpha: 1,
      duration: 333,
    });
  }

  hide() {
    if (this.visibilityTween) {
      this.visibilityTween.stop();
      this.visibilityTween.remove();
      this.visibilityTween = null;
    }

    this.visibilityTween = this.scene.tweens.add({
      targets: this,
      alpha: 0,
      duration: 333,
    });
  }
}
