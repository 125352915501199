import { useState, useLayoutEffect } from "react";

const useWrapperSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      const gameWrapper = document.querySelector(".game-wrapper");

      if (!gameWrapper) {
        setSize([0, 0]);
      } else {
        setSize([gameWrapper.offsetWidth, gameWrapper.offsetHeight]);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export default useWrapperSize;
