import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import BlitzGame from "./components/game/Game";
import { ColyseusProvider } from "./helpers/ColyseusProvider";
import "./App.scss";

function App() {
  return (
    <ColyseusProvider>
      <div className="game-container">
        <BlitzGame />
      </div>
    </ColyseusProvider>
  );
}

export default App;
