import Phaser from "phaser";
import { createColorParticle, getRandomInt, timer } from "./utils";

const particleColors = {
  blue: "#28b3d5",
  pink: "#ffc0cb",
  green: "#5abd00",
  yellow: "#fff700",
  purple: "#a900ff",
  red: "#a10f0f",
  missed: "#444444",
  hit: "#6a0b0b",
  friendly: "#04d71e",
};

export class ParticlesScene extends Phaser.Scene {
  constructor(config) {
    super(config);
  }

  create() {
    this.pool = {
      green: [],
      blue: [],
      pink: [],
      red: [],
      yellow: [],
      purple: [],
      missed: [],
      wall: [],
    };

    this.textPool = [];

    this.fadeMask = this.add.rectangle(
      this.cameras.main.width / 2,
      this.cameras.main.height / 2,
      this.cameras.main.width,
      this.cameras.main.height,
      0x000000
    );
    // this.fadeMask.depth = 10;
    this.fadeMask.visible = false;
    this.fadeMask.alpha = 0;

    this.events.on("destroy", () => {
      this.reset();
    });
  }

  showTrail(data) {
    let emitter;

    // check if there's one in the pool
    if (this.pool[data.mana].length) {
      emitter = this.pool[data.mana].shift();
    } else {
      if (!this.textures.exists("particle-" + data.mana)) {
        createColorParticle(this, {
          width: 32,
          height: 32,
          color: particleColors[data.mana],
          label: data.mana,
        });
      }
      const particles = this.add.particles("particle-" + data.mana);

      emitter = particles.createEmitter({
        x: data.sourceX,
        y: data.sourceY,
        quantity:
          this.game.settings.graphics.particles === "high"
            ? 12
            : this.game.settings.graphics.particles === "mid"
            ? 6
            : 2,
        speed: { random: [50, 100] },
        lifespan: { random: [200, 400] },
        scale: { random: true, start: 1, end: 0 },
        rotate: { random: true, start: 0, end: 180 },
        angle: { random: true, start: 0, end: 270 },
        blendMode: "ADD",
      });
    }

    const xVals = [
      data.sourceX,
      data.sourceX + getRandomInt(-30, 70),
      data.destX + getRandomInt(-55, 10),
      data.destX,
    ];
    const yVals = [
      data.sourceY,
      data.sourceY + getRandomInt(-20, 80),
      data.destY + getRandomInt(-25, 60),
      data.destY,
    ];

    this.tweens.addCounter({
      from: 0,
      to: 1,
      ease: Phaser.Math.Easing.Sine.InOut,
      duration: 1000,
      onStart: () => {
        emitter.start();
        emitter.setPosition(data.sourceX, data.sourceY);
      },
      onUpdate: (tween) => {
        const v = tween.getValue();
        const x = Phaser.Math.Interpolation.CatmullRom(xVals, v);
        const y = Phaser.Math.Interpolation.CatmullRom(yVals, v);

        emitter.setPosition(x, y);
      },
      onComplete: () => {
        // emitter.explode(20, data.destX, data.destY);
        emitter.stop();

        this.time.delayedCall(500, () => {
          this.pool[data.mana].push(emitter);
          // particles.removeEmitter(emitter);
        });
      },
    });
  }

  castEffect(data) {
    // console.log("cast", data);
    let emitter;
    // console.log("castEff", data.hit, data.isFriendly);
    // check if there's one in the pool
    if (data.name === "addWall" && this.pool.wall.length) {
      emitter = this.pool.wall.shift();
    } else if (!data.hit && this.pool.missed.length) {
      emitter = this.pool.missed.shift();
    } else {
      let particleKey;
      let emitterConfig;

      if (data.name === "addWall") {
        particleKey = "wall";
        emitterConfig = {
          quantity: 1,
          lifespan: 0,
          scale: { start: 1, end: 0.15 },
          rotate: { random: true, start: 0, end: 180 },
          angle: { random: true, start: 0, end: 270 },
        };
      } else {
        if (!data.hit && !this.textures.exists("particle-missed")) {
          createColorParticle(this, {
            width: 48,
            height: 48,
            color: particleColors.missed,
            label: "missed",
            textureKey: "effect-particle",
          });
        } else if (
          data.isFriendly &&
          !this.textures.exists("particle-friendly")
        ) {
          createColorParticle(this, {
            width: 48,
            height: 48,
            color: particleColors.friendly,
            label: "friendly",
            textureKey: "effect-particle",
          });
        } else if (data.hit && !this.textures.exists("particle-hit")) {
          createColorParticle(this, {
            width: 48,
            height: 48,
            color: particleColors.hit,
            label: "hit",
            textureKey: "effect-particle",
          });
        }

        particleKey =
          "particle-" +
          (data.isFriendly ? "friendly" : data.hit ? "hit" : "missed");
        emitterConfig = {
          quantity:
            this.game.settings.graphics.particles === "high"
              ? 12
              : this.game.settings.graphics.particles === "mid"
              ? 6
              : 2,
          lifespan: { random: [400, 600] },
          scale: { random: true, start: 0.25, end: 2.25 },
          rotate: { random: true, start: 0, end: 180 },
          angle: { random: true, start: 0, end: 270 },
          blendMode: "ADD",
        };
      }

      const particles = this.add.particles(particleKey);
      // console.log(particleKey, emitterConfig);
      emitter = particles.createEmitter({
        x: data.sourceX,
        y: data.sourceY,
        ...emitterConfig,
      });
    }

    let text;

    if (this.textPool.length) {
      text = this.textPool.shift();
    } else {
      text = this.make.text({
        x: 0,
        y: 0,
        text: data.text,
        style: {
          fontFamily: "Luckiest Guy",
          fontSize: "14px",
          stroke: "#7d5d2a",
          strokeThickness: 4,
          fill: "#ffffff",
          // wordWrap: {
          //   width: 164,
          // },
          // fixedWidth: 164,
          align: "center",
        },
        add: true,
        origin: {
          x: 0.5,
          y: 0,
        },
      });
    }

    text.setText(data.text);

    const xVals = [
      data.sourceX,
      // data.sourceX + getRandomInt(-30, 70),
      // data.destX + getRandomInt(-55, 10),
      data.destX,
    ];
    const yVals = [
      data.sourceY,
      // data.sourceY + getRandomInt(-25, 75),
      // data.destY + getRandomInt(-50, 50),
      data.destY,
    ];

    this.tweens.addCounter({
      from: 0,
      to: 1,
      ease: Phaser.Math.Easing.Sine.InOut,
      duration: data.castTime,
      onStart: () => {
        emitter.start();
        emitter.setPosition(data.sourceX, data.sourceY);
      },
      onUpdate: (tween) => {
        const v = tween.getValue();
        const x = Phaser.Math.Interpolation.CatmullRom(xVals, v);
        const y = Phaser.Math.Interpolation.CatmullRom(yVals, v);

        emitter.setPosition(x, y);
      },
      onComplete: () => {
        if (data.hit && data.name !== "addWall") {
          emitter.explode(
            this.game.settings.graphics.particles === "high"
              ? 24
              : this.game.settings.graphics.particles === "mid"
              ? 12
              : 6,
            data.destX,
            data.destY
          );

          if (data.onComplete) {
            data.onComplete();
          }
        }

        emitter.stop();

        if (!data.hit && data.name !== "addWall") {
          this.time.delayedCall(500, () => {
            this.pool.missed.push(emitter);
          });
        }
      },
    });

    text.visible = false;

    this.tweens.add({
      targets: text,
      alpha: 1,
      duration: 1000,
      delay: data.castTime * 0.9,
      yoyo: true,
      ease: Phaser.Math.Easing.Sine.InOut,
      onStart: () => {
        text.alpha = 0;
        text.visible = true;
      },
      onComplete: () => {
        text.visible = false;
        this.textPool.push(text);
      },
    });
    this.tweens.add({
      targets: text,
      y: data.destY - 20,
      duration: 300,
      delay: data.castTime * 0.9,
      ease: Phaser.Math.Easing.Sine.InOut,
      onStart: () => {
        text.y = data.destY;
        text.x = data.destX;
      },
      onComplete: () => {
        this.tweens.add({
          targets: text,
          y: data.destY - 25,
          duration: 250,
          delay: 425,
          ease: Phaser.Math.Easing.Sine.InOut,
        });
      },
    });
  }

  showGameOver(data) {
    const text = this.make.text({
      x: data.msgX,
      y: data.msgY + 50,
      text: "GAME\nOVER",
      style: {
        fontFamily: "Blaka",
        fontSize: "80px",
        stroke: "#ff0000",
        strokeThickness: 16,
        fill: "#fff700",
        align: "center",
        lineSpacing: -20,
      },

      origin: {
        x: 0.5,
        y: 0.5,
      },
    });
    text.alpha = 0;
    text.setScale(data.isMe ? 0.8 : 0.4);

    this.add.existing(text);

    if (data.isMe) {
      this.tweens.add({
        targets: this.fadeMask,
        alpha: 0.5,
        onStart: () => {
          this.fadeMask.visible = true;
          this.fadeMask.alpha = 1;
        },
      });
    }

    this.tweens.add({
      targets: text,
      y: data.msgY,
      alpha: 1,
      duration: 1500,
      ease: "Bounce.easeInOut",
    });
  }

  showWinner(data) {
    const text = this.make.text({
      x: data.msgX,
      y: data.msgY + 50,
      text: data.isMe ? "VICTORY!" : "WINNER!",
      style: {
        fontFamily: "Blaka",
        fontSize: "80px",
        stroke: "#fff700",
        strokeThickness: 16,
        fill: "#2094f3",
        align: "center",
        lineSpacing: -20,
      },

      origin: {
        x: 0.5,
        y: 0.5,
      },
    });
    text.alpha = 0;
    text.setScale(data.isMe ? 1.25 : 0.4);

    this.add.existing(text);

    this.tweens.add({
      targets: this.fadeMask,
      alpha: 0.5,
      onStart: () => {
        this.fadeMask.visible = true;
        this.fadeMask.alpha = 1;
      },
    });
    this.tweens.add({
      targets: text,
      y: data.msgY,
      alpha: 1,
      duration: 1500,
      ease: "Bounce.easeInOut",
    });
  }

  reset() {
    this.tweens.add({
      targets: this.children,
      alpha: 0,
      duration: 300,
      onComplete: () => {
        this.fadeMask.visible = false;
      },
    });
  }
}
