import Phaser from "phaser";
import { OptionView } from "../../OptionView";
import { ToggleButton } from "../../../components/ToggleButton";
import { SOUND_FX } from "../../../sound/Sound";
import { Dropdown } from "../../../components/Dropdown";

export class GraphicsSettings extends OptionView {
  constructor(scene, x, y, children) {
    super(scene, x, y, children);

    const midX = this.width / 2;

    this.add(
      this.scene.make.text({
        x: -midX,
        y: 100,
        text: "Container Size",
        style: {
          fontFamily: "Luckiest Guy",
          fontSize: "26px",
          stroke: "#7d5d2a",
          strokeThickness: 4,
          fill: "#ffffff",
        },
        add: true,
        origin: {
          x: 0,
          y: 0.5,
        },
      })
    );

    this.add(
      this.scene.make.text({
        x: -midX,
        y: 270,
        text: "Particles Count",
        style: {
          fontFamily: "Luckiest Guy",
          fontSize: "26px",
          stroke: "#7d5d2a",
          strokeThickness: 4,
          fill: "#ffffff",
        },
        add: true,
        origin: {
          x: 0,
          y: 0.5,
        },
      })
    );

    this.containerSize = new Dropdown(this.scene, -midX + 140, 150, {
      name: "size",
      width: 280,
      onChange: (value) => {
        this.scene.events.emit("setting-change", {
          category: "graphics",
          field: "screenSize",
          value,
        });
      },
    });
    this.add(this.containerSize);
    this.containerSize.depth = 10;

    // this.containerSize.setDisplayText("def");
    this.alpha = 0;

    this.containerSize.setOptions([
      {
        text: "default (1440 x 720)",
        value: "default",
      },
      { text: "1920 x 1080", value: "1920" },
      { text: "100%", value: "100" },
    ]);

    this.containerParticles = new Dropdown(this.scene, -midX + 140, 320, {
      name: "particles",
      width: 280,
      onChange: (value) => {
        this.scene.events.emit("setting-change", {
          category: "graphics",
          field: "particles",
          value,
        });
      },
    });
    this.add(this.containerParticles);
    this.containerParticles.setOptions([
      {
        text: "Low",
        value: "low",
      },
      { text: "Medium", value: "mid" },
      { text: "High", value: "high" },
    ]);
  }

  async show(settings) {
    if (this.visibilityTween) {
      this.visibilityTween.stop();
      this.visibilityTween.remove();
      this.visibilityTween = null;
    }

    this.visibilityTween = this.scene.tweens.add({
      targets: [this],
      alpha: 1,
      duration: 333,
    });

    this.containerSize.selectOption(settings.screenSize);
    this.containerParticles.selectOption(settings.particles);
  }

  hide() {
    if (this.visibilityTween) {
      this.visibilityTween.stop();
      this.visibilityTween.remove();
      this.visibilityTween = null;
    }

    this.visibilityTween = this.scene.tweens.add({
      targets: [this],
      alpha: 0,
      duration: 333,
    });
  }
}
