import { CSSTransition } from "react-transition-group";
import Box from "../../components/Box";
import styles from "./Maintenance.module.scss";

const Maintenance = ({}) => {
  return (
    <Box className={styles.container}>
      <img src="images/logo-s.png" alt="Axie Blitz logo" />
      <br />
      <br />
      Game is in maintenance mode, please check back later!
    </Box>
  );
};

export default Maintenance;
