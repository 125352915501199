import { CSSTransition } from "react-transition-group";
import Box from "../../components/Box";
import MenuButton from "../../components/Form/MenuButton";
import { SOUND_FX } from "../../../gameSrc/sound/Sound";
import Input from "../../components/Form/Input";
import { validateEmail } from "../../utils";
import { TRANSITIONS } from "../../../../../constants";
import {
  getUserSettings,
  signIn,
  updateUserSettings,
} from "../../../gameSrc/helpers/api";
import ModalSpinner from "../../components/ModalLoader";

import styles from "../../ui.module.scss";
import settingsStyles from "./settings.module.scss";
import { useState, useReducer, useEffect } from "react";
import TextButton from "../../components/Form/TextButton";
import GameOptions from "./settings/GameOptions";
import GraphicOptions from "./settings/GraphicOptions";
import SoundOptions from "./settings/SoundOptions";

const initState = {
  optionSelected: "",
  data: {},
  dirty: false,
};

const reducer = (state, action, game) => {
  const newState = { ...state };

  switch (action.type) {
    case "change_option":
      newState.optionSelected = action.value;
      break;

    case "load_settings":
      newState.data = { ...newState.data, ...action.value };
      newState.dirty = false;
      break;

    case "update_settings":
      newState.dirty = true;
      if (action.subcategory) {
        newState.data[action.category][action.subcategory] = {
          ...newState.data[action.category][action.subcategory],
          ...action.values,
        };
      } else {
        newState.data[action.category] = {
          ...newState.data[action.category],
          ...action.values,
        };
      }
      break;

    default:
      break;
  }

  return newState;
};

const Settings = ({ show, changeView, game, playSound, onSave }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  // const [modal, showModal] = useState(false);
  // const [register, showRegister] = useState(false);
  // const [validForm, setValidForm] = useState(false);
  const [sending, sendRequest] = useState(false);
  // const [errorMsg, setError] = useState("");
  const [displayed, showSettings] = useState(false);

  useEffect(() => {
    async function callGetSettings() {
      sendRequest(true);
      try {
        const settings = await getUserSettings();
        dispatch({ type: "load_settings", value: settings });
      } catch (e) {
        console.log("error getting settings", e);
      }

      showSettings(true);
      sendRequest(false);
    }

    if (show) {
      callGetSettings();
    } else {
      showSettings(false);
    }
  }, [show]);

  useEffect(() => {
    if (displayed) {
      // showSettings(true);
      dispatch({ type: "change_option", value: "game" });
    } else {
      // showSettings(false);
      dispatch({ type: "change_option", value: "" });
    }
  }, [displayed]);

  const handleSave = async () => {
    sendRequest(true);
    try {
      const savedSettings = await updateUserSettings(state.data);
      game.current.saveSettings(savedSettings);
      dispatch({ type: "load_settings", value: savedSettings });
    } catch (e) {
      console.log("error updating settings", e);
    }

    sendRequest(false);
    onSave();
  };

  return (
    <CSSTransition
      in={show}
      timeout={TRANSITIONS.fade}
      classNames="tr-fade"
      unmountOnExit
    >
      <div className={styles.screen} style={{ background: "rgba(0,0,0,0.75)" }}>
        <CSSTransition
          in={displayed}
          timeout={TRANSITIONS.fade}
          classNames="tr-fade-left"
          unmountOnExit
        >
          <Box className={[settingsStyles.leftBar, styles.dark].join(" ")}>
            <TextButton
              text={"Game"}
              size="large"
              copyClassName={[
                settingsStyles.option,
                state.optionSelected === "game" ? settingsStyles.selected : "",
              ]
                .join(" ")
                .trim(0)}
              onClick={() => {
                playSound(SOUND_FX.CLICK, { volume: 0.25 });
                dispatch({ type: "change_option", value: "game" });
              }}
              onMouseEnter={() => {
                playSound(SOUND_FX.HOVER, { volume: 0.25 });
              }}
              style={{ marginBottom: 20 }}
            ></TextButton>
            <TextButton
              text={"Graphics"}
              size="large"
              copyClassName={[
                settingsStyles.option,
                state.optionSelected === "graphics"
                  ? settingsStyles.selected
                  : "",
              ]
                .join(" ")
                .trim(0)}
              onClick={() => {
                playSound(SOUND_FX.CLICK, { volume: 0.25 });
                dispatch({ type: "change_option", value: "graphics" });
              }}
              onMouseEnter={() => {
                playSound(SOUND_FX.HOVER, { volume: 0.25 });
              }}
              style={{ marginBottom: 20 }}
            ></TextButton>
            <TextButton
              text={"Sound"}
              size="large"
              copyClassName={[
                settingsStyles.option,
                state.optionSelected === "sound" ? settingsStyles.selected : "",
              ]
                .join(" ")
                .trim(0)}
              onClick={() => {
                playSound(SOUND_FX.CLICK, { volume: 0.25 });
                dispatch({ type: "change_option", value: "sound" });
              }}
              onMouseEnter={() => {
                playSound(SOUND_FX.HOVER, { volume: 0.25 });
              }}
            ></TextButton>
            <div className={settingsStyles.ctas}>
              <MenuButton
                text={"save"}
                disabled={!state.dirty}
                throttleMs={1000}
                onClick={() => {
                  playSound(SOUND_FX.CLICK, { volume: 0.25 });
                  handleSave();
                }}
                onMouseEnter={() => {
                  playSound(SOUND_FX.HOVER, { volume: 0.25 });
                }}
              />
              <MenuButton
                text={"back"}
                // disabled={!validForm}
                throttleMs={1000}
                onClick={() => {
                  playSound(SOUND_FX.CLICK, { volume: 0.25 });
                  changeView("mainMenu");
                }}
                onMouseEnter={() => {
                  playSound(SOUND_FX.HOVER, { volume: 0.25 });
                }}
              />
            </div>
          </Box>
        </CSSTransition>
        <CSSTransition
          in={displayed}
          timeout={TRANSITIONS.fade}
          classNames="tr-fade-right"
          unmountOnExit
        >
          <Box className={settingsStyles.mainBox}>
            <GameOptions
              show={state.optionSelected === "game"}
              values={state.data.game}
              onChange={(category, values) => {
                dispatch({ type: "update_settings", category, values });
              }}
            />
            <GraphicOptions
              show={state.optionSelected === "graphics"}
              values={state.data.graphics}
              onChange={(category, values) => {
                dispatch({ type: "update_settings", category, values });
              }}
            />
            <SoundOptions
              show={state.optionSelected === "sound"}
              values={state.data.sound}
              onChange={(category, values) => {
                dispatch({
                  type: "update_settings",
                  category,
                  subcategory: "volume",
                  values,
                });
              }}
            />
          </Box>
        </CSSTransition>
        <ModalSpinner show={sending} style={{ borderRadius: 24 }} />
      </div>
    </CSSTransition>
  );
};

export default Settings;
