import Phaser from "phaser";
import { createSkillCard, createColorParticle } from "./utils";

const tags = {
  aquatic: "blue-skill-mana",
  dawn: "dawn-tag",
  dusk: "dusk-tag",
  mech: "mech-tag",
  bird: "pink-skill-mana",
  reptile: "purple-skill-mana",
  bug: "red-skill-mana",
  beast: "yellow-skill-mana",
  plant: "green-skill-mana",
};

export class AxieDetailsModal extends Phaser.GameObjects.Container {
  constructor(scene, x, y, children) {
    super(scene, x, y, children);
    // createColorParticle(scene);
    scene.add.existing(this);
    this.add(
      this.scene.make.image({
        x: 0,
        y: 0,
        key: "selection-details",
        add: true,
      })
    );
    this.visible = false;
    this.alpha = 0;

    this.statsText = this.scene.add.rexBBCodeText({
      x: -175,
      y: -290,
      origin: { x: 0, y: 0 },
      style: {
        fontFamily: "Luckiest Guy",
        fontSize: "10px",
        color: "#7d5d2a",
        stroke: 0xfafafa,
        strokeThickness: 2,
        wrap: {
          mode: "word",
          width: 315,
        },
      },
    });

    this.add(this.statsText);

    const passiveText = this.scene.add.rexBBCodeText({
      x: -175,
      y: -185,
      origin: { x: 0, y: 0 },
      style: {
        fontFamily: "Secular One",
        fontSize: "12px",
        lineSpacing: -1,
        color: "#7d5d2a",
        stroke: 0xfafafa,
        strokeThickness: 2,
        wrap: {
          mode: "word",
          width: 330,
        },
      },
    });
    passiveText.name = "passiveText";
    this.add(passiveText);

    const eyesText = this.scene.add.rexBBCodeText({
      x: -95,
      y: -162,
      origin: { x: 0, y: 0 },
      style: {
        fontFamily: "Secular One",
        fontSize: "12px",
        lineSpacing: -1,
        color: "#7d5d2a",
        stroke: 0xfafafa,
        strokeThickness: 2,
        wrap: {
          mode: "word",
          width: 265,
        },
      },
    });
    eyesText.name = "eyesText";
    this.add(eyesText);

    const earsText = this.scene.add.rexBBCodeText({
      x: -95,
      y: -85,
      origin: { x: 0, y: 0 },
      style: {
        fontFamily: "Secular One",
        fontSize: "12px",
        lineSpacing: -1,
        color: "#7d5d2a",
        stroke: 0xfafafa,
        strokeThickness: 2,
        wrap: {
          mode: "word",
          width: 265,
        },
      },
    });
    earsText.name = "earsText";
    this.add(earsText);

    const backText = this.scene.add.rexBBCodeText({
      x: -95,
      y: -8,
      origin: { x: 0, y: 0 },
      style: {
        fontFamily: "Secular One",
        fontSize: "12px",
        lineSpacing: -1,
        color: "#7d5d2a",
        stroke: 0xfafafa,
        strokeThickness: 2,
        wrap: {
          mode: "word",
          width: 265,
        },
      },
    });
    backText.name = "backText";
    this.add(backText);

    const mouthText = this.scene.add.rexBBCodeText({
      x: -95,
      y: 69,
      origin: { x: 0, y: 0 },
      style: {
        fontFamily: "Secular One",
        fontSize: "12px",
        lineSpacing: -1,
        color: "#7d5d2a",
        stroke: 0xfafafa,
        strokeThickness: 2,
        wrap: {
          mode: "word",
          width: 265,
        },
      },
    });
    mouthText.name = "mouthText";
    this.add(mouthText);

    const hornText = this.scene.add.rexBBCodeText({
      x: -95,
      y: 146,
      origin: { x: 0, y: 0 },
      style: {
        fontFamily: "Secular One",
        fontSize: "12px",
        lineSpacing: -1,
        color: "#7d5d2a",
        stroke: 0xfafafa,
        strokeThickness: 2,
        wrap: {
          mode: "word",
          width: 265,
        },
      },
    });
    hornText.name = "hornText";
    this.add(hornText);

    const tailText = this.scene.add.rexBBCodeText({
      x: -95,
      y: 223,
      origin: { x: 0, y: 0 },
      style: {
        fontFamily: "Secular One",
        fontSize: "12px",
        lineSpacing: -1,
        color: "#7d5d2a",
        stroke: 0xfafafa,
        strokeThickness: 2,
        wrap: {
          mode: "word",
          width: 265,
        },
      },
    });
    tailText.name = "tailText";
    this.add(tailText);
  }

  show(axie) {
    if (!axie) {
      return;
    }

    if (this.displayTween) {
      this.displayTween.stop();
    }

    this.displayTween = this.scene.tweens.add({
      targets: this,
      alpha: 1,
      duration: 250,
      onStart: () => {
        this.visible = true;
        this.depth = 6;
      },
    });

    const mods = {};
    axie.statModifiers.forEach((mod) => {
      if (mod.evasion !== 0) {
        if (mod.evasion !== 0) {
          if (!mods.evasion) {
            mods.evasion = [];
          }
          mods.evasion.push(
            `${mod.evasion > 0 ? "[color=#1b7f1b]+" : "[color=red]"}${
              mod.evasion / 10
            }[/color] ${mod.typeValue}`
          );
        }
      }
      if (mod.speed !== 0) {
        if (mod.speed !== 0) {
          if (!mods.speed) {
            mods.speed = [];
          }
          mods.speed.push(
            `${mod.speed > 0 ? "[color=#1b7f1b]+" : "[color=red]"}${
              mod.speed / 10
            }[/color] ${mod.typeValue}`
          );
        }
      }
      if (mod.luck !== 0) {
        if (!mods.luck) {
          mods.luck = [];
        }
        mods.luck.push(
          `${mod.luck > 0 ? "[color=#1b7f1b]+" : "[color=red]"}${
            mod.luck / 10
          }[/color] ${mod.typeValue}`
        );
      }
      if (mod.wall !== 0) {
        if (!mods.wall) {
          mods.wall = [];
        }
        mods.wall.push(
          `${mod.wall > 0 ? "[color=#1b7f1b]+" : "[color=red]"}${
            mod.wall / 1000
          }[/color] ${mod.typeValue}`
        );
      }
    });

    const stats = axie.stats;
    this.statsText.setText(`[stroke][y=-3][img=${
      tags[axie.axieClass]
    }][/y][size=16]${axie.name}[/size]

[size=14]speed: ${stats.speed / 10}${
      mods.speed ? " (" + mods.speed.join(" ") + ")" : ""
    }
evasion: ${stats.evasion / 10}${
      mods.evasion ? " (" + mods.evasion.join(" ") + ")" : ""
    }
luck: ${stats.luck / 10}${mods.luck ? " (" + mods.luck.join(" ") + ")" : ""}
wall: ${stats.wall / 1000} secs${
      mods.wall ? " (" + mods.wall.join(" ") + ")" : ""
    }[/size][/stroke]`);

    this.showSkill(axie.eyes, "eyes", -162);
    this.showSkill(axie.ears, "ears", -85);
    this.showSkill(axie.back, "back", -8);
    this.showSkill(axie.mouth, "mouth", 69);
    this.showSkill(axie.horn, "horn", 146);
    this.showSkill(axie.tail, "tail", 223);

    this.getByName("passiveText").setText(
      `[size=15][stroke]${axie.passiveText}[/stroke][/size]`
    );
  }

  showSkill(part, partType, y) {
    createSkillCard(this, {
      x: -175,
      y: y,
      width: 70,
      height: 70,
      key: part.cardId,
      add: true,
      partType,
    });

    this.getByName(`${partType}Text`).setText(
      `[size=15][stroke]${part.name}  cost: ${part.manaCost} [y=-1][img=${
        part.manaType
      }-skill-mana][/y]  cd: ${parseFloat((part.cooldown / 1000).toFixed(2))}s
[b]${part.cardName}[/b] - ${part.description}[/stroke][/size]`
    );
  }

  hide() {
    if (this.displayTween) {
      this.displayTween.stop();
    }

    this.displayTween = this.scene.tweens.add({
      targets: this,
      alpha: 0,
      duration: 250,
      onComplete: () => {
        this.visible = false;
        this.depth = 3;
      },
    });
  }
}
