import { forwardRef, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { TRANSITIONS } from "../../../../../constants";

import styles from "./input.module.scss";

const Input = forwardRef(
  (
    {
      name,
      type = "text",
      label,
      required = false,
      validationFn,
      changeFn,
      className,
      children,
      errorMsg,
      ...rest
    },
    ref
  ) => {
    const inputRef = useRef();
    const fieldRef = ref || inputRef;
    const [valid, setValid] = useState(true);

    useEffect(() => {
      fieldRef.current.addEventListener("blur", (e) => {
        if (validationFn) {
          setValid(validationFn(e.target.value));
        }
      });
      fieldRef.current.addEventListener("change", (e) => {
        if (changeFn) {
          changeFn(e.target.value);
        }
      });
    }, []);

    return (
      <div className={[styles.input, className].join(" ").trim()} {...rest}>
        <CSSTransition
          in={!valid}
          timeout={TRANSITIONS.fadeFast}
          classNames="tr-fade-up"
          unmountOnExit
        >
          <div
            className={[styles.errorMsg, !valid ? styles.show : ""]
              .join(" ")
              .trim()}
          >
            {errorMsg}
          </div>
        </CSSTransition>
        <div className={styles.wrapper}>
          <input
            className={!valid ? styles.error : ""}
            ref={fieldRef}
            type={type}
            id={name}
            required={required}
          />
          <label htmlFor={name}>{label}</label>
          {children}
        </div>
      </div>
    );
  }
);

export default Input;
